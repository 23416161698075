import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, SegmentedButtons, IconButton } from 'react-native-paper'
import {
  Icon3dBumpDown,
  Icon3dBumpUp,
  IconBumpList,
} from '../../../utils/meldd-icons'
import { useTheme } from '../../../hooks/use-theme'
import { useCallback, useMemo, useRef } from 'react'
import {
  BumpContributionInput,
  Contribution,
  ContributionBumpDetailFragmentDoc,
  useBumpContributionMutation,
  useBumpsForContributionQuery,
} from '../../../generated/graphql'
import _orderBy from 'lodash/orderBy'
import { useAuthContext } from '../../../auth/auth-context'
import { useFeatures } from '../../../contexts/FeatureProvider'
import { MessageType } from '../../../utils/message-type'
import Toast from 'react-native-root-toast'
import { useTranslation } from 'react-i18next'
import { BumpDetailsModal, BumpDetailsRef } from './BumpDetailsModal'
import {
  BumpJustificationModal,
  CANCEL_BUMP,
  BumpJustificationRef,
} from './BumpJustificationModal'
import { MelddToolTip } from '../../../utils/meldd-tooltip'
import { SocketEvents, useSocketRoom } from '../../../socket/socket.hooks'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import Row from '../../../components/containers/Row'
import * as Sentry from '@sentry/react-native'

interface BumpButtonsProps {
  contribution: Contribution
}

export function BumpButtons(props: BumpButtonsProps) {
  const { contribution } = props
  const { userId: loggedUserId } = useAuthContext()
  const bumpDetailsRef = useRef<BumpDetailsRef>(null)
  const bumpJustificationRef = useRef<BumpJustificationRef>(null)
  const { ownerBumps } = useFeatures()
  const { t } = useTranslation('bumps')
  const { authAction } = useAuthContext()
  const navigation = useNavigation<AppNavigation>()

  const theme = useTheme()

  const [doBumpContribution, { loading: isBumping }] =
    useBumpContributionMutation({
      update: (cache, result) => {
        const { data } = result
        if (data) {
          // Add the bump to the list if id didn't exist
          const field = `contributionBumpsByContribution(${JSON.stringify({
            contributionId: contribution.id,
          })})`
          cache.modify({
            id: 'ROOT_QUERY',
            fields: {
              [field]: (currentBumps: any[], { readField }) => {
                const newBumpRef = cache.writeFragment({
                  data: data.bumpContribution,
                  fragment: ContributionBumpDetailFragmentDoc,
                })
                const newBumpRefStr = JSON.stringify(newBumpRef)
                if (
                  currentBumps.some(
                    (ref) => JSON.stringify(ref) == newBumpRefStr
                  )
                ) {
                  return currentBumps
                }
                return [...currentBumps, newBumpRef]
              },
            },
          })
        }
      },
    })

  const { data, refetch } = useBumpsForContributionQuery({
    variables: { contributionId: contribution.id },
    skip: !contribution.id,
  })
  useSocketRoom(contribution.id).event(SocketEvents.Refresh, refetch)
  const bumps = useMemo(
    () =>
      _orderBy(
        data?.contributionBumpsByContribution || [],
        'updatedAt',
        'desc'
      ),
    [data]
  )

  const isContributor = useMemo(
    () => contribution.userId == loggedUserId,
    [contribution, loggedUserId]
  )
  const myBumpValue = useMemo(
    () => bumps.find((b) => b.userId == loggedUserId)?.value || 0,
    [bumps]
  )
  const positiveCount = useMemo(
    () => bumps.reduce((v, b) => (b.value > 0 ? v + 1 : v), 0),
    [bumps]
  )
  const negativeCount = useMemo(
    () => bumps.reduce((v, b) => (b.value < 0 ? v - 1 : v), 0),
    [bumps]
  )
  const canBump = useMemo(
    () => !isBumping && (!isContributor || ownerBumps),
    [isContributor, ownerBumps, isBumping]
  )
  const saveBump = async (vote: string, reason: string) => {
    const addValue = vote == 'up' ? 1 : -1
    const value = myBumpValue + addValue
    if (Math.abs(value) <= 1) {
      const input: BumpContributionInput = {
        contributionId: contribution.id,
        value: value,
        reason,
      }
      try {
        await doBumpContribution({ variables: { input } })
        Toast.show(t('Bump saved'), MessageType.info)
      } catch (e) {
        Toast.show((e as Error).message, MessageType.error)
        console.error(e)
        Sentry.captureException(e)
      }
    }
  }

  const handleClick = useCallback(
    (value: 'up' | 'down') => {
      const showReason = myBumpValue == 0
      // If is negative or positive, request the reason, otherwise is removing his bump
      showReason
        ? authAction(navigation, () =>
            bumpJustificationRef.current?.show(value)
          )
        : authAction(navigation, () => saveBump(value, ''))
    },
    [myBumpValue]
  )

  const styles = useMemo(
    () =>
      StyleSheet.create({
        up: {
          minWidth: 0,
          maxWidth: 72,
          backgroundColor:
            myBumpValue > 0 ? theme.colors.success.surface : undefined,
        },
        down: {
          minWidth: 0,
          maxWidth: 72,
          backgroundColor:
            myBumpValue < 0 ? theme.colors.danger.surface : undefined,
        },
      }),
    [myBumpValue]
  )

  return (
    <Row>
      <BumpDetailsModal ref={bumpDetailsRef} />
      <BumpJustificationModal
        ref={bumpJustificationRef}
        onResult={(value, reason) => {
          if (value === null) {
            return
          }
          if (reason === CANCEL_BUMP) {
            return
          }
          saveBump(value, reason)
        }}
      />
      <MelddToolTip title={t('Info')}>
        <IconButton
          icon={IconBumpList}
          onPress={() => bumpDetailsRef.current?.show(bumps)}
        />
      </MelddToolTip>

      <SegmentedButtons
        onValueChange={(value) => {
          authAction(navigation, () => handleClick(value as 'up' | 'down'))
        }}
        value={myBumpValue > 0 ? 'up' : myBumpValue < 0 ? 'down' : ''}
        buttons={[
          {
            value: 'down',
            disabled: !canBump,
            label: '' + negativeCount,
            icon: () => (
              <Icon3dBumpDown
                size={20}
                onPress={() =>
                  // Clicking the button makes that the modal lose focus and close before opening
                  setTimeout(
                    () => authAction(navigation, () => handleClick('down')),
                    100
                  )
                }
                mode="text"
              />
            ),
            style: styles.down,
          },
          {
            value: 'up',
            disabled: !canBump,
            label: '' + positiveCount,
            icon: () => (
              <Icon3dBumpUp
                size={20}
                onPress={() =>
                  // Clicking the button makes that the modal lose focus and close before opening
                  setTimeout(
                    () => authAction(navigation, () => handleClick('up')),
                    100
                  )
                }
                mode="text"
              />
            ),
            style: styles.up,
          },
        ]}
      />
    </Row>
  )
}

import 'fast-text-encoding'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import { decode } from 'base64-arraybuffer'
import { AppNavigation } from '../navigation/types'
import Toast from 'react-native-root-toast'
import { MessageType } from './message-type'
import * as Sentry from '@sentry/react-native'
const mammoth = require('mammoth/mammoth.browser')
function elementsOfType(elementType: string, transform: (v: any) => any) {
  return mammoth.transforms._elements(function (element: any) {
    if (element && element.type === elementType) {
      return transform(element)
    } else {
      return element
    }
  })
}

export const importFile = async (
  navigation: AppNavigation,
  clusterId: string
) => {
  try {
    const result = await DocumentPicker.getDocumentAsync({
      multiple: false,
      copyToCacheDirectory: true,
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    })
    console.log(result)
    if (result.type == 'cancel') {
      Toast.show('Canceled', MessageType.info)
      return
    } else if (result.type === 'success') {
      console.log('file', result.file)
      const file = await FileSystem.readAsStringAsync(result.uri, {
        encoding: 'base64',
      })
      const arrayBuffer = decode(file)
      const transformDocument = mammoth.transforms._elements(function (
        element: any
      ) {
        // console.log(element)
        if (
          element &&
          (element.type === 'image' ||
            element.type === 'bookmarkStart' ||
            element.type === 'bookmarkEnd')
        ) {
          return {}
        } else {
          return element
        }
      })

      const htmlResult = await mammoth.convertToHtml(
        {
          arrayBuffer,
        },
        {
          transformDocument,
        }
      )
      console.log(htmlResult)
      if (htmlResult.value) {
        // Toast.show('Got content', MessageType.info)

        navigation.navigate('CreateArticle', {
          clusterId,
          content: htmlResult.value,
          title: result.name,
        })
      } else {
        Toast.show('No content', MessageType.info)
      }
    }
  } catch (e) {
    Toast.show('Error importing: ' + (e as Error).message, MessageType.error)
    console.error(e)
    Sentry.captureException(e)
  }
}

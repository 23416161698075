import { Button, Card, Icon, IconButton, List, Text } from 'react-native-paper'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import { useTranslation } from 'react-i18next'
import {
  GetMyContributionByTaskIdDocument,
  GetTaskContributionsDocument,
  GetTaskDocument,
  PendingTasksDocument,
  useArchiveTaskMutation,
  useContributeOnTaskMutation,
  useDeleteTaskContributionMutation,
  useGetMyContributionByTaskIdQuery,
} from '../../../generated/graphql'
import { createShimmerPlaceHolder } from 'expo-shimmer-placeholder'
import { LinearGradient } from 'expo-linear-gradient'
import { MelddTasks, navigateToTaskTarget } from '../task.types'
import Toast from 'react-native-root-toast'
import { useAuthContext } from '../../../auth/auth-context'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'
import { IconCompleted } from '../../../utils/meldd-icons'
import { useApolloClient } from '@apollo/client'
import { useMemo } from 'react'
import * as Sentry from '@sentry/react-native'
const ShimmerPlaceHolder = createShimmerPlaceHolder(LinearGradient)

const DeleteContributionButton = ({ id }: { id: string }) => {
  const { t } = useTranslation(['tasks', 'common'])
  const [doDelete] = useDeleteTaskContributionMutation()
  const client = useApolloClient()

  const onRemoveContribution = async () => {
    try {
      await doDelete({ variables: { taskContributionId: id } })
      await client.refetchQueries({
        include: [
          GetMyContributionByTaskIdDocument,
          GetTaskContributionsDocument,
        ],
      })
      Toast.show(t('success'))
    } catch (e) {
      console.error(e)
      Toast.show(t((e as Error).message))
      Sentry.captureException(e)
    }
  }
  return (
    <Button
      mode="text"
      onPress={() => {
        onRemoveContribution()
      }}
    >
      {t('cancel')}
    </Button>
  )
}

/**
 * Owner Action feature is postponed, this code is working and tested
 */
const OwnerAction = ({ task }: { task: MelddTasks }) => {
  const { t } = useTranslation(['tasks', 'common'])
  const styles = useStyle()
  const [doArchive] = useArchiveTaskMutation()
  const client = useApolloClient()

  const onArchive = async () => {
    try {
      await doArchive({ variables: { input: { taskId: task.id } } })
      await client.refetchQueries({
        include: [GetTaskDocument],
      })
      Toast.show(t('success'))
    } catch (e) {
      console.error(e)
      Toast.show(t((e as Error).message))
      Sentry.captureException(e)
    }
  }

  return (
    <List.Item
      title={t('your-taks')}
      right={() => {
        if (task.isCompleted) {
          return (
            <TouchableIconWithToolTip
              icon={<Icon source={IconCompleted} size={24} />}
              tooltip={t('completed')}
            />
          )
        }
        if (task.isArchived) {
          return (
            <Button mode="contained" disabled={true}>
              {t('already-archived')}
            </Button>
          )
        }

        return (
          <Button
            mode="contained"
            onPress={() => {
              onArchive()
            }}
          >
            {t('archive')}
          </Button>
        )
      }}
      style={styles.contributeListItem}
    />
  )
}

const GuestAction = ({ task }: { task: MelddTasks }) => {
  const { t } = useTranslation(['tasks', 'common'])
  const { userId } = useAuthContext()
  const { authAction, isAnonymous } = useAuthContext()
  const styles = useStyle()
  const navigation = useNavigation<AppNavigation>()
  const client = useApolloClient()

  const { data, loading, refetch } = useGetMyContributionByTaskIdQuery({
    variables: {
      taskId: task.id,
    },
    skip: isAnonymous,
  })
  const [doContribute] = useContributeOnTaskMutation()

  const onContribute = async () => {
    try {
      await doContribute({
        variables: {
          input: {
            taskId: task.id,
          },
        },
      })
      await client.refetchQueries({
        include: [GetTaskContributionsDocument, PendingTasksDocument],
      })
      Toast.show(t('success'))
      refetch()
    } catch (e) {
      console.error(e)
      Toast.show(t((e as Error).message))
      Sentry.captureException(e)
    }
  }
  const myContribution = data?.getMyContributionByTaskId

  const title = useMemo(() => {
    if (myContribution?.isCompleted) {
      return t('task-completed')
    }
    if (task.isCompleted && task.assignedId === userId) {
      return t('task-completed')
    }

    // Archived
    if (task.isArchived) {
      return t('task-archived')
    }
    // Assigned
    if (task.assignedId) {
      if (task.assignedId === userId) {
        return t('task-assigned-you')
      }
      return t('task-assigned-other')
    }
    // Open for Contributions
    if (myContribution) {
      return t('already-contributor')
    }
    return t('want-to-contribute')
  }, [myContribution, task, t])

  if (loading)
    return (
      <ShimmerPlaceHolder
        visible={true}
        height={40}
        LinearGradient={LinearGradient}
      />
    )

  return (
    <List.Item
      title={title}
      right={() => {
        // Assignee
        if (task.assignedId) {
          if (task.assignedId === userId) {
            if (task.isCompleted) {
              return (
                <TouchableIconWithToolTip
                  icon={<Icon source={IconCompleted} size={24} />}
                  tooltip={t('completed')}
                />
              )
            }
            if (task.isArchived) {
              return null
            }
            return (
              <Button
                onPress={() => {
                  navigateToTaskTarget({ task, navigation })
                }}
              >
                {t('complete')}
              </Button>
            )
          }

          return null
        }
        // If no contribution, show contribute button unless is archived
        if (!myContribution) {
          if (task.isArchived) {
            return null
          }
          return (
            <Button
              mode="contained"
              onPress={() => {
                authAction(navigation, () => onContribute())
              }}
            >
              {t('accept')}
            </Button>
          )
        }
        // If contribution, show completed even is archived
        if (myContribution.isCompleted) {
          return (
            <TouchableIconWithToolTip
              icon={<Icon source={IconCompleted} size={24} />}
              tooltip={t('completed')}
            />
          )
        }
        if (task.isArchived) {
          return null
        }
        return <DeleteContributionButton id={myContribution.id} />
      }}
      style={styles.contributeListItem}
    />
  )
}

export const TaskActions = ({ task }: { task: MelddTasks }) => {
  const { userId } = useAuthContext()

  // Management functionality is postponed
  // if (task.owner.id === userId) {
  //   return <OwnerAction task={task} />
  // }
  // Even owners can accept the task
  return <GuestAction task={task} />
}
const useStyle = createStyle(({ theme }) => ({
  contributeListItem: {
    paddingVertical: theme.spacing(2),
    paddingHorizontal: theme.spacing(4),
  },
}))

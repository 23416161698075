import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { AvailableNodesQuery, useNodeByIdLazyQuery } from '../generated/graphql'
import { useAuthContext } from '../auth/auth-context'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Unpacked } from '../utils/types'
import { MdNode } from '../screens/ListNodes/types'
import Toast from 'react-native-root-toast'
import { MessageType } from '../utils/message-type'
import { useNavigation } from '@react-navigation/native'
import { getErrorCode } from '../utils/error-utils'
import { AppNavigation } from '../navigation/types'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react-native'

interface CurrentNodeProviderProps {
  children: ReactNode
}

interface CurrentNodeProviderShape {
  currentNode?: NdNode
  setCurrentNode: (nodeId: string) => void
  loading: boolean
}

const CURRENT_NODE_KEY = 'currentNode'

const CurrentNodeContext = createContext<CurrentNodeProviderShape>({
  currentNode: undefined,
  setCurrentNode: () => {},
  loading: false,
})

type NdNode = Unpacked<AvailableNodesQuery['availableNodes']>

export function CurrentNodeProvider(props: CurrentNodeProviderProps) {
  const { userId, isAnonymous } = useAuthContext()
  const [currentNode, _setCurrentNode] = useState<MdNode>()
  const [doLoadNode] = useNodeByIdLazyQuery()
  const [loading, setLoading] = useState(true)
  const navigation = useNavigation<AppNavigation>()
  const { t } = useTranslation('nodes')
  const setCurrentNode = useCallback(
    async (nodeId: string) => {
      if (!nodeId) {
        AsyncStorage.removeItem(`${CURRENT_NODE_KEY}:${userId}`)
        return
      }
      try {
        setLoading(true)
        const { data, error } = await doLoadNode({
          variables: { id: nodeId },
          // There is an issue with the cache: You are in a private node, logout and reload, you will get the security error. After login,
          // the call is cached not using the token (after you logout) and keep throwing security error.
          // With this solution may send 1 extra error while you are logging but then is working fine.
          // fetchPolicy: 'cache-and-network',
        })

        if (data) {
          _setCurrentNode(data['nodeById'])
          AsyncStorage.setItem(`${CURRENT_NODE_KEY}:${userId}`, nodeId)
          return
        }

        if (error) {
          const errorCode = getErrorCode(error)
          if (errorCode === 'FORBIDDEN' && isAnonymous) {
            Toast.show(t('private-node-login-required'), {
              position: Toast.positions.CENTER,
            })
            // If we navigate to login, on clicking back we are always redirected to the node list
            // Is better to provide an error (or specific IU / Modal with login button)
            // navigation.navigate('LoginMobileNumber', {
            //   returnPath: getReturnPath(navigation),
            // })
            return
          }
          Toast.show(error.message, MessageType.error)
        }
      } catch (e) {
        Toast.show((e as Error).message, MessageType.error)
        console.error(e)
        Sentry.captureException(e)
      } finally {
        setLoading(false)
      }
    },
    [doLoadNode, userId, navigation, isAnonymous]
  )

  useEffect(() => {
    setCurrentNode(currentNode?.id || '')
  }, [userId])

  const value: CurrentNodeProviderShape = useMemo(
    () => ({
      currentNode,
      setCurrentNode,
      loading,
    }),
    [currentNode, setCurrentNode, loading]
  )

  return <CurrentNodeContext.Provider value={value} {...props} />
}

export const useNode = () => {
  const context = useContext(CurrentNodeContext)

  return context
}

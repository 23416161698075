import * as React from 'react'
import { Switch } from 'react-native-paper'
import {
  StyleProp,
  TextStyle,
  View,
  Text,
  ViewStyle,
  StyleSheet,
  ActivityIndicator,
} from 'react-native'
import { useTheme } from '../hooks/use-theme'

interface LabeledSwitchProps extends React.ComponentProps<typeof Switch> {
  label: string
  isLoading?: boolean
  onLabelPress?: () => void
  wrapperStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
}

export function LabeledSwitch(props: LabeledSwitchProps) {
  const theme = useTheme()
  const styles = StyleSheet.create({
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    },
    label: {
      color: theme.colors.text,
      fontSize: theme.fonts.labelLarge.fontSize,
    },
  })
  const { wrapperStyle, label, labelStyle, ...rest } = props
  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      <Text
        style={[styles.label, labelStyle]}
        onPress={() => props.onLabelPress?.()}
      >
        {label}
      </Text>
      {!props.isLoading ? (
        <Switch {...rest} />
      ) : (
        <ActivityIndicator size="small" color={theme.colors.primary} />
      )}
    </View>
  )
}

import { Platform } from 'react-native'
import Bowser from 'bowser'

export const getBrowser = () => {
  if (Platform.OS !== 'web' || typeof navigator === 'undefined') {
    return null
  }
  return Bowser.getParser(window.navigator.userAgent)
}

export const getBrowserInfo = () => {
  const browser = getBrowser()
  if (!browser) return null

  const browserName = browser.getBrowserName()
  const browserVersion = browser.getBrowserVersion()
  const platform = browser.getPlatform()
  const osName = browser.getOSName()

  const deviceInfo =
    platform.type !== 'desktop' ? platform.model || osName : osName

  return `${browserName} ${browserVersion} - ${deviceInfo}`
}

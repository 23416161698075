import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, Portal, Button, Text, TextInput } from 'react-native-paper'
import { StyleSheet } from 'react-native'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import { TextInput as RNTextInput } from 'react-native'
export const CANCEL_BUMP = Symbol('CANCEL_BUMP')
type Handler = (
  bumpDirection: 'up' | 'down' | null,
  message: string | typeof CANCEL_BUMP
) => void
type BumpProps = {
  onResult: Handler
}

export type BumpJustificationRef = {
  show: (bumpDirection: 'up' | 'down') => void
}

export const BumpJustificationModal = React.forwardRef<
  BumpJustificationRef,
  BumpProps
>(({ onResult }: BumpProps, ref) => {
  const [visible, setVisible] = React.useState(false)
  const [justification, setJustification] = React.useState<string>('')
  const [disabled, setDisabled] = React.useState(false)
  const [bumpDirection, setBumpDirection] = React.useState<
    'up' | 'down' | null
  >(null)
  const inputRef = useRef<RNTextInput>(null)

  const { t } = useTranslation('bumps')
  const cancel = () => {
    setVisible(false)
    onResult(bumpDirection, CANCEL_BUMP)
  }

  const confirm = () => {
    setVisible(false)
    onResult(bumpDirection, justification)
  }

  React.useImperativeHandle(
    ref,
    () => ({
      show: (bumpDirection: 'up' | 'down') => {
        // Remember the bump direction
        setBumpDirection(bumpDirection)
        // Reset the modal
        setVisible(true)
        setJustification('')
        setDisabled(false)
      },
    }),
    []
  )

  // Auto focus
  useEffect(() => {
    if (!visible) return
    // Give a second the ref is mounted
    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }, [visible])

  const styles = useStyles()
  return (
    <Portal>
      <Dialog style={styles.dialog} visible={visible} onDismiss={cancel}>
        <Dialog.Title>
          <Text variant="bodyMedium" style={styles.heading}>
            {t('Motivate your bump')}
          </Text>
        </Dialog.Title>
        <Dialog.Content>
          <TextInput
            ref={inputRef}
            mode={'outlined'}
            onChangeText={setJustification}
            label={t('Reason (optional)')}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button disabled={disabled} onPress={cancel}>
            {t('Cancel')}
          </Button>
          <Button
            mode={'contained'}
            style={styles.button}
            disabled={disabled}
            onPress={() => confirm()}
          >
            {t('Bump')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
})

const useStyles = createStyle(({ theme }) =>
  StyleSheet.create({
    dialog: {
      backgroundColor: theme.colors.surfaceVariant,
    },
    title: {
      fontWeight: 'bold',
    },
    heading: {
      color: theme.colors.onSurfaceVariant,
    },
    button: {
      paddingHorizontal: theme.spacing(2),
    },
  })
)

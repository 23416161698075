import React from 'react'
import { StyleSheet, View } from 'react-native'
import { IconButton } from 'react-native-paper'
import { WhiteCard } from './containers/WhiteCard'

interface NavigationButtonsProps {
  onPrevious?: () => void
  onNext?: () => void
  disabled?: boolean
}

export const NavigationButtons = ({
  onPrevious,
  onNext,
  disabled = false,
}: NavigationButtonsProps) => {
  return (
    <WhiteCard style={{ height: 36, flexDirection: 'row' }}>
      <IconButton
        icon="chevron-left"
        size={20}
        onPress={onPrevious}
        disabled={disabled}
        style={styles.button}
      />
      <View style={styles.divider} />
      <IconButton
        icon="chevron-right"
        size={20}
        onPress={onNext}
        disabled={disabled}
        style={styles.button}
      />
    </WhiteCard>
  )
}

const styles = StyleSheet.create({
  button: {
    margin: 0,
    padding: 0,
    borderRadius: 0,
    width: 48, // Added width
  },
  divider: {
    width: 1,
    height: '100%',
    backgroundColor: '#e0e0e0',
  },
})

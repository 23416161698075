import { useEffect, useState } from 'react'
import { Keyboard, KeyboardEvent, Platform } from 'react-native'

export const useKeyboard = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0)
  const [keyboardDisplayed, setKeyboardDisplayed] = useState(false)

  function onKeyboardDidShow(e: KeyboardEvent) {
    // Remove type here if not using TypeScript
    setKeyboardHeight(e.endCoordinates.height)
    setKeyboardDisplayed(true)
  }

  function onKeyboardDidHide() {
    setKeyboardHeight(0)
    setKeyboardDisplayed(false)
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleResize = () => {
        // Check if visualViewport is supported
        if (window.visualViewport) {
          const viewport = window.visualViewport
          const isKeyboardOpen = window.innerHeight - viewport.height > 150
          setKeyboardDisplayed(isKeyboardOpen)
          setKeyboardHeight(
            isKeyboardOpen ? window.innerHeight - viewport.height : 0
          )
        } else {
          // Fallback for browsers without visualViewport support
          const isKeyboardOpen = window.innerHeight < window.outerHeight * 0.8
          setKeyboardDisplayed(isKeyboardOpen)
          setKeyboardHeight(
            isKeyboardOpen ? window.outerHeight - window.innerHeight : 0
          )
        }
      }

      window.visualViewport?.addEventListener('resize', handleResize)
      return () => {
        window.visualViewport?.removeEventListener('resize', handleResize)
      }
    }

    const showSubscription = Keyboard.addListener(
      'keyboardDidShow',
      onKeyboardDidShow
    )
    const hideSubscription = Keyboard.addListener(
      'keyboardDidHide',
      onKeyboardDidHide
    )
    return () => {
      showSubscription.remove()
      hideSubscription.remove()
    }
  }, [])

  return { keyboardHeight, keyboardDisplayed }
}

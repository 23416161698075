import * as React from 'react'
import {
  FlatList,
  ListRenderItem,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'
import { MenuItem, MenuItemSpec } from './types'

interface MenuState {
  [action: string]: { active: boolean; enabled: boolean }
}

import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AddLinkModal, ModalRef } from './AddLinkModal'

interface ToolbarProps {
  editorState: MenuState
  onMenuItem: (msg: any) => void
  iconTint?: string
  iconSize?: number
  disabledButtonStyle?: ViewStyle
  itemStyle?: TextStyle
}

const styles = StyleSheet.create({
  item: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRightColor: '#E7E7E7',
    borderRightWidth: 1,
    borderLeftColor: '#E7E7E7',
    borderLeftWidth: 1,
  },
  richBar: {
    height: 40,
    backgroundColor: 'white',
    borderColor: '#E7E7E7',
    borderRadius: 1,
    borderWidth: 1,
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    marginBottom: 15,
    width: '98%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
const items: MenuItemSpec[] = [
  {
    action: 'bold',
    message: { type: 'toggleMark', mark: 'strong' },
    icon: 'format-bold',
  },
  {
    action: 'em',
    message: { type: 'toggleMark', mark: 'em' },
    icon: 'format-italic',
  },
  // {action: 'code', message: {type: 'toggleMark', mark: 'code'}, icon: 'code-braces'},
  {
    action: 'paragraph',
    message: { type: 'blockType', block: 'paragraph' },
    icon: 'format-paragraph',
  },
  {
    action: 'link',
    message: { type: 'toggleMark', mark: 'link' },
    icon: 'link',
  },
  {
    action: 'heading1',
    message: { type: 'blockType', block: 'heading', attrs: { level: 1 } },
    icon: 'format-header-1',
  },
  {
    action: 'heading2',
    message: { type: 'blockType', block: 'heading', attrs: { level: 2 } },
    icon: 'format-header-2',
  },
  {
    action: 'heading3',
    message: { type: 'blockType', block: 'heading', attrs: { level: 3 } },
    icon: 'format-header-3',
  },
  {
    action: 'bulletList',
    message: { type: 'wrapList', node: 'bullet_list' },
    icon: 'format-list-bulleted',
  },
  {
    action: 'orderedList',
    message: { type: 'wrapList', node: 'ordered_list' },
    icon: 'format-list-numbered',
  },
  { action: 'lift', message: { type: 'lift' }, icon: 'format-indent-decrease' },
  {
    action: 'joinUp',
    message: { type: 'joinUp' },
    icon: 'arrow-expand-vertical',
  },
  {
    action: 'breakLine',
    message: { type: 'breakLine' },
    icon: 'keyboard-return',
  },
]

export function Toolbar(props: ToolbarProps) {
  const { editorState, onMenuItem, iconSize = 20, iconTint = 'black' } = props
  const addLinkModalRef = React.useRef<ModalRef>(null)
  const callbacks: Record<string, () => void> = {
    link: () => {
      addLinkModalRef.current?.open()
    },
  }
  const menuItems: MenuItem[] = React.useMemo(
    () =>
      items.map((spec) => {
        return {
          ...spec,
          enabled: editorState[spec.action]?.enabled || false,
          active: editorState[spec.action]?.active || false,
          callback: callbacks[spec.action] || undefined,
        }
      }),
    [editorState]
  )
  const renderItem: ListRenderItem<MenuItem> = ({ item }) => {
    const buttonSize = 38
    return (
      <MaterialCommunityIcons.Button
        disabled={!item.enabled}
        name={item.icon}
        onPress={() => {
          item.callback ? item.callback() : onMenuItem(item.message)
        }}
        color={item.enabled ? iconTint : item.active ? iconTint : '#aaa'}
        backgroundColor={
          item.active ? (item.enabled ? 'rgb(96, 80, 220);' : '#aaf') : 'white'
        }
        borderRadius={1}
        size={iconSize}
        style={{
          padding: 0,
          margin: 0,
          width: buttonSize,
          height: buttonSize,
          borderRightColor: '#00000012',
          borderRightWidth: 1,
        }}
        iconStyle={{ marginLeft: 8, padding: 0 }}
      />
    )
  }

  return (
    <View style={styles.richBar}>
      <FlatList
        horizontal
        keyboardShouldPersistTaps={'always'}
        keyExtractor={(item, index) => item.action + '-' + index}
        data={menuItems}
        alwaysBounceHorizontal={false}
        showsHorizontalScrollIndicator={false}
        renderItem={renderItem}
      />
      <AddLinkModal
        ref={addLinkModalRef}
        onSubmit={(url) => {
          const message = menuItems.find(
            (item) => item.action === 'link'
          )?.message
          onMenuItem({ ...message, url })
        }}
      />
    </View>
  )
}

Toolbar.defaultProps = {
  iconTint: 'black',
  iconSize: 20,
  iconGap: 10,
}

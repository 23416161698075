import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { View } from 'react-native'
import {
  Button,
  IconButton,
  TextInput,
  Text,
  Portal,
  Dialog,
} from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import Toast from 'react-native-root-toast'
import { IconAccept, IconReject } from '../../../utils/meldd-icons'
import { useTheme } from '../../../hooks/use-theme'
import {
  ContributionStatus,
  ContributionType,
  UpdateContributionInput,
  useUpdateContributionStatusMutation,
} from '../../../generated/graphql'
import { MessageType } from '../../../utils/message-type'
import Row from '../../../components/containers/Row'
import Column from '../../../components/containers/Column'
import {
  ConfirmDialogue,
  ConfirmDialogueRef,
} from '../../../components/ConfirmDialogue'
import { Contribution } from '../types'
// Used for the ref of text input
import { TextInput as RNTextInput } from 'react-native'
import * as Sentry from '@sentry/react-native'
interface ContributionDecisionBoxProps {
  contribution: Contribution
  canSubmit?: boolean
  onStatusUpdated?: () => void
}

interface CensureButtonProps {
  contributionId: string
  onCensure: () => void
}

const CensureButton = ({ contributionId, onCensure }: CensureButtonProps) => {
  const theme = useTheme()
  const { t } = useTranslation('contributions')
  const [doUpdateContributionStatus] = useUpdateContributionStatusMutation()
  const censureRef = useRef<ConfirmDialogueRef>(null)
  const updateContribution = useCallback(
    async (_status: ContributionStatus) => {
      const input: UpdateContributionInput = {
        contributionId,
        status: _status,
      }

      try {
        await doUpdateContributionStatus({
          variables: { input },
          onQueryUpdated: (query) => {
            if (query.queryName == 'contributionsForMyArticles') {
              query.refetch()
            }
          },
        })
        Toast.show(t('contribution-censured'), MessageType.info)
        onCensure?.()
      } catch (e) {
        Toast.show((e as Error).message, MessageType.error)
        console.error(e)
        Sentry.captureException(e)
      }
    },
    [contributionId, doUpdateContributionStatus, t, onCensure]
  )

  return (
    <>
      <ConfirmDialogue
        ref={censureRef}
        label={t('censure-confirm')}
        acceptLabel={t('Censure')}
        onContinue={() => updateContribution(ContributionStatus.Censured)}
      ></ConfirmDialogue>
      <Button
        onPress={() => censureRef.current?.open()}
        mode={'outlined'}
        style={{ borderColor: theme.colors.danger.main }}
        textColor={theme.colors.danger.main}
      >
        {t('Censure')}
      </Button>
    </>
  )
}

type DecisionModalProps = {
  contribution: Contribution
  onSubmit: ({
    action,
    response,
  }: {
    action: ContributionStatus.Accepted | ContributionStatus.Rejected
    response: string
  }) => void
}

type DecisionModalRef = {
  open: (
    action: ContributionStatus.Accepted | ContributionStatus.Rejected
  ) => void
}

const ContributionDecisionModal = forwardRef<
  DecisionModalRef,
  DecisionModalProps
>(({ onSubmit, contribution }: DecisionModalProps, ref) => {
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState<
    ContributionStatus.Accepted | ContributionStatus.Rejected | null
  >(null)
  const { t } = useTranslation('contributions')
  const [response, setResponse] = useState('')
  const [error, setError] = useState<string>('')
  const theme = useTheme()
  const inputRef = useRef<RNTextInput>(null)

  useImperativeHandle(ref, () => ({
    open: (
      action: ContributionStatus.Accepted | ContributionStatus.Rejected
    ) => {
      setVisible(true)
      setAction(action)
      setError('')
      console.log('inputRef.current', inputRef.current)
    },
  }))

  const onSubmitReason = useCallback(() => {
    if (!action) return
    if (contribution.contributionType !== ContributionType.Edit) {
      if (!response?.trim()) {
        setError(t('response-required'))
        return
      }
    }
    setError('')
    setVisible(false)
    onSubmit({ action, response })
  }, [response, onSubmit, action])

  // Auto focus
  useEffect(() => {
    if (!visible) return
    // Give a second the ref is mounted
    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }, [visible])
  const isRequired = contribution.contributionType !== ContributionType.Edit
  return (
    <Portal>
      <Dialog visible={visible} onDismiss={() => setVisible(false)}>
        <Dialog.Content>
          <View>
            <Row style={{ marginBottom: 8 }}>
              <Text variant={'titleSmall'}>
                {t('response-contributor')}: {isRequired ? '*' : ''}
              </Text>
            </Row>
            <TextInput
              ref={inputRef}
              multiline={true}
              numberOfLines={2}
              mode={'outlined'}
              value={response}
              onChange={({ nativeEvent }) => {
                setResponse(nativeEvent.text)
              }}
            />
            {error && (
              <Text
                variant={'labelSmall'}
                style={{
                  color: theme.colors.danger.main,
                  marginTop: 4,
                }}
              >
                {error}
              </Text>
            )}
          </View>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => setVisible(false)}>{t('Cancel')}</Button>
          <Button
            mode={'contained'}
            onPress={() => {
              onSubmitReason()
            }}
          >
            {action === ContributionStatus.Accepted
              ? t('approve')
              : t('reject')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
})

export const ContributionDecisionBox = ({
  contribution,
  onStatusUpdated,
}: ContributionDecisionBoxProps) => {
  const theme = useTheme()
  const { t } = useTranslation('contributions')
  const [doUpdateContributionStatus] = useUpdateContributionStatusMutation()
  const modalRef = useRef<DecisionModalRef>(null)

  const updateContribution = useCallback(
    async (status: ContributionStatus, response: string) => {
      const input: UpdateContributionInput = {
        contributionId: contribution.id,
        status,
        response,
      }

      try {
        await doUpdateContributionStatus({
          variables: { input },
          onQueryUpdated: (query) => {
            if (query.queryName == 'contributionsForMyArticles') {
              query.refetch()
            }
          },
        })
        Toast.show(
          t('The contribution status has been updated to', {
            status,
          }),
          MessageType.info
        )
        onStatusUpdated?.()
      } catch (e) {
        Toast.show((e as Error).message, MessageType.error)
        console.error(e)
        Sentry.captureException(e)
      }
    },
    [contribution.id, doUpdateContributionStatus, t, onStatusUpdated]
  )
  if (!contribution.id) return null
  if (contribution.status !== ContributionStatus.Pending) return null
  return (
    <Column gap={4} width={'100%'}>
      <ContributionDecisionModal
        contribution={contribution}
        ref={modalRef}
        onSubmit={({ action, response }) => {
          updateContribution(action, response)
        }}
      />

      {/* ==== Buttons ==== */}
      <Row justifyContent="space-between">
        <CensureButton
          contributionId={contribution.id}
          onCensure={() => onStatusUpdated?.()}
        />
        <View style={{ flexGrow: 1 }} />

        <IconButton
          onPress={() => {
            modalRef.current?.open(ContributionStatus.Rejected)
          }}
          size={18}
          iconColor={'white'}
          style={{ backgroundColor: theme.colors.danger.main }}
          icon={IconReject}
        />
        <IconButton
          onPress={() => {
            modalRef.current?.open(ContributionStatus.Accepted)
          }}
          size={18}
          iconColor={'white'}
          style={{ backgroundColor: theme.colors.success.main }}
          icon={IconAccept}
        />
      </Row>
    </Column>
  )
}

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any }
}

export type Article = {
  __typename?: 'Article'
  archivedAt?: Maybe<Scalars['DateTime']['output']>
  articleVotes: Array<ArticleVote>
  cluster: Cluster
  clusterId: Scalars['ID']['output']
  contributionCount: Scalars['Int']['output']
  contributions: Array<Contribution>
  /** Accepted Contributors of an article. */
  contributors: Array<User>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isAutoTitle: Scalars['Boolean']['output']
  isOwner: Scalars['Boolean']['output']
  latestContent: Scalars['String']['output']
  originalContent: Scalars['String']['output']
  owner?: Maybe<User>
  ownerId?: Maybe<Scalars['ID']['output']>
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  version: Scalars['Int']['output']
  voteCount: Scalars['Int']['output']
}

export type ArticleVote = {
  __typename?: 'ArticleVote'
  article: Article
  articleId: Scalars['ID']['output']
  clusterId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isRemoved: Scalars['Boolean']['output']
  justification?: Maybe<Scalars['String']['output']>
  user: User
  userId: Scalars['ID']['output']
}

export type AssignTaskInput = {
  assignedId: Scalars['String']['input']
  taskId: Scalars['ID']['input']
}

export type BumpContributionInput = {
  contributionId: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
  value: Scalars['Int']['input']
}

export type Cluster = {
  __typename?: 'Cluster'
  articleVotes: Array<ArticleVote>
  articles: Array<Article>
  contentUpdatedAt: Scalars['DateTime']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isModerator: Scalars['Boolean']['output']
  isOwner: Scalars['Boolean']['output']
  isPrivate: Scalars['Boolean']['output']
  moderators: Array<ClusterMember>
  node: Node
  nodeId: Scalars['ID']['output']
  showModerators: Scalars['Boolean']['output']
  showPerspectiveOwners: Scalars['Boolean']['output']
  topic: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ClusterMember = {
  __typename?: 'ClusterMember'
  clusterId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isModerator: Scalars['Boolean']['output']
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type Contribution = {
  __typename?: 'Contribution'
  appliedAsVersion: Scalars['Int']['output']
  article: Article
  articleId: Scalars['ID']['output']
  basedOnVersion: Scalars['Int']['output']
  begin: Scalars['Int']['output']
  bumpCount: Scalars['Int']['output']
  bumpScore: Scalars['Int']['output']
  bumps: Array<ContributionBump>
  cluster: Cluster
  clusterId: Scalars['ID']['output']
  contributionType: ContributionType
  createdAt: Scalars['DateTime']['output']
  decisionAt?: Maybe<Scalars['DateTime']['output']>
  end: Scalars['Int']['output']
  id: Scalars['ID']['output']
  input?: Maybe<Scalars['String']['output']>
  intendedType?: Maybe<ContributionType>
  latestContent: Scalars['String']['output']
  motivation?: Maybe<Scalars['String']['output']>
  originalText: Scalars['String']['output']
  reservedUntil?: Maybe<Scalars['DateTime']['output']>
  response?: Maybe<Scalars['String']['output']>
  status: ContributionStatus
  updatedAt: Scalars['DateTime']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type ContributionBump = {
  __typename?: 'ContributionBump'
  contribution: Contribution
  contributionId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  reason: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['ID']['output']
  value: Scalars['Int']['output']
}

export type ContributionResponse = {
  __typename?: 'ContributionResponse'
  contributions: Array<Contribution>
  percentage: Scalars['Float']['output']
}

export enum ContributionStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Censured = 'CENSURED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum ContributionType {
  Challenge = 'CHALLENGE',
  Edit = 'EDIT',
  Inquiry = 'INQUIRY',
  Reserved = 'RESERVED',
}

export type CreateArticleInput = {
  clusterId: Scalars['ID']['input']
  isAutoTitle: Scalars['Boolean']['input']
  originalContent: Scalars['String']['input']
  title?: InputMaybe<Scalars['String']['input']>
}

export type CreateClusterInput = {
  isPrivate: Scalars['Boolean']['input']
  nodeId: Scalars['ID']['input']
  showModerators: Scalars['Boolean']['input']
  showPerspectiveOwners: Scalars['Boolean']['input']
  topic: Scalars['String']['input']
}

export type CreateClusterMemberInput = {
  clusterId: Scalars['ID']['input']
  isModerator: Scalars['Boolean']['input']
  userId: Scalars['ID']['input']
}

export type CreateInvitationInput = {
  maxUsage?: InputMaybe<Scalars['Int']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  nodeId: Scalars['String']['input']
  validAfter?: InputMaybe<Scalars['DateTime']['input']>
  validBefore?: InputMaybe<Scalars['DateTime']['input']>
}

export type CreateMelddTaskInput = {
  targetId: Scalars['String']['input']
  targetType: Scalars['String']['input']
  title: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type CreateNodeInput = {
  description: Scalars['String']['input']
  isPrivate: Scalars['Boolean']['input']
  name: Scalars['String']['input']
}

export type CreateNodeMemberInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>
  nodeId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type GetContributionsByTargetInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>
  targetId: Scalars['ID']['input']
  targetType: Scalars['String']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type GetContributionsFilter = {
  completed?: InputMaybe<Scalars['Boolean']['input']>
  targetType?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type GetContributionsInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>
  targetType?: InputMaybe<Scalars['String']['input']>
  taskId: Scalars['String']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type GetTasksInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  assigned?: InputMaybe<Scalars['Boolean']['input']>
  assignedId?: InputMaybe<Scalars['ID']['input']>
  completed?: InputMaybe<Scalars['Boolean']['input']>
  contributed?: InputMaybe<Scalars['Boolean']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<Array<Scalars['String']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Array<Scalars['String']['input']>>
}

export type GoogleLoginInput = {
  os: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type GoogleTokenInput = {
  os: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type InboundOutboundResponse = {
  __typename?: 'InboundOutboundResponse'
  coRank: Scalars['Float']['output']
  /** To be dropped, use coRank instead */
  corank: Scalars['Float']['output']
  inbound: PerTypeResponse
  outbound: PerTypeResponse
}

export type Invitation = {
  __typename?: 'Invitation'
  id: Scalars['ID']['output']
  maxUsage?: Maybe<Scalars['Int']['output']>
  message?: Maybe<Scalars['String']['output']>
  node?: Maybe<Node>
  nodeId: Scalars['ID']['output']
  usageCount: Scalars['Int']['output']
  validAfter?: Maybe<Scalars['DateTime']['output']>
  validBefore?: Maybe<Scalars['DateTime']['output']>
}

export type InvitationError = {
  __typename?: 'InvitationError'
  code: Scalars['String']['output']
  errorMessage: Scalars['String']['output']
}

export type InvitationResultUnion = Invitation | InvitationError

export type LoginResponse = {
  __typename?: 'LoginResponse'
  accessToken: Scalars['String']['output']
  refreshToken: Scalars['String']['output']
  sub: Scalars['Float']['output']
  user: User
}

export type LoginSsoCheck = {
  __typename?: 'LoginSSOCheck'
  accountExists: Scalars['Boolean']['output']
  ssoName: Scalars['String']['output']
}

export type LoginUserInput = {
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type LoginUserMobileOtpInput = {
  googleToken?: InputMaybe<GoogleTokenInput>
  mobileCountryCode: Scalars['Float']['input']
  mobileNumber: Scalars['String']['input']
  otp: Scalars['String']['input']
  wechatCode?: InputMaybe<Scalars['String']['input']>
}

export type LoginUserOtpInput = {
  otp: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type LogoutInput = {
  token: Scalars['String']['input']
}

export type LogoutResponse = {
  __typename?: 'LogoutResponse'
  isSuccess: Scalars['Boolean']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptInvitation: InvitationResultUnion
  archiveTask: Scalars['Boolean']['output']
  assignTask: Scalars['Boolean']['output']
  bumpContribution: ContributionBump
  cancelReservedContributions: Array<Contribution>
  contributeOnTask: TasksContributor
  createArticle: Article
  createCluster: Cluster
  createClusterMember: ClusterMember
  createInvitation: InvitationResultUnion
  createNode: Node
  createNodeMember: NodeMember
  createTask: Tasks
  deleteAccount: Scalars['String']['output']
  deleteArticle: Scalars['ID']['output']
  deleteCluster: Scalars['ID']['output']
  deleteNode: Scalars['String']['output']
  deleteTaskContribution: Scalars['Boolean']['output']
  emptyContent: Scalars['String']['output']
  generateOpinion: Scalars['String']['output']
  googleCheck: LoginSsoCheck
  googleLogin: LoginResponse
  linkWithGoogle: Scalars['String']['output']
  linkWithWechat: Scalars['String']['output']
  login: LoginResponse
  loginMobileOTP: LoginResponse
  loginOTP: LoginResponse
  logout: LogoutResponse
  placeArticleVote: Array<ArticleVote>
  refresh: LoginResponse
  registerPushToken: RegisterPushTokenPayload
  removeArticleVote: Array<ArticleVote>
  removeClusterMember: Scalars['ID']['output']
  removeNodeMember: Scalars['ID']['output']
  requestMobileVerificationCode: RequestMobileVerificationCodeResponse
  requestOTP: RequestOtpResponse
  reserveContribution: Contribution
  revokeGoogle: Scalars['String']['output']
  revokeWechat: Scalars['String']['output']
  signUp: RegisterResponse
  submitContribution: Contribution
  subscribeWebPush: User
  transferArticleOwnership: Article
  unsubscribeWebPush: User
  updateArticle: Article
  updateCluster: Cluster
  updateClusterMember: ClusterMember
  updateContributionStatus: Contribution
  updateNode: Node
  updateUser: User
  verifyMobile: Scalars['String']['output']
  wechatCheck: LoginSsoCheck
  wechatLogin: LoginResponse
  wechatSignature: WechatJdkSignatureResponse
}

export type MutationAcceptInvitationArgs = {
  id: Scalars['ID']['input']
}

export type MutationArchiveTaskArgs = {
  input: TaskIdInput
}

export type MutationAssignTaskArgs = {
  input: AssignTaskInput
}

export type MutationBumpContributionArgs = {
  input: BumpContributionInput
}

export type MutationContributeOnTaskArgs = {
  input: TaskIdInput
}

export type MutationCreateArticleArgs = {
  input: CreateArticleInput
}

export type MutationCreateClusterArgs = {
  input: CreateClusterInput
}

export type MutationCreateClusterMemberArgs = {
  input: CreateClusterMemberInput
}

export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput
}

export type MutationCreateNodeArgs = {
  input: CreateNodeInput
}

export type MutationCreateNodeMemberArgs = {
  input: CreateNodeMemberInput
}

export type MutationCreateTaskArgs = {
  input: CreateMelddTaskInput
}

export type MutationDeleteArticleArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteClusterArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteNodeArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteTaskContributionArgs = {
  taskContributionId: Scalars['String']['input']
}

export type MutationGenerateOpinionArgs = {
  clusterId: Scalars['String']['input']
  model?: InputMaybe<Scalars['String']['input']>
  prompt: Scalars['String']['input']
}

export type MutationGoogleCheckArgs = {
  input: GoogleLoginInput
}

export type MutationGoogleLoginArgs = {
  input: GoogleLoginInput
}

export type MutationLinkWithGoogleArgs = {
  input: GoogleLoginInput
}

export type MutationLinkWithWechatArgs = {
  input: WechatLoginInput
}

export type MutationLoginArgs = {
  loginUserInput: LoginUserInput
}

export type MutationLoginMobileOtpArgs = {
  input: LoginUserMobileOtpInput
}

export type MutationLoginOtpArgs = {
  input: LoginUserOtpInput
}

export type MutationLogoutArgs = {
  input?: InputMaybe<LogoutInput>
}

export type MutationPlaceArticleVoteArgs = {
  input: PlaceArticleVoteInput
}

export type MutationRefreshArgs = {
  input: RefreshTokenInput
}

export type MutationRegisterPushTokenArgs = {
  input: RegisterPushTokenInput
}

export type MutationRemoveArticleVoteArgs = {
  input: RemoveArticleVoteInput
}

export type MutationRemoveClusterMemberArgs = {
  id: Scalars['ID']['input']
}

export type MutationRemoveNodeMemberArgs = {
  id: Scalars['ID']['input']
}

export type MutationRequestMobileVerificationCodeArgs = {
  input: RequestMobileVerificationInput
}

export type MutationRequestOtpArgs = {
  input: RequestOtpInput
}

export type MutationReserveContributionArgs = {
  input: ReserveContributionInput
}

export type MutationSignUpArgs = {
  input: SignUpInput
}

export type MutationSubmitContributionArgs = {
  input: SubmitContributionInput
}

export type MutationSubscribeWebPushArgs = {
  input: SubscribeWebPushInput
}

export type MutationTransferArticleOwnershipArgs = {
  input: TransferArticleOwnershipInput
}

export type MutationUpdateArticleArgs = {
  input: UpdateArticleInput
}

export type MutationUpdateClusterArgs = {
  input: UpdateClusterInput
}

export type MutationUpdateClusterMemberArgs = {
  input: UpdateClusterMemberInput
}

export type MutationUpdateContributionStatusArgs = {
  input: UpdateContributionInput
}

export type MutationUpdateNodeArgs = {
  input: UpdateNodeInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationVerifyMobileArgs = {
  input: VerifyMobileInput
}

export type MutationWechatCheckArgs = {
  input: WechatLoginInput
}

export type MutationWechatLoginArgs = {
  input: WechatLoginInput
}

export type MutationWechatSignatureArgs = {
  input: WechatJdkSignatureInput
}

export type Node = {
  __typename?: 'Node'
  clusters: Array<Cluster>
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  isAdmin: Scalars['Boolean']['output']
  isPrivate: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type NodeMember = {
  __typename?: 'NodeMember'
  id: Scalars['ID']['output']
  isAdmin: Scalars['Boolean']['output']
  nodeId: Scalars['ID']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type NodeStats = {
  __typename?: 'NodeStats'
  contributions: Array<Contribution>
  node: Node
  nodeId: Scalars['ID']['output']
  perspectives: Array<Article>
  users: Array<User>
}

export type NodeStatsInput = {
  nodeId: Scalars['ID']['input']
}

export type PendingTasksResponse = {
  __typename?: 'PendingTasksResponse'
  pendingContributions: Scalars['Int']['output']
  pendingTasks: Scalars['Int']['output']
}

export type PerTypeResponse = {
  __typename?: 'PerTypeResponse'
  challenge: ContributionResponse
  edit: ContributionResponse
  inquiry: ContributionResponse
}

export type PlaceArticleVoteInput = {
  articleId: Scalars['ID']['input']
  clusterId: Scalars['ID']['input']
  justification?: InputMaybe<Scalars['String']['input']>
}

export type Query = {
  __typename?: 'Query'
  article: Article
  articleVotes: Array<ArticleVote>
  articleVotesByArticleId: Array<ArticleVote>
  articlesForCluster: Array<Article>
  articlesForUser: Array<Article>
  availableClusters: Array<Cluster>
  availableNodes: Array<Node>
  cluster: Cluster
  clusterById: Cluster
  clusterMembers: Array<ClusterMember>
  clusterMembersByClusterId: Array<ClusterMember>
  clusters: Array<Cluster>
  contributionBumpsByContribution: Array<ContributionBump>
  contributionById: Contribution
  contributionsByArticle: Array<Contribution>
  contributionsByCluster: Array<Contribution>
  contributionsByPerspective: Array<Contribution>
  contributionsByUser: Array<Contribution>
  contributionsForMyArticles: Array<Contribution>
  getMyContributionByTaskId?: Maybe<TasksContributor>
  getMyContributions: TaskContributorResponse
  getTargetContributions: TaskContributorResponse
  getTask: Tasks
  getTaskContributions: TaskContributorResponse
  getTasks: TasksResponse
  inboundOutboundContributions: InboundOutboundResponse
  nodeById: Node
  nodeMembers: Array<NodeMember>
  nodeMembersByNodeId: Array<NodeMember>
  nodeStats: NodeStats
  nodes: Array<Node>
  nodesWithClusters: Array<Node>
  pendingTasks?: Maybe<PendingTasksResponse>
  reservedContribution?: Maybe<Contribution>
  reservedContributions: Array<Contribution>
  suggestUsername: SuggestUsernamePayload
  tasksTypes: Array<TaskType>
  user: User
  usernameAvailable: UsernameAvailablePayload
  users: Array<User>
  usersBySearchUsername: Array<User>
  validAppAccessKey: ValidAppAccessKeyPayload
  validInvitation: InvitationResultUnion
}

export type QueryArticleArgs = {
  id: Scalars['ID']['input']
}

export type QueryArticleVotesByArticleIdArgs = {
  articleId: Scalars['ID']['input']
}

export type QueryArticlesForClusterArgs = {
  clusterId: Scalars['ID']['input']
}

export type QueryArticlesForUserArgs = {
  userId: Scalars['ID']['input']
}

export type QueryAvailableClustersArgs = {
  nodeId: Scalars['ID']['input']
}

export type QueryClusterArgs = {
  id: Scalars['ID']['input']
}

export type QueryClusterByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryClusterMembersByClusterIdArgs = {
  clusterId: Scalars['ID']['input']
}

export type QueryContributionBumpsByContributionArgs = {
  contributionId: Scalars['ID']['input']
}

export type QueryContributionByIdArgs = {
  contributionId: Scalars['ID']['input']
}

export type QueryContributionsByArticleArgs = {
  articleId: Scalars['ID']['input']
  status: Array<ContributionStatus>
}

export type QueryContributionsByClusterArgs = {
  clusterId: Scalars['ID']['input']
}

export type QueryContributionsByPerspectiveArgs = {
  articleId: Scalars['ID']['input']
}

export type QueryContributionsByUserArgs = {
  status: Array<ContributionStatus>
  userId: Scalars['ID']['input']
}

export type QueryContributionsForMyArticlesArgs = {
  status: Array<ContributionStatus>
}

export type QueryGetMyContributionByTaskIdArgs = {
  taskId: Scalars['String']['input']
}

export type QueryGetMyContributionsArgs = {
  input: GetContributionsFilter
  offset: Scalars['Float']['input']
  size: Scalars['Float']['input']
}

export type QueryGetTargetContributionsArgs = {
  input: GetContributionsByTargetInput
  offset: Scalars['Float']['input']
  size: Scalars['Float']['input']
}

export type QueryGetTaskArgs = {
  taskId: Scalars['String']['input']
}

export type QueryGetTaskContributionsArgs = {
  input: GetContributionsInput
  offset: Scalars['Float']['input']
  size: Scalars['Float']['input']
}

export type QueryGetTasksArgs = {
  input: GetTasksInput
  offset: Scalars['Float']['input']
  size: Scalars['Float']['input']
}

export type QueryInboundOutboundContributionsArgs = {
  userId: Scalars['ID']['input']
}

export type QueryNodeByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryNodeMembersByNodeIdArgs = {
  nodeId: Scalars['ID']['input']
}

export type QueryNodeStatsArgs = {
  input: NodeStatsInput
}

export type QueryPendingTasksArgs = {
  targetId?: InputMaybe<Scalars['String']['input']>
}

export type QueryReservedContributionArgs = {
  articleId: Scalars['ID']['input']
}

export type QuerySuggestUsernameArgs = {
  input: SuggestUsernameInput
}

export type QueryUserArgs = {
  id: Scalars['ID']['input']
}

export type QueryUsernameAvailableArgs = {
  username: Scalars['String']['input']
}

export type QueryUsersBySearchUsernameArgs = {
  username: Scalars['String']['input']
}

export type QueryValidAppAccessKeyArgs = {
  code: Scalars['String']['input']
}

export type QueryValidInvitationArgs = {
  id: Scalars['ID']['input']
}

export type RefreshTokenInput = {
  id: Scalars['Float']['input']
}

export type RegisterPushTokenInput = {
  token: Scalars['String']['input']
}

export type RegisterPushTokenPayload = {
  __typename?: 'RegisterPushTokenPayload'
  success: Scalars['Boolean']['output']
}

export type RegisterResponse = {
  __typename?: 'RegisterResponse'
  accessToken: Scalars['String']['output']
  refreshToken: Scalars['String']['output']
  sub: Scalars['Float']['output']
  user: User
}

export type RemoveArticleVoteInput = {
  articleId: Scalars['ID']['input']
  clusterId: Scalars['ID']['input']
}

export type RequestMobileVerificationCodeResponse = {
  __typename?: 'RequestMobileVerificationCodeResponse'
  isSent: Scalars['Boolean']['output']
}

export type RequestMobileVerificationInput = {
  /** mobile code */
  mobileCountryCode: Scalars['Int']['input']
  mobileNumber: Scalars['String']['input']
}

export type RequestOtpInput = {
  /** mobile code */
  mobileCountryCode?: InputMaybe<Scalars['Int']['input']>
  mobileNumber?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type RequestOtpResponse = {
  __typename?: 'RequestOtpResponse'
  isSent: Scalars['Boolean']['output']
}

export type ReserveContributionInput = {
  articleId: Scalars['ID']['input']
  basedOnVersion: Scalars['Int']['input']
  begin: Scalars['Int']['input']
  clusterId: Scalars['ID']['input']
  end: Scalars['Int']['input']
  intendedType?: InputMaybe<ContributionType>
}

export type SignUpInput = {
  appAccessKey: Scalars['String']['input']
  firstName: Scalars['String']['input']
  googleToken?: InputMaybe<GoogleTokenInput>
  lastName: Scalars['String']['input']
  /** mobile_country_code */
  mobileCountryCode: Scalars['Int']['input']
  mobileNumber: Scalars['String']['input']
  smsCode: Scalars['String']['input']
  username?: InputMaybe<Scalars['String']['input']>
  wechatCode?: InputMaybe<Scalars['String']['input']>
}

export enum SocketEvents {
  Refresh = 'REFRESH',
  TaskCompleted = 'TASK_COMPLETED',
  TimelineUpdate = 'TIMELINE_UPDATE',
}

export type SubmitContributionInput = {
  contributionId: Scalars['ID']['input']
  input: Scalars['String']['input']
  motivation?: InputMaybe<Scalars['String']['input']>
  type: ContributionType
}

export type SubscribeWebPushInput = {
  deviceInfo?: InputMaybe<Scalars['String']['input']>
  subscription: Scalars['String']['input']
}

export type SuggestUsernameInput = {
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
}

export type SuggestUsernamePayload = {
  __typename?: 'SuggestUsernamePayload'
  username: Scalars['String']['output']
}

export enum TargetType {
  Article = 'ARTICLE',
  Cluster = 'CLUSTER',
  Contribution = 'CONTRIBUTION',
  Node = 'NODE',
}

export type TaskContributorResponse = {
  __typename?: 'TaskContributorResponse'
  count: Scalars['Float']['output']
  results: Array<TasksContributor>
}

export type TaskIdInput = {
  taskId: Scalars['ID']['input']
}

export type TaskType = {
  __typename?: 'TaskType'
  type?: Maybe<Array<Scalars['String']['output']>>
  value?: Maybe<Array<Scalars['String']['output']>>
}

export type Tasks = {
  __typename?: 'Tasks'
  archivedAt: Scalars['DateTime']['output']
  assigned?: Maybe<User>
  assignedId?: Maybe<Scalars['ID']['output']>
  completedAt: Scalars['DateTime']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  isArchived: Scalars['Boolean']['output']
  isCompleted: Scalars['Boolean']['output']
  owner?: Maybe<User>
  targetId: Scalars['String']['output']
  targetType: Scalars['String']['output']
  title: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type TasksContributor = {
  __typename?: 'TasksContributor'
  completedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  isCompleted: Scalars['Boolean']['output']
  task: Tasks
  taskId: Scalars['ID']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type TasksResponse = {
  __typename?: 'TasksResponse'
  count: Scalars['Float']['output']
  results: Array<Tasks>
}

export enum TimeLineAction {
  ChallengeContributionCreated = 'CHALLENGE_CONTRIBUTION_CREATED',
  ClusterCreated = 'CLUSTER_CREATED',
  ClusterDeleted = 'CLUSTER_DELETED',
  ContributionApproved = 'CONTRIBUTION_APPROVED',
  ContributionBumpedDown = 'CONTRIBUTION_BUMPED_DOWN',
  ContributionBumpedUp = 'CONTRIBUTION_BUMPED_UP',
  ContributionRejected = 'CONTRIBUTION_REJECTED',
  EditContributionCreated = 'EDIT_CONTRIBUTION_CREATED',
  InquiryContributionCreated = 'INQUIRY_CONTRIBUTION_CREATED',
  PerspectiveCreated = 'PERSPECTIVE_CREATED',
  PerspectiveDeleted = 'PERSPECTIVE_DELETED',
  PerspectiveTransferred = 'PERSPECTIVE_TRANSFERRED',
  PerspectiveVote = 'PERSPECTIVE_VOTE',
  TaskCreated = 'TASK_CREATED',
}

export type TimeLineModel = {
  __typename?: 'TimeLineModel'
  action: TimeLineAction
  payload: TimelinePayloadBase
  target: TargetType
  targetId: Scalars['String']['output']
  timestamp: Scalars['String']['output']
  tree: Array<TreeNode>
  user: TimelineUser
}

export type TimelinePayloadBase = {
  __typename?: 'TimelinePayloadBase'
  articleId?: Maybe<Scalars['String']['output']>
  clusterId?: Maybe<Scalars['String']['output']>
  contributionId?: Maybe<Scalars['String']['output']>
  motivation?: Maybe<Scalars['String']['output']>
  originalText?: Maybe<Scalars['String']['output']>
  targetId?: Maybe<Scalars['String']['output']>
  targetType?: Maybe<Scalars['String']['output']>
  taskId?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type TimelineUser = {
  __typename?: 'TimelineUser'
  id: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type TransferArticleOwnershipInput = {
  articleId: Scalars['ID']['input']
  newOwnerId: Scalars['ID']['input']
}

export type TreeNode = {
  __typename?: 'TreeNode'
  id: Scalars['String']['output']
  target: TargetType
}

export type UpdateArticleInput = {
  id: Scalars['ID']['input']
  isAutoTitle: Scalars['Boolean']['input']
  title: Scalars['String']['input']
}

export type UpdateClusterInput = {
  clusterId: Scalars['ID']['input']
  isPrivate: Scalars['Boolean']['input']
  showModerators: Scalars['Boolean']['input']
  showPerspectiveOwners: Scalars['Boolean']['input']
  topic: Scalars['String']['input']
}

export type UpdateClusterMemberInput = {
  id: Scalars['ID']['input']
  isModerator: Scalars['Boolean']['input']
}

export type UpdateContributionInput = {
  contributionId: Scalars['ID']['input']
  response?: InputMaybe<Scalars['String']['input']>
  status: ContributionStatus
}

export type UpdateNodeInput = {
  description: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type UpdateUserInput = {
  bio?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type User = {
  __typename?: 'User'
  bio?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  emailAddress?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  googleSync?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>
  isMobileVerified?: Maybe<Scalars['Boolean']['output']>
  isPushNotificationsEnabled: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  mobileCountryCode?: Maybe<Scalars['Int']['output']>
  mobileNumber?: Maybe<Scalars['String']['output']>
  notificationsDeviceInfo?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  username: Scalars['String']['output']
  wechatSync?: Maybe<Scalars['Boolean']['output']>
}

export type UsernameAvailablePayload = {
  __typename?: 'UsernameAvailablePayload'
  isAvailable: Scalars['Boolean']['output']
  message?: Maybe<Scalars['String']['output']>
}

export type ValidAppAccessKeyPayload = {
  __typename?: 'ValidAppAccessKeyPayload'
  isValid: Scalars['Boolean']['output']
}

export type VerifyMobileInput = {
  mobileCountryCode: Scalars['Float']['input']
  mobileNumber: Scalars['String']['input']
  otp: Scalars['String']['input']
}

export type WechatJdkSignatureInput = {
  url: Scalars['String']['input']
}

export type WechatJdkSignatureResponse = {
  __typename?: 'WechatJDKSignatureResponse'
  nonceStr: Scalars['String']['output']
  signature: Scalars['String']['output']
  timestamp: Scalars['String']['output']
}

export type WechatLoginInput = {
  code: Scalars['String']['input']
  provider: Scalars['String']['input']
}

export type VoteDetailFragment = {
  __typename?: 'ArticleVote'
  id: string
  userId: string
  clusterId: string
  articleId: string
  justification?: string | null
  user: { __typename?: 'User'; id: string; username: string }
}

export type PlaceVoteMutationVariables = Exact<{
  input: PlaceArticleVoteInput
}>

export type PlaceVoteMutation = {
  __typename?: 'Mutation'
  placeArticleVote: Array<{
    __typename?: 'ArticleVote'
    id: string
    userId: string
    clusterId: string
    articleId: string
    justification?: string | null
    user: { __typename?: 'User'; id: string; username: string }
  }>
}

export type RemoveVoteMutationVariables = Exact<{
  input: RemoveArticleVoteInput
}>

export type RemoveVoteMutation = {
  __typename?: 'Mutation'
  removeArticleVote: Array<{
    __typename?: 'ArticleVote'
    id: string
    userId: string
    clusterId: string
    articleId: string
    justification?: string | null
    user: { __typename?: 'User'; id: string; username: string }
  }>
}

export type ArticleDetailFragment = {
  __typename?: 'Article'
  id: string
  title: string
  ownerId?: string | null
  clusterId: string
  isAutoTitle: boolean
  version: number
  isOwner: boolean
  cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
  owner?: { __typename?: 'User'; id: string; username: string } | null
}

export type ArticlesForClusterQueryVariables = Exact<{
  clusterId: Scalars['ID']['input']
}>

export type ArticlesForClusterQuery = {
  __typename?: 'Query'
  articlesForCluster: Array<{
    __typename?: 'Article'
    id: string
    title: string
    ownerId?: string | null
    clusterId: string
    isAutoTitle: boolean
    version: number
    isOwner: boolean
    cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
    owner?: { __typename?: 'User'; id: string; username: string } | null
  }>
}

export type ArticlesForUserQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type ArticlesForUserQuery = {
  __typename?: 'Query'
  articlesForUser: Array<{
    __typename?: 'Article'
    contributionCount: number
    id: string
    title: string
    ownerId?: string | null
    clusterId: string
    isAutoTitle: boolean
    version: number
    isOwner: boolean
    cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
    owner?: { __typename?: 'User'; id: string; username: string } | null
  }>
}

export type ArticleQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ArticleQuery = {
  __typename?: 'Query'
  article: {
    __typename?: 'Article'
    originalContent: string
    latestContent: string
    voteCount: number
    id: string
    title: string
    ownerId?: string | null
    clusterId: string
    isAutoTitle: boolean
    version: number
    isOwner: boolean
    contributions: Array<{
      __typename?: 'Contribution'
      input?: string | null
      response?: string | null
      motivation?: string | null
      originalText: string
      intendedType?: ContributionType | null
      reservedUntil?: any | null
      begin: number
      end: number
      id: string
      articleId: string
      status: ContributionStatus
      userId: string
      decisionAt?: any | null
      contributionType: ContributionType
      createdAt: any
      user?: {
        __typename?: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
      } | null
    }>
    cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
    owner?: { __typename?: 'User'; id: string; username: string } | null
  }
}

export type ArticlePathQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ArticlePathQuery = {
  __typename?: 'Query'
  article: {
    __typename?: 'Article'
    id: string
    cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
  }
}

export type ArticleContributorsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ArticleContributorsQuery = {
  __typename?: 'Query'
  article: {
    __typename?: 'Article'
    id: string
    contributors: Array<{ __typename?: 'User'; id: string; username: string }>
  }
}

export type CreateArticleMutationVariables = Exact<{
  input: CreateArticleInput
}>

export type CreateArticleMutation = {
  __typename?: 'Mutation'
  createArticle: {
    __typename?: 'Article'
    latestContent: string
    id: string
    title: string
    ownerId?: string | null
    clusterId: string
    isAutoTitle: boolean
    version: number
    isOwner: boolean
    cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
    owner?: { __typename?: 'User'; id: string; username: string } | null
  }
}

export type UpdateArticleMutationVariables = Exact<{
  input: UpdateArticleInput
}>

export type UpdateArticleMutation = {
  __typename?: 'Mutation'
  updateArticle: {
    __typename?: 'Article'
    latestContent: string
    id: string
    title: string
    ownerId?: string | null
    clusterId: string
    isAutoTitle: boolean
    version: number
    isOwner: boolean
    cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
    owner?: { __typename?: 'User'; id: string; username: string } | null
  }
}

export type DeleteArticleMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteArticleMutation = {
  __typename?: 'Mutation'
  deleteArticle: string
}

export type TransferArticleOwnershipMutationVariables = Exact<{
  input: TransferArticleOwnershipInput
}>

export type TransferArticleOwnershipMutation = {
  __typename?: 'Mutation'
  transferArticleOwnership: {
    __typename?: 'Article'
    latestContent: string
    id: string
    title: string
    ownerId?: string | null
    clusterId: string
    isAutoTitle: boolean
    version: number
    isOwner: boolean
    cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
    owner?: { __typename?: 'User'; id: string; username: string } | null
  }
}

export type ClusterMemberDetailFragment = {
  __typename?: 'ClusterMember'
  id: string
  clusterId: string
  userId: string
  isModerator: boolean
  user: {
    __typename?: 'User'
    id: string
    username: string
    firstName: string
    lastName: string
  }
}

export type UpdateClusterMemberMutationVariables = Exact<{
  input: UpdateClusterMemberInput
}>

export type UpdateClusterMemberMutation = {
  __typename?: 'Mutation'
  updateClusterMember: {
    __typename?: 'ClusterMember'
    id: string
    clusterId: string
    userId: string
    isModerator: boolean
    user: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    }
  }
}

export type CreateClusterMemberMutationVariables = Exact<{
  input: CreateClusterMemberInput
}>

export type CreateClusterMemberMutation = {
  __typename?: 'Mutation'
  createClusterMember: {
    __typename?: 'ClusterMember'
    id: string
    clusterId: string
    userId: string
    isModerator: boolean
    user: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    }
  }
}

export type RemoveClusterMemberMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RemoveClusterMemberMutation = {
  __typename?: 'Mutation'
  removeClusterMember: string
}

export type GetMembersByClusterIdQueryVariables = Exact<{
  clusterId: Scalars['ID']['input']
}>

export type GetMembersByClusterIdQuery = {
  __typename?: 'Query'
  clusterMembersByClusterId: Array<{
    __typename?: 'ClusterMember'
    id: string
    clusterId: string
    userId: string
    isModerator: boolean
    user: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    }
  }>
}

export type ClusterDetailFragment = {
  __typename?: 'Cluster'
  id: string
  isPrivate: boolean
  showModerators: boolean
  showPerspectiveOwners: boolean
  nodeId: string
  topic: string
  contentUpdatedAt: any
}

export type CreateClusterMutationVariables = Exact<{
  input: CreateClusterInput
}>

export type CreateClusterMutation = {
  __typename?: 'Mutation'
  createCluster: {
    __typename?: 'Cluster'
    isModerator: boolean
    id: string
    isPrivate: boolean
    showModerators: boolean
    showPerspectiveOwners: boolean
    nodeId: string
    topic: string
    contentUpdatedAt: any
    moderators: Array<{
      __typename?: 'ClusterMember'
      id: string
      clusterId: string
      userId: string
      isModerator: boolean
      user: {
        __typename?: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
      }
    }>
  }
}

export type UpdateClusterMutationVariables = Exact<{
  input: UpdateClusterInput
}>

export type UpdateClusterMutation = {
  __typename?: 'Mutation'
  updateCluster: {
    __typename?: 'Cluster'
    isModerator: boolean
    id: string
    isPrivate: boolean
    showModerators: boolean
    showPerspectiveOwners: boolean
    nodeId: string
    topic: string
    contentUpdatedAt: any
    moderators: Array<{
      __typename?: 'ClusterMember'
      id: string
      clusterId: string
      userId: string
      isModerator: boolean
      user: {
        __typename?: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
      }
    }>
  }
}

export type DeleteClusterMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteClusterMutation = {
  __typename?: 'Mutation'
  deleteCluster: string
}

export type AvailableClustersQueryVariables = Exact<{
  nodeId: Scalars['ID']['input']
}>

export type AvailableClustersQuery = {
  __typename?: 'Query'
  availableClusters: Array<{
    __typename?: 'Cluster'
    isModerator: boolean
    id: string
    isPrivate: boolean
    showModerators: boolean
    showPerspectiveOwners: boolean
    nodeId: string
    topic: string
    contentUpdatedAt: any
    moderators: Array<{
      __typename?: 'ClusterMember'
      id: string
      clusterId: string
      userId: string
      isModerator: boolean
      user: {
        __typename?: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
      }
    }>
    articleVotes: Array<{
      __typename?: 'ArticleVote'
      articleId: string
      userId: string
      justification?: string | null
      user: { __typename?: 'User'; id: string; username: string }
    }>
    articles: Array<{
      __typename?: 'Article'
      contributionCount: number
      latestContent: string
      id: string
      title: string
      ownerId?: string | null
      clusterId: string
      isAutoTitle: boolean
      version: number
      isOwner: boolean
      contributions: Array<{
        __typename?: 'Contribution'
        id: string
        articleId: string
        status: ContributionStatus
        userId: string
        decisionAt?: any | null
        contributionType: ContributionType
        createdAt: any
        input?: string | null
        response?: string | null
        motivation?: string | null
        originalText: string
        user?: {
          __typename?: 'User'
          id: string
          username: string
          firstName: string
          lastName: string
        } | null
      }>
      cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
      owner?: { __typename?: 'User'; id: string; username: string } | null
    }>
  }>
}

export type ClusterForPerspectiveQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ClusterForPerspectiveQuery = {
  __typename?: 'Query'
  cluster: {
    __typename?: 'Cluster'
    isModerator: boolean
    id: string
    isPrivate: boolean
    showModerators: boolean
    showPerspectiveOwners: boolean
    nodeId: string
    topic: string
    contentUpdatedAt: any
    articleVotes: Array<{
      __typename?: 'ArticleVote'
      articleId: string
      userId: string
      justification?: string | null
      user: { __typename?: 'User'; id: string; username: string }
    }>
    articles: Array<{
      __typename?: 'Article'
      title: string
      latestContent: string
      originalContent: string
      id: string
      ownerId?: string | null
      clusterId: string
      isAutoTitle: boolean
      version: number
      isOwner: boolean
      contributions: Array<{
        __typename?: 'Contribution'
        id: string
        articleId: string
        status: ContributionStatus
        userId: string
        decisionAt?: any | null
        contributionType: ContributionType
        createdAt: any
        input?: string | null
        response?: string | null
        motivation?: string | null
        originalText: string
        user?: {
          __typename?: 'User'
          id: string
          username: string
          firstName: string
          lastName: string
        } | null
      }>
      cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
      owner?: { __typename?: 'User'; id: string; username: string } | null
    }>
  }
}

export type ClusterByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ClusterByIdQuery = {
  __typename?: 'Query'
  clusterById: {
    __typename?: 'Cluster'
    id: string
    isPrivate: boolean
    showModerators: boolean
    showPerspectiveOwners: boolean
    nodeId: string
    topic: string
    contentUpdatedAt: any
  }
}

export type ContributionBumpDetailFragment = {
  __typename?: 'ContributionBump'
  contributionId: string
  userId: string
  value: number
  reason: string
  updatedAt: any
  user: { __typename?: 'User'; id: string; username: string }
}

export type BumpsForContributionQueryVariables = Exact<{
  contributionId: Scalars['ID']['input']
}>

export type BumpsForContributionQuery = {
  __typename?: 'Query'
  contributionBumpsByContribution: Array<{
    __typename?: 'ContributionBump'
    contributionId: string
    userId: string
    value: number
    reason: string
    updatedAt: any
    user: { __typename?: 'User'; id: string; username: string }
  }>
}

export type BumpContributionMutationVariables = Exact<{
  input: BumpContributionInput
}>

export type BumpContributionMutation = {
  __typename?: 'Mutation'
  bumpContribution: {
    __typename?: 'ContributionBump'
    contributionId: string
    userId: string
    value: number
    reason: string
    updatedAt: any
    user: { __typename?: 'User'; id: string; username: string }
  }
}

export type ContributionDetailFragment = {
  __typename?: 'Contribution'
  id: string
  articleId: string
  status: ContributionStatus
  userId: string
  decisionAt?: any | null
  contributionType: ContributionType
  createdAt: any
  input?: string | null
  response?: string | null
  motivation?: string | null
  originalText: string
  user?: {
    __typename?: 'User'
    id: string
    username: string
    firstName: string
    lastName: string
  } | null
}

export type FullContributionDetailFragment = {
  __typename?: 'Contribution'
  clusterId: string
  id: string
  articleId: string
  status: ContributionStatus
  userId: string
  decisionAt?: any | null
  contributionType: ContributionType
  createdAt: any
  input?: string | null
  response?: string | null
  motivation?: string | null
  originalText: string
  user?: {
    __typename?: 'User'
    id: string
    username: string
    firstName: string
    lastName: string
  } | null
  article: {
    __typename?: 'Article'
    id: string
    clusterId: string
    title: string
    isOwner: boolean
  }
}

export type ContributionEditDetailFragment = {
  __typename?: 'Contribution'
  input?: string | null
  response?: string | null
  motivation?: string | null
  originalText: string
  intendedType?: ContributionType | null
  reservedUntil?: any | null
  begin: number
  end: number
  id: string
  articleId: string
  status: ContributionStatus
  userId: string
  decisionAt?: any | null
  contributionType: ContributionType
  createdAt: any
  user?: {
    __typename?: 'User'
    id: string
    username: string
    firstName: string
    lastName: string
  } | null
}

export type ContributionDetailWithNodeFragment = {
  __typename?: 'Contribution'
  clusterId: string
  id: string
  articleId: string
  status: ContributionStatus
  userId: string
  decisionAt?: any | null
  contributionType: ContributionType
  createdAt: any
  input?: string | null
  response?: string | null
  motivation?: string | null
  originalText: string
  article: {
    __typename?: 'Article'
    id: string
    clusterId: string
    title: string
    isOwner: boolean
    cluster: {
      __typename?: 'Cluster'
      id: string
      topic: string
      node: { __typename?: 'Node'; id: string; name: string }
    }
  }
  user?: {
    __typename?: 'User'
    id: string
    username: string
    firstName: string
    lastName: string
  } | null
}

export type ContributionResponseDetailFragment = {
  __typename?: 'ContributionResponse'
  percentage: number
  contributions: Array<{
    __typename?: 'Contribution'
    clusterId: string
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
    article: {
      __typename?: 'Article'
      id: string
      clusterId: string
      title: string
      isOwner: boolean
    }
  }>
}

export type PerTypeResponseDetailFragment = {
  __typename?: 'PerTypeResponse'
  inquiry: {
    __typename?: 'ContributionResponse'
    percentage: number
    contributions: Array<{
      __typename?: 'Contribution'
      clusterId: string
      id: string
      articleId: string
      status: ContributionStatus
      userId: string
      decisionAt?: any | null
      contributionType: ContributionType
      createdAt: any
      input?: string | null
      response?: string | null
      motivation?: string | null
      originalText: string
      user?: {
        __typename?: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
      } | null
      article: {
        __typename?: 'Article'
        id: string
        clusterId: string
        title: string
        isOwner: boolean
      }
    }>
  }
  challenge: {
    __typename?: 'ContributionResponse'
    percentage: number
    contributions: Array<{
      __typename?: 'Contribution'
      clusterId: string
      id: string
      articleId: string
      status: ContributionStatus
      userId: string
      decisionAt?: any | null
      contributionType: ContributionType
      createdAt: any
      input?: string | null
      response?: string | null
      motivation?: string | null
      originalText: string
      user?: {
        __typename?: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
      } | null
      article: {
        __typename?: 'Article'
        id: string
        clusterId: string
        title: string
        isOwner: boolean
      }
    }>
  }
  edit: {
    __typename?: 'ContributionResponse'
    percentage: number
    contributions: Array<{
      __typename?: 'Contribution'
      clusterId: string
      id: string
      articleId: string
      status: ContributionStatus
      userId: string
      decisionAt?: any | null
      contributionType: ContributionType
      createdAt: any
      input?: string | null
      response?: string | null
      motivation?: string | null
      originalText: string
      user?: {
        __typename?: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
      } | null
      article: {
        __typename?: 'Article'
        id: string
        clusterId: string
        title: string
        isOwner: boolean
      }
    }>
  }
}

export type InboundOutboundContributionsQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type InboundOutboundContributionsQuery = {
  __typename?: 'Query'
  inboundOutboundContributions: {
    __typename?: 'InboundOutboundResponse'
    corank: number
    inbound: {
      __typename?: 'PerTypeResponse'
      inquiry: {
        __typename?: 'ContributionResponse'
        percentage: number
        contributions: Array<{
          __typename?: 'Contribution'
          clusterId: string
          id: string
          articleId: string
          status: ContributionStatus
          userId: string
          decisionAt?: any | null
          contributionType: ContributionType
          createdAt: any
          input?: string | null
          response?: string | null
          motivation?: string | null
          originalText: string
          user?: {
            __typename?: 'User'
            id: string
            username: string
            firstName: string
            lastName: string
          } | null
          article: {
            __typename?: 'Article'
            id: string
            clusterId: string
            title: string
            isOwner: boolean
          }
        }>
      }
      challenge: {
        __typename?: 'ContributionResponse'
        percentage: number
        contributions: Array<{
          __typename?: 'Contribution'
          clusterId: string
          id: string
          articleId: string
          status: ContributionStatus
          userId: string
          decisionAt?: any | null
          contributionType: ContributionType
          createdAt: any
          input?: string | null
          response?: string | null
          motivation?: string | null
          originalText: string
          user?: {
            __typename?: 'User'
            id: string
            username: string
            firstName: string
            lastName: string
          } | null
          article: {
            __typename?: 'Article'
            id: string
            clusterId: string
            title: string
            isOwner: boolean
          }
        }>
      }
      edit: {
        __typename?: 'ContributionResponse'
        percentage: number
        contributions: Array<{
          __typename?: 'Contribution'
          clusterId: string
          id: string
          articleId: string
          status: ContributionStatus
          userId: string
          decisionAt?: any | null
          contributionType: ContributionType
          createdAt: any
          input?: string | null
          response?: string | null
          motivation?: string | null
          originalText: string
          user?: {
            __typename?: 'User'
            id: string
            username: string
            firstName: string
            lastName: string
          } | null
          article: {
            __typename?: 'Article'
            id: string
            clusterId: string
            title: string
            isOwner: boolean
          }
        }>
      }
    }
    outbound: {
      __typename?: 'PerTypeResponse'
      inquiry: {
        __typename?: 'ContributionResponse'
        percentage: number
        contributions: Array<{
          __typename?: 'Contribution'
          clusterId: string
          id: string
          articleId: string
          status: ContributionStatus
          userId: string
          decisionAt?: any | null
          contributionType: ContributionType
          createdAt: any
          input?: string | null
          response?: string | null
          motivation?: string | null
          originalText: string
          user?: {
            __typename?: 'User'
            id: string
            username: string
            firstName: string
            lastName: string
          } | null
          article: {
            __typename?: 'Article'
            id: string
            clusterId: string
            title: string
            isOwner: boolean
          }
        }>
      }
      challenge: {
        __typename?: 'ContributionResponse'
        percentage: number
        contributions: Array<{
          __typename?: 'Contribution'
          clusterId: string
          id: string
          articleId: string
          status: ContributionStatus
          userId: string
          decisionAt?: any | null
          contributionType: ContributionType
          createdAt: any
          input?: string | null
          response?: string | null
          motivation?: string | null
          originalText: string
          user?: {
            __typename?: 'User'
            id: string
            username: string
            firstName: string
            lastName: string
          } | null
          article: {
            __typename?: 'Article'
            id: string
            clusterId: string
            title: string
            isOwner: boolean
          }
        }>
      }
      edit: {
        __typename?: 'ContributionResponse'
        percentage: number
        contributions: Array<{
          __typename?: 'Contribution'
          clusterId: string
          id: string
          articleId: string
          status: ContributionStatus
          userId: string
          decisionAt?: any | null
          contributionType: ContributionType
          createdAt: any
          input?: string | null
          response?: string | null
          motivation?: string | null
          originalText: string
          user?: {
            __typename?: 'User'
            id: string
            username: string
            firstName: string
            lastName: string
          } | null
          article: {
            __typename?: 'Article'
            id: string
            clusterId: string
            title: string
            isOwner: boolean
          }
        }>
      }
    }
  }
}

export type ContributionsForArticleQueryVariables = Exact<{
  articleId: Scalars['ID']['input']
  status: Array<ContributionStatus> | ContributionStatus
}>

export type ContributionsForArticleQuery = {
  __typename?: 'Query'
  contributionsByArticle: Array<{
    __typename?: 'Contribution'
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    intendedType?: ContributionType | null
    reservedUntil?: any | null
    begin: number
    end: number
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
  }>
}

export type ContributionsForPerspectiveQueryVariables = Exact<{
  articleId: Scalars['ID']['input']
}>

export type ContributionsForPerspectiveQuery = {
  __typename?: 'Query'
  contributionsByPerspective: Array<{
    __typename?: 'Contribution'
    clusterId: string
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
    article: {
      __typename?: 'Article'
      id: string
      clusterId: string
      title: string
      isOwner: boolean
    }
  }>
}

export type ContributionsForClusterQueryVariables = Exact<{
  clusterId: Scalars['ID']['input']
}>

export type ContributionsForClusterQuery = {
  __typename?: 'Query'
  contributionsByCluster: Array<{
    __typename?: 'Contribution'
    clusterId: string
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
    article: {
      __typename?: 'Article'
      id: string
      clusterId: string
      title: string
      isOwner: boolean
    }
  }>
}

export type ContributionsForUserQueryVariables = Exact<{
  userId: Scalars['ID']['input']
  status: Array<ContributionStatus> | ContributionStatus
}>

export type ContributionsForUserQuery = {
  __typename?: 'Query'
  contributionsByUser: Array<{
    __typename?: 'Contribution'
    clusterId: string
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
    article: {
      __typename?: 'Article'
      id: string
      clusterId: string
      title: string
      isOwner: boolean
    }
  }>
}

export type ContributionsForMyArticlesQueryVariables = Exact<{
  status: Array<ContributionStatus> | ContributionStatus
}>

export type ContributionsForMyArticlesQuery = {
  __typename?: 'Query'
  contributionsForMyArticles: Array<{
    __typename?: 'Contribution'
    clusterId: string
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    article: {
      __typename?: 'Article'
      id: string
      clusterId: string
      title: string
      isOwner: boolean
      cluster: {
        __typename?: 'Cluster'
        id: string
        topic: string
        node: { __typename?: 'Node'; id: string; name: string }
      }
    }
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
  }>
}

export type UpdateContributionStatusMutationVariables = Exact<{
  input: UpdateContributionInput
}>

export type UpdateContributionStatusMutation = {
  __typename?: 'Mutation'
  updateContributionStatus: {
    __typename?: 'Contribution'
    clusterId: string
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
    article: {
      __typename?: 'Article'
      id: string
      clusterId: string
      title: string
      isOwner: boolean
    }
  }
}

export type ReserveContributionMutationVariables = Exact<{
  input: ReserveContributionInput
}>

export type ReserveContributionMutation = {
  __typename?: 'Mutation'
  reserveContribution: {
    __typename?: 'Contribution'
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    intendedType?: ContributionType | null
    reservedUntil?: any | null
    begin: number
    end: number
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
  }
}

export type SubmitContributionMutationVariables = Exact<{
  input: SubmitContributionInput
}>

export type SubmitContributionMutation = {
  __typename?: 'Mutation'
  submitContribution: {
    __typename?: 'Contribution'
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    intendedType?: ContributionType | null
    reservedUntil?: any | null
    begin: number
    end: number
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
  }
}

export type ReservedContributionQueryVariables = Exact<{
  articleId: Scalars['ID']['input']
}>

export type ReservedContributionQuery = {
  __typename?: 'Query'
  reservedContribution?: {
    __typename?: 'Contribution'
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    intendedType?: ContributionType | null
    reservedUntil?: any | null
    begin: number
    end: number
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
  } | null
}

export type ContributionByIdQueryVariables = Exact<{
  contributionId: Scalars['ID']['input']
}>

export type ContributionByIdQuery = {
  __typename?: 'Query'
  contributionById: {
    __typename?: 'Contribution'
    bumpCount: number
    id: string
    articleId: string
    status: ContributionStatus
    userId: string
    decisionAt?: any | null
    contributionType: ContributionType
    createdAt: any
    input?: string | null
    response?: string | null
    motivation?: string | null
    originalText: string
    article: {
      __typename?: 'Article'
      id: string
      title: string
      ownerId?: string | null
      clusterId: string
      isAutoTitle: boolean
      version: number
      isOwner: boolean
      cluster: { __typename?: 'Cluster'; id: string; nodeId: string }
      owner?: { __typename?: 'User'; id: string; username: string } | null
    }
    user?: {
      __typename?: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
    } | null
  }
}

export type UserDetailFragment = {
  __typename?: 'User'
  id: string
  firstName: string
  lastName: string
  username: string
  mobileNumber?: string | null
  mobileCountryCode?: number | null
  googleSync?: boolean | null
  wechatSync?: boolean | null
  emailAddress?: string | null
  notificationsDeviceInfo?: string | null
  isMobileVerified?: boolean | null
  isEmailVerified?: boolean | null
  isPushNotificationsEnabled: boolean
}

export type SubscribeWebPushMutationVariables = Exact<{
  input: SubscribeWebPushInput
}>

export type SubscribeWebPushMutation = {
  __typename?: 'Mutation'
  subscribeWebPush: {
    __typename?: 'User'
    id: string
    firstName: string
    lastName: string
    username: string
    mobileNumber?: string | null
    mobileCountryCode?: number | null
    googleSync?: boolean | null
    wechatSync?: boolean | null
    emailAddress?: string | null
    notificationsDeviceInfo?: string | null
    isMobileVerified?: boolean | null
    isEmailVerified?: boolean | null
    isPushNotificationsEnabled: boolean
  }
}

export type UnsubscribeWebPushMutationVariables = Exact<{
  [key: string]: never
}>

export type UnsubscribeWebPushMutation = {
  __typename?: 'Mutation'
  unsubscribeWebPush: {
    __typename?: 'User'
    id: string
    firstName: string
    lastName: string
    username: string
    mobileNumber?: string | null
    mobileCountryCode?: number | null
    googleSync?: boolean | null
    wechatSync?: boolean | null
    emailAddress?: string | null
    notificationsDeviceInfo?: string | null
    isMobileVerified?: boolean | null
    isEmailVerified?: boolean | null
    isPushNotificationsEnabled: boolean
  }
}

export type CheckInvitationQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CheckInvitationQuery = {
  __typename?: 'Query'
  validInvitation:
    | {
        __typename?: 'Invitation'
        id: string
        message?: string | null
        node?: {
          __typename?: 'Node'
          id: string
          name: string
          description: string
        } | null
      }
    | { __typename?: 'InvitationError'; errorMessage: string; code: string }
}

export type AcceptInvitationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AcceptInvitationMutation = {
  __typename?: 'Mutation'
  acceptInvitation:
    | { __typename?: 'Invitation'; nodeId: string }
    | { __typename?: 'InvitationError'; errorMessage: string; code: string }
}

export type GenerateOpinionMutationVariables = Exact<{
  prompt: Scalars['String']['input']
  model: Scalars['String']['input']
  clusterId: Scalars['String']['input']
}>

export type GenerateOpinionMutation = {
  __typename?: 'Mutation'
  generateOpinion: string
}

export type NodeMemberDetailFragment = {
  __typename?: 'NodeMember'
  id: string
  nodeId: string
  userId: string
  isAdmin: boolean
  user: {
    __typename?: 'User'
    username: string
    firstName: string
    lastName: string
  }
}

export type CreateNodeMemberMutationVariables = Exact<{
  input: CreateNodeMemberInput
}>

export type CreateNodeMemberMutation = {
  __typename?: 'Mutation'
  createNodeMember: {
    __typename?: 'NodeMember'
    id: string
    nodeId: string
    userId: string
    isAdmin: boolean
    user: {
      __typename?: 'User'
      username: string
      firstName: string
      lastName: string
    }
  }
}

export type RemoveNodeMemberMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RemoveNodeMemberMutation = {
  __typename?: 'Mutation'
  removeNodeMember: string
}

export type NodeMembersByNodeIdQueryVariables = Exact<{
  nodeId: Scalars['ID']['input']
}>

export type NodeMembersByNodeIdQuery = {
  __typename?: 'Query'
  nodeMembersByNodeId: Array<{
    __typename?: 'NodeMember'
    id: string
    nodeId: string
    userId: string
    isAdmin: boolean
    user: {
      __typename?: 'User'
      username: string
      firstName: string
      lastName: string
    }
  }>
}

export type AvailableNodesQueryVariables = Exact<{ [key: string]: never }>

export type AvailableNodesQuery = {
  __typename?: 'Query'
  availableNodes: Array<{
    __typename?: 'Node'
    id: string
    name: string
    isPrivate: boolean
    description: string
  }>
}

export type NodeStatsQueryVariables = Exact<{
  input: NodeStatsInput
}>

export type NodeStatsQuery = {
  __typename?: 'Query'
  nodeStats: {
    __typename?: 'NodeStats'
    nodeId: string
    node: {
      __typename?: 'Node'
      id: string
      name: string
      description: string
      isPrivate: boolean
      isAdmin: boolean
    }
    perspectives: Array<{
      __typename?: 'Article'
      id: string
      title: string
      voteCount: number
      cluster: { __typename?: 'Cluster'; id: string; topic: string }
    }>
    contributions: Array<{
      __typename?: 'Contribution'
      bumpScore: number
      clusterId: string
      id: string
      articleId: string
      status: ContributionStatus
      userId: string
      decisionAt?: any | null
      contributionType: ContributionType
      createdAt: any
      input?: string | null
      response?: string | null
      motivation?: string | null
      originalText: string
      user?: {
        __typename?: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
      } | null
      article: {
        __typename?: 'Article'
        id: string
        clusterId: string
        title: string
        isOwner: boolean
      }
    }>
    users: Array<{ __typename?: 'User'; id: string; username: string }>
  }
}

export type NodeByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type NodeByIdQuery = {
  __typename?: 'Query'
  nodeById: {
    __typename?: 'Node'
    id: string
    name: string
    description: string
    isPrivate: boolean
    isAdmin: boolean
  }
}

export type TaskDetailFragment = {
  __typename?: 'Tasks'
  id: string
  title: string
  type: string
  targetId: string
  targetType: string
  createdAt: any
  isArchived: boolean
  isCompleted: boolean
  assignedId?: string | null
  owner?: { __typename?: 'User'; id: string; username: string } | null
}

export type TaskContributorFragment = {
  __typename?: 'TasksContributor'
  id: string
  userId: string
  taskId: string
  isCompleted: boolean
  completedAt?: any | null
  createdAt: any
  user: { __typename?: 'User'; id: string; username: string }
  task: {
    __typename?: 'Tasks'
    id: string
    title: string
    targetId: string
    targetType: string
    type: string
  }
}

export type CreateTaskMutationVariables = Exact<{
  input: CreateMelddTaskInput
}>

export type CreateTaskMutation = {
  __typename?: 'Mutation'
  createTask: {
    __typename?: 'Tasks'
    id: string
    title: string
    type: string
    targetId: string
    targetType: string
  }
}

export type AssignTaskMutationVariables = Exact<{
  input: AssignTaskInput
}>

export type AssignTaskMutation = {
  __typename?: 'Mutation'
  assignTask: boolean
}

export type ArchiveTaskMutationVariables = Exact<{
  input: TaskIdInput
}>

export type ArchiveTaskMutation = {
  __typename?: 'Mutation'
  archiveTask: boolean
}

export type GetTaskQueryVariables = Exact<{
  taskId: Scalars['String']['input']
}>

export type GetTaskQuery = {
  __typename?: 'Query'
  getTask: {
    __typename?: 'Tasks'
    id: string
    title: string
    type: string
    targetId: string
    targetType: string
    createdAt: any
    isArchived: boolean
    isCompleted: boolean
    assignedId?: string | null
    owner?: { __typename?: 'User'; id: string; username: string } | null
  }
}

export type GetTaskContributionsQueryVariables = Exact<{
  input: GetContributionsInput
  offset: Scalars['Float']['input']
  size: Scalars['Float']['input']
}>

export type GetTaskContributionsQuery = {
  __typename?: 'Query'
  getTaskContributions: {
    __typename?: 'TaskContributorResponse'
    count: number
    results: Array<{
      __typename?: 'TasksContributor'
      id: string
      userId: string
      taskId: string
      isCompleted: boolean
      completedAt?: any | null
      createdAt: any
      user: { __typename?: 'User'; id: string; username: string }
      task: {
        __typename?: 'Tasks'
        id: string
        title: string
        targetId: string
        targetType: string
        type: string
      }
    }>
  }
}

export type GetTasksQueryVariables = Exact<{
  input: GetTasksInput
  offset: Scalars['Float']['input']
  size: Scalars['Float']['input']
}>

export type GetTasksQuery = {
  __typename?: 'Query'
  getTasks: {
    __typename?: 'TasksResponse'
    count: number
    results: Array<{
      __typename?: 'Tasks'
      id: string
      title: string
      type: string
      targetId: string
      targetType: string
      createdAt: any
      isArchived: boolean
      isCompleted: boolean
      assignedId?: string | null
      owner?: { __typename?: 'User'; id: string; username: string } | null
    }>
  }
}

export type GetTargetContributionsQueryVariables = Exact<{
  input: GetContributionsByTargetInput
  offset: Scalars['Float']['input']
  size: Scalars['Float']['input']
}>

export type GetTargetContributionsQuery = {
  __typename?: 'Query'
  getTargetContributions: {
    __typename?: 'TaskContributorResponse'
    count: number
    results: Array<{
      __typename?: 'TasksContributor'
      id: string
      userId: string
      taskId: string
      isCompleted: boolean
      completedAt?: any | null
      createdAt: any
      user: { __typename?: 'User'; id: string; username: string }
      task: {
        __typename?: 'Tasks'
        id: string
        title: string
        targetId: string
        targetType: string
        type: string
      }
    }>
  }
}

export type GetMyContributionByTaskIdQueryVariables = Exact<{
  taskId: Scalars['String']['input']
}>

export type GetMyContributionByTaskIdQuery = {
  __typename?: 'Query'
  getMyContributionByTaskId?: {
    __typename?: 'TasksContributor'
    id: string
    userId: string
    taskId: string
    isCompleted: boolean
    completedAt?: any | null
    createdAt: any
    user: { __typename?: 'User'; id: string; username: string }
    task: {
      __typename?: 'Tasks'
      id: string
      title: string
      targetId: string
      targetType: string
      type: string
    }
  } | null
}

export type GetMyContributionsQueryVariables = Exact<{
  input: GetContributionsFilter
  offset: Scalars['Float']['input']
  size: Scalars['Float']['input']
}>

export type GetMyContributionsQuery = {
  __typename?: 'Query'
  getMyContributions: {
    __typename?: 'TaskContributorResponse'
    count: number
    results: Array<{
      __typename?: 'TasksContributor'
      id: string
      userId: string
      taskId: string
      isCompleted: boolean
      completedAt?: any | null
      createdAt: any
      user: { __typename?: 'User'; id: string; username: string }
      task: {
        __typename?: 'Tasks'
        id: string
        title: string
        targetId: string
        targetType: string
        type: string
      }
    }>
  }
}

export type ContributeOnTaskMutationVariables = Exact<{
  input: TaskIdInput
}>

export type ContributeOnTaskMutation = {
  __typename?: 'Mutation'
  contributeOnTask: {
    __typename?: 'TasksContributor'
    id: string
    userId: string
    taskId: string
    isCompleted: boolean
    completedAt?: any | null
    createdAt: any
    user: { __typename?: 'User'; id: string; username: string }
    task: {
      __typename?: 'Tasks'
      id: string
      title: string
      targetId: string
      targetType: string
      type: string
    }
  }
}

export type DeleteTaskContributionMutationVariables = Exact<{
  taskContributionId: Scalars['String']['input']
}>

export type DeleteTaskContributionMutation = {
  __typename?: 'Mutation'
  deleteTaskContribution: boolean
}

export type PendingTasksQueryVariables = Exact<{
  targetId?: InputMaybe<Scalars['String']['input']>
}>

export type PendingTasksQuery = {
  __typename?: 'Query'
  pendingTasks?: {
    __typename?: 'PendingTasksResponse'
    pendingTasks: number
    pendingContributions: number
  } | null
}

export type UserWithBioDetailFragment = {
  __typename?: 'User'
  bio?: string | null
  id: string
  firstName: string
  lastName: string
  username: string
  mobileNumber?: string | null
  mobileCountryCode?: number | null
  googleSync?: boolean | null
  wechatSync?: boolean | null
  emailAddress?: string | null
  notificationsDeviceInfo?: string | null
  isMobileVerified?: boolean | null
  isEmailVerified?: boolean | null
  isPushNotificationsEnabled: boolean
}

export type UserQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type UserQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    bio?: string | null
    id: string
    firstName: string
    lastName: string
    username: string
    mobileNumber?: string | null
    mobileCountryCode?: number | null
    googleSync?: boolean | null
    wechatSync?: boolean | null
    emailAddress?: string | null
    notificationsDeviceInfo?: string | null
    isMobileVerified?: boolean | null
    isEmailVerified?: boolean | null
    isPushNotificationsEnabled: boolean
  }
}

export type SearchUserQueryVariables = Exact<{
  username: Scalars['String']['input']
}>

export type SearchUserQuery = {
  __typename?: 'Query'
  usersBySearchUsername: Array<{
    __typename?: 'User'
    id: string
    username: string
  }>
}

export type SignUpMutationVariables = Exact<{
  input: SignUpInput
}>

export type SignUpMutation = {
  __typename?: 'Mutation'
  signUp: {
    __typename?: 'RegisterResponse'
    accessToken: string
    user: {
      __typename?: 'User'
      id: string
      firstName: string
      lastName: string
      username: string
      mobileNumber?: string | null
      mobileCountryCode?: number | null
      googleSync?: boolean | null
      wechatSync?: boolean | null
      emailAddress?: string | null
      notificationsDeviceInfo?: string | null
      isMobileVerified?: boolean | null
      isEmailVerified?: boolean | null
      isPushNotificationsEnabled: boolean
    }
  }
}

export type LoginMutationVariables = Exact<{
  input: LoginUserInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'LoginResponse'
    accessToken: string
    user: {
      __typename?: 'User'
      id: string
      firstName: string
      lastName: string
      username: string
      mobileNumber?: string | null
      mobileCountryCode?: number | null
      googleSync?: boolean | null
      wechatSync?: boolean | null
      emailAddress?: string | null
      notificationsDeviceInfo?: string | null
      isMobileVerified?: boolean | null
      isEmailVerified?: boolean | null
      isPushNotificationsEnabled: boolean
    }
  }
}

export type LoginOtpMutationVariables = Exact<{
  input: LoginUserOtpInput
}>

export type LoginOtpMutation = {
  __typename?: 'Mutation'
  loginOTP: {
    __typename?: 'LoginResponse'
    accessToken: string
    user: {
      __typename?: 'User'
      id: string
      firstName: string
      lastName: string
      username: string
      mobileNumber?: string | null
      mobileCountryCode?: number | null
      googleSync?: boolean | null
      wechatSync?: boolean | null
      emailAddress?: string | null
      notificationsDeviceInfo?: string | null
      isMobileVerified?: boolean | null
      isEmailVerified?: boolean | null
      isPushNotificationsEnabled: boolean
    }
  }
}

export type LoginMobileOtpMutationVariables = Exact<{
  input: LoginUserMobileOtpInput
}>

export type LoginMobileOtpMutation = {
  __typename?: 'Mutation'
  loginMobileOTP: {
    __typename?: 'LoginResponse'
    accessToken: string
    user: {
      __typename?: 'User'
      id: string
      firstName: string
      lastName: string
      username: string
      mobileNumber?: string | null
      mobileCountryCode?: number | null
      googleSync?: boolean | null
      wechatSync?: boolean | null
      emailAddress?: string | null
      notificationsDeviceInfo?: string | null
      isMobileVerified?: boolean | null
      isEmailVerified?: boolean | null
      isPushNotificationsEnabled: boolean
    }
  }
}

export type RequestMobileVerificationCodeMutationVariables = Exact<{
  input: RequestMobileVerificationInput
}>

export type RequestMobileVerificationCodeMutation = {
  __typename?: 'Mutation'
  requestMobileVerificationCode: {
    __typename?: 'RequestMobileVerificationCodeResponse'
    isSent: boolean
  }
}

export type RequestOtpMutationVariables = Exact<{
  input: RequestOtpInput
}>

export type RequestOtpMutation = {
  __typename?: 'Mutation'
  requestOTP: { __typename?: 'RequestOtpResponse'; isSent: boolean }
}

export type GoogleLoginMutationVariables = Exact<{
  input: GoogleLoginInput
}>

export type GoogleLoginMutation = {
  __typename?: 'Mutation'
  googleLogin: {
    __typename?: 'LoginResponse'
    accessToken: string
    user: {
      __typename?: 'User'
      id: string
      firstName: string
      lastName: string
      username: string
      mobileNumber?: string | null
      mobileCountryCode?: number | null
      googleSync?: boolean | null
      wechatSync?: boolean | null
      emailAddress?: string | null
      notificationsDeviceInfo?: string | null
      isMobileVerified?: boolean | null
      isEmailVerified?: boolean | null
      isPushNotificationsEnabled: boolean
    }
  }
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'User'
    bio?: string | null
    id: string
    firstName: string
    lastName: string
    username: string
    mobileNumber?: string | null
    mobileCountryCode?: number | null
    googleSync?: boolean | null
    wechatSync?: boolean | null
    emailAddress?: string | null
    notificationsDeviceInfo?: string | null
    isMobileVerified?: boolean | null
    isEmailVerified?: boolean | null
    isPushNotificationsEnabled: boolean
  }
}

export type GoogleCheckMutationVariables = Exact<{
  input: GoogleLoginInput
}>

export type GoogleCheckMutation = {
  __typename?: 'Mutation'
  googleCheck: {
    __typename?: 'LoginSSOCheck'
    accountExists: boolean
    ssoName: string
  }
}

export type LogoutMutationVariables = Exact<{
  input?: InputMaybe<LogoutInput>
}>

export type LogoutMutation = {
  __typename?: 'Mutation'
  logout: { __typename?: 'LogoutResponse'; isSuccess: boolean }
}

export type ValidAppAccessKeyQueryVariables = Exact<{
  code: Scalars['String']['input']
}>

export type ValidAppAccessKeyQuery = {
  __typename?: 'Query'
  validAppAccessKey: {
    __typename?: 'ValidAppAccessKeyPayload'
    isValid: boolean
  }
}

export type SuggestUsernameQueryVariables = Exact<{
  input: SuggestUsernameInput
}>

export type SuggestUsernameQuery = {
  __typename?: 'Query'
  suggestUsername: { __typename?: 'SuggestUsernamePayload'; username: string }
}

export type ValidUsernameQueryVariables = Exact<{
  username: Scalars['String']['input']
}>

export type ValidUsernameQuery = {
  __typename?: 'Query'
  usernameAvailable: {
    __typename?: 'UsernameAvailablePayload'
    isAvailable: boolean
    message?: string | null
  }
}

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>

export type DeleteAccountMutation = {
  __typename?: 'Mutation'
  deleteAccount: string
}

export type RegisterPushTokenMutationVariables = Exact<{
  input: RegisterPushTokenInput
}>

export type RegisterPushTokenMutation = {
  __typename?: 'Mutation'
  registerPushToken: {
    __typename?: 'RegisterPushTokenPayload'
    success: boolean
  }
}

export type VerifyMobileMutationVariables = Exact<{
  input: VerifyMobileInput
}>

export type VerifyMobileMutation = {
  __typename?: 'Mutation'
  verifyMobile: string
}

export type LinkWithGoogleMutationVariables = Exact<{
  input: GoogleLoginInput
}>

export type LinkWithGoogleMutation = {
  __typename?: 'Mutation'
  linkWithGoogle: string
}

export type RevokeGoogleMutationVariables = Exact<{ [key: string]: never }>

export type RevokeGoogleMutation = {
  __typename?: 'Mutation'
  revokeGoogle: string
}

export type RevokeWechatMutationVariables = Exact<{ [key: string]: never }>

export type RevokeWechatMutation = {
  __typename?: 'Mutation'
  revokeWechat: string
}

export type WechatLoginMutationVariables = Exact<{
  input: WechatLoginInput
}>

export type WechatLoginMutation = {
  __typename?: 'Mutation'
  wechatLogin: {
    __typename?: 'LoginResponse'
    accessToken: string
    user: {
      __typename?: 'User'
      id: string
      firstName: string
      lastName: string
      username: string
      mobileNumber?: string | null
      mobileCountryCode?: number | null
      googleSync?: boolean | null
      wechatSync?: boolean | null
      emailAddress?: string | null
      notificationsDeviceInfo?: string | null
      isMobileVerified?: boolean | null
      isEmailVerified?: boolean | null
      isPushNotificationsEnabled: boolean
    }
  }
}

export type WechatCheckMutationVariables = Exact<{
  input: WechatLoginInput
}>

export type WechatCheckMutation = {
  __typename?: 'Mutation'
  wechatCheck: {
    __typename?: 'LoginSSOCheck'
    accountExists: boolean
    ssoName: string
  }
}

export type LinkWithWechatMutationVariables = Exact<{
  input: WechatLoginInput
}>

export type LinkWithWechatMutation = {
  __typename?: 'Mutation'
  linkWithWechat: string
}

export type WechatSignatureMutationVariables = Exact<{
  input: WechatJdkSignatureInput
}>

export type WechatSignatureMutation = {
  __typename?: 'Mutation'
  wechatSignature: {
    __typename?: 'WechatJDKSignatureResponse'
    nonceStr: string
    signature: string
    timestamp: string
  }
}

export const VoteDetailFragmentDoc = gql`
  fragment VoteDetail on ArticleVote {
    id
    userId
    clusterId
    articleId
    justification
    user {
      id
      username
    }
  }
`
export const ArticleDetailFragmentDoc = gql`
  fragment ArticleDetail on Article {
    id
    title
    ownerId
    clusterId
    isAutoTitle
    version
    isOwner
    cluster {
      id
      nodeId
    }
    owner {
      id
      username
    }
  }
`
export const ClusterMemberDetailFragmentDoc = gql`
  fragment ClusterMemberDetail on ClusterMember {
    id
    clusterId
    userId
    isModerator
    user {
      id
      username
      firstName
      lastName
    }
  }
`
export const ClusterDetailFragmentDoc = gql`
  fragment ClusterDetail on Cluster {
    id
    isPrivate
    showModerators
    showPerspectiveOwners
    nodeId
    topic
    contentUpdatedAt
  }
`
export const ContributionBumpDetailFragmentDoc = gql`
  fragment ContributionBumpDetail on ContributionBump {
    contributionId
    userId
    value
    reason
    updatedAt
    user {
      id
      username
    }
  }
`
export const ContributionDetailFragmentDoc = gql`
  fragment ContributionDetail on Contribution {
    id
    articleId
    status
    userId
    decisionAt
    contributionType
    createdAt
    input
    response
    motivation
    originalText
    user {
      id
      username
      firstName
      lastName
    }
  }
`
export const ContributionEditDetailFragmentDoc = gql`
  fragment ContributionEditDetail on Contribution {
    ...ContributionDetail
    input
    response
    motivation
    originalText
    intendedType
    reservedUntil
    begin
    end
  }
  ${ContributionDetailFragmentDoc}
`
export const FullContributionDetailFragmentDoc = gql`
  fragment FullContributionDetail on Contribution {
    ...ContributionDetail
    clusterId
    user {
      id
      username
      firstName
      lastName
    }
    article {
      id
      clusterId
      title
      isOwner
    }
  }
  ${ContributionDetailFragmentDoc}
`
export const ContributionDetailWithNodeFragmentDoc = gql`
  fragment ContributionDetailWithNode on Contribution {
    ...FullContributionDetail
    article {
      id
      cluster {
        id
        topic
        node {
          id
          name
        }
      }
    }
  }
  ${FullContributionDetailFragmentDoc}
`
export const ContributionResponseDetailFragmentDoc = gql`
  fragment ContributionResponseDetail on ContributionResponse {
    percentage
    contributions {
      ...FullContributionDetail
    }
  }
  ${FullContributionDetailFragmentDoc}
`
export const PerTypeResponseDetailFragmentDoc = gql`
  fragment PerTypeResponseDetail on PerTypeResponse {
    inquiry {
      ...ContributionResponseDetail
    }
    challenge {
      ...ContributionResponseDetail
    }
    edit {
      ...ContributionResponseDetail
    }
  }
  ${ContributionResponseDetailFragmentDoc}
`
export const NodeMemberDetailFragmentDoc = gql`
  fragment NodeMemberDetail on NodeMember {
    id
    nodeId
    userId
    isAdmin
    user {
      username
      firstName
      lastName
    }
  }
`
export const TaskDetailFragmentDoc = gql`
  fragment TaskDetail on Tasks {
    id
    title
    type
    targetId
    targetType
    createdAt
    isArchived
    isCompleted
    assignedId
    owner {
      id
      username
    }
  }
`
export const TaskContributorFragmentDoc = gql`
  fragment TaskContributor on TasksContributor {
    id
    userId
    taskId
    isCompleted
    completedAt
    createdAt
    user {
      id
      username
    }
    task {
      id
      title
      targetId
      targetType
      type
    }
  }
`
export const UserDetailFragmentDoc = gql`
  fragment UserDetail on User {
    id
    firstName
    lastName
    username
    mobileNumber
    mobileCountryCode
    googleSync
    wechatSync
    emailAddress
    notificationsDeviceInfo
    isMobileVerified
    isEmailVerified
    isPushNotificationsEnabled
  }
`
export const UserWithBioDetailFragmentDoc = gql`
  fragment UserWithBioDetail on User {
    ...UserDetail
    bio
  }
  ${UserDetailFragmentDoc}
`
export const PlaceVoteDocument = gql`
  mutation placeVote($input: PlaceArticleVoteInput!) {
    placeArticleVote(input: $input) {
      ...VoteDetail
    }
  }
  ${VoteDetailFragmentDoc}
`
export type PlaceVoteMutationFn = Apollo.MutationFunction<
  PlaceVoteMutation,
  PlaceVoteMutationVariables
>

/**
 * __usePlaceVoteMutation__
 *
 * To run a mutation, you first call `usePlaceVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeVoteMutation, { data, loading, error }] = usePlaceVoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceVoteMutation,
    PlaceVoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PlaceVoteMutation, PlaceVoteMutationVariables>(
    PlaceVoteDocument,
    options
  )
}
export type PlaceVoteMutationHookResult = ReturnType<
  typeof usePlaceVoteMutation
>
export type PlaceVoteMutationResult = Apollo.MutationResult<PlaceVoteMutation>
export type PlaceVoteMutationOptions = Apollo.BaseMutationOptions<
  PlaceVoteMutation,
  PlaceVoteMutationVariables
>
export const RemoveVoteDocument = gql`
  mutation removeVote($input: RemoveArticleVoteInput!) {
    removeArticleVote(input: $input) {
      ...VoteDetail
    }
  }
  ${VoteDetailFragmentDoc}
`
export type RemoveVoteMutationFn = Apollo.MutationFunction<
  RemoveVoteMutation,
  RemoveVoteMutationVariables
>

/**
 * __useRemoveVoteMutation__
 *
 * To run a mutation, you first call `useRemoveVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVoteMutation, { data, loading, error }] = useRemoveVoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVoteMutation,
    RemoveVoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveVoteMutation, RemoveVoteMutationVariables>(
    RemoveVoteDocument,
    options
  )
}
export type RemoveVoteMutationHookResult = ReturnType<
  typeof useRemoveVoteMutation
>
export type RemoveVoteMutationResult = Apollo.MutationResult<RemoveVoteMutation>
export type RemoveVoteMutationOptions = Apollo.BaseMutationOptions<
  RemoveVoteMutation,
  RemoveVoteMutationVariables
>
export const ArticlesForClusterDocument = gql`
  query articlesForCluster($clusterId: ID!) {
    articlesForCluster(clusterId: $clusterId) {
      ...ArticleDetail
    }
  }
  ${ArticleDetailFragmentDoc}
`

/**
 * __useArticlesForClusterQuery__
 *
 * To run a query within a React component, call `useArticlesForClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesForClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesForClusterQuery({
 *   variables: {
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useArticlesForClusterQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticlesForClusterQuery,
    ArticlesForClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArticlesForClusterQuery,
    ArticlesForClusterQueryVariables
  >(ArticlesForClusterDocument, options)
}
export function useArticlesForClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticlesForClusterQuery,
    ArticlesForClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArticlesForClusterQuery,
    ArticlesForClusterQueryVariables
  >(ArticlesForClusterDocument, options)
}
export function useArticlesForClusterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ArticlesForClusterQuery,
    ArticlesForClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ArticlesForClusterQuery,
    ArticlesForClusterQueryVariables
  >(ArticlesForClusterDocument, options)
}
export type ArticlesForClusterQueryHookResult = ReturnType<
  typeof useArticlesForClusterQuery
>
export type ArticlesForClusterLazyQueryHookResult = ReturnType<
  typeof useArticlesForClusterLazyQuery
>
export type ArticlesForClusterSuspenseQueryHookResult = ReturnType<
  typeof useArticlesForClusterSuspenseQuery
>
export type ArticlesForClusterQueryResult = Apollo.QueryResult<
  ArticlesForClusterQuery,
  ArticlesForClusterQueryVariables
>
export const ArticlesForUserDocument = gql`
  query articlesForUser($userId: ID!) {
    articlesForUser(userId: $userId) {
      ...ArticleDetail
      cluster {
        id
        nodeId
      }
      contributionCount
    }
  }
  ${ArticleDetailFragmentDoc}
`

/**
 * __useArticlesForUserQuery__
 *
 * To run a query within a React component, call `useArticlesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useArticlesForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticlesForUserQuery,
    ArticlesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArticlesForUserQuery, ArticlesForUserQueryVariables>(
    ArticlesForUserDocument,
    options
  )
}
export function useArticlesForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticlesForUserQuery,
    ArticlesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArticlesForUserQuery,
    ArticlesForUserQueryVariables
  >(ArticlesForUserDocument, options)
}
export function useArticlesForUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ArticlesForUserQuery,
    ArticlesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ArticlesForUserQuery,
    ArticlesForUserQueryVariables
  >(ArticlesForUserDocument, options)
}
export type ArticlesForUserQueryHookResult = ReturnType<
  typeof useArticlesForUserQuery
>
export type ArticlesForUserLazyQueryHookResult = ReturnType<
  typeof useArticlesForUserLazyQuery
>
export type ArticlesForUserSuspenseQueryHookResult = ReturnType<
  typeof useArticlesForUserSuspenseQuery
>
export type ArticlesForUserQueryResult = Apollo.QueryResult<
  ArticlesForUserQuery,
  ArticlesForUserQueryVariables
>
export const ArticleDocument = gql`
  query article($id: ID!) {
    article(id: $id) {
      ...ArticleDetail
      originalContent
      latestContent
      voteCount
      contributions {
        ...ContributionEditDetail
      }
    }
  }
  ${ArticleDetailFragmentDoc}
  ${ContributionEditDetailFragmentDoc}
`

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleQuery(
  baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(
    ArticleDocument,
    options
  )
}
export function useArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(
    ArticleDocument,
    options
  )
}
export function useArticleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ArticleQuery,
    ArticleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ArticleQuery, ArticleQueryVariables>(
    ArticleDocument,
    options
  )
}
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>
export type ArticleSuspenseQueryHookResult = ReturnType<
  typeof useArticleSuspenseQuery
>
export type ArticleQueryResult = Apollo.QueryResult<
  ArticleQuery,
  ArticleQueryVariables
>
export const ArticlePathDocument = gql`
  query articlePath($id: ID!) {
    article(id: $id) {
      id
      cluster {
        id
        nodeId
      }
    }
  }
`

/**
 * __useArticlePathQuery__
 *
 * To run a query within a React component, call `useArticlePathQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlePathQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticlePathQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticlePathQuery,
    ArticlePathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArticlePathQuery, ArticlePathQueryVariables>(
    ArticlePathDocument,
    options
  )
}
export function useArticlePathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticlePathQuery,
    ArticlePathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArticlePathQuery, ArticlePathQueryVariables>(
    ArticlePathDocument,
    options
  )
}
export function useArticlePathSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ArticlePathQuery,
    ArticlePathQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ArticlePathQuery, ArticlePathQueryVariables>(
    ArticlePathDocument,
    options
  )
}
export type ArticlePathQueryHookResult = ReturnType<typeof useArticlePathQuery>
export type ArticlePathLazyQueryHookResult = ReturnType<
  typeof useArticlePathLazyQuery
>
export type ArticlePathSuspenseQueryHookResult = ReturnType<
  typeof useArticlePathSuspenseQuery
>
export type ArticlePathQueryResult = Apollo.QueryResult<
  ArticlePathQuery,
  ArticlePathQueryVariables
>
export const ArticleContributorsDocument = gql`
  query articleContributors($id: ID!) {
    article(id: $id) {
      id
      contributors {
        id
        username
      }
    }
  }
`

/**
 * __useArticleContributorsQuery__
 *
 * To run a query within a React component, call `useArticleContributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleContributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleContributorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleContributorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticleContributorsQuery,
    ArticleContributorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArticleContributorsQuery,
    ArticleContributorsQueryVariables
  >(ArticleContributorsDocument, options)
}
export function useArticleContributorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleContributorsQuery,
    ArticleContributorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArticleContributorsQuery,
    ArticleContributorsQueryVariables
  >(ArticleContributorsDocument, options)
}
export function useArticleContributorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ArticleContributorsQuery,
    ArticleContributorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ArticleContributorsQuery,
    ArticleContributorsQueryVariables
  >(ArticleContributorsDocument, options)
}
export type ArticleContributorsQueryHookResult = ReturnType<
  typeof useArticleContributorsQuery
>
export type ArticleContributorsLazyQueryHookResult = ReturnType<
  typeof useArticleContributorsLazyQuery
>
export type ArticleContributorsSuspenseQueryHookResult = ReturnType<
  typeof useArticleContributorsSuspenseQuery
>
export type ArticleContributorsQueryResult = Apollo.QueryResult<
  ArticleContributorsQuery,
  ArticleContributorsQueryVariables
>
export const CreateArticleDocument = gql`
  mutation createArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      ...ArticleDetail
      latestContent
    }
  }
  ${ArticleDetailFragmentDoc}
`
export type CreateArticleMutationFn = Apollo.MutationFunction<
  CreateArticleMutation,
  CreateArticleMutationVariables
>

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateArticleMutation,
    CreateArticleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateArticleMutation,
    CreateArticleMutationVariables
  >(CreateArticleDocument, options)
}
export type CreateArticleMutationHookResult = ReturnType<
  typeof useCreateArticleMutation
>
export type CreateArticleMutationResult =
  Apollo.MutationResult<CreateArticleMutation>
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleMutation,
  CreateArticleMutationVariables
>
export const UpdateArticleDocument = gql`
  mutation updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      ...ArticleDetail
      latestContent
    }
  }
  ${ArticleDetailFragmentDoc}
`
export type UpdateArticleMutationFn = Apollo.MutationFunction<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleMutation,
    UpdateArticleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateArticleMutation,
    UpdateArticleMutationVariables
  >(UpdateArticleDocument, options)
}
export type UpdateArticleMutationHookResult = ReturnType<
  typeof useUpdateArticleMutation
>
export type UpdateArticleMutationResult =
  Apollo.MutationResult<UpdateArticleMutation>
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>
export const DeleteArticleDocument = gql`
  mutation deleteArticle($id: ID!) {
    deleteArticle(id: $id)
  }
`
export type DeleteArticleMutationFn = Apollo.MutationFunction<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleMutation,
    DeleteArticleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteArticleMutation,
    DeleteArticleMutationVariables
  >(DeleteArticleDocument, options)
}
export type DeleteArticleMutationHookResult = ReturnType<
  typeof useDeleteArticleMutation
>
export type DeleteArticleMutationResult =
  Apollo.MutationResult<DeleteArticleMutation>
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>
export const TransferArticleOwnershipDocument = gql`
  mutation transferArticleOwnership($input: TransferArticleOwnershipInput!) {
    transferArticleOwnership(input: $input) {
      ...ArticleDetail
      latestContent
    }
  }
  ${ArticleDetailFragmentDoc}
`
export type TransferArticleOwnershipMutationFn = Apollo.MutationFunction<
  TransferArticleOwnershipMutation,
  TransferArticleOwnershipMutationVariables
>

/**
 * __useTransferArticleOwnershipMutation__
 *
 * To run a mutation, you first call `useTransferArticleOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferArticleOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferArticleOwnershipMutation, { data, loading, error }] = useTransferArticleOwnershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferArticleOwnershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferArticleOwnershipMutation,
    TransferArticleOwnershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TransferArticleOwnershipMutation,
    TransferArticleOwnershipMutationVariables
  >(TransferArticleOwnershipDocument, options)
}
export type TransferArticleOwnershipMutationHookResult = ReturnType<
  typeof useTransferArticleOwnershipMutation
>
export type TransferArticleOwnershipMutationResult =
  Apollo.MutationResult<TransferArticleOwnershipMutation>
export type TransferArticleOwnershipMutationOptions =
  Apollo.BaseMutationOptions<
    TransferArticleOwnershipMutation,
    TransferArticleOwnershipMutationVariables
  >
export const UpdateClusterMemberDocument = gql`
  mutation updateClusterMember($input: UpdateClusterMemberInput!) {
    updateClusterMember(input: $input) {
      ...ClusterMemberDetail
    }
  }
  ${ClusterMemberDetailFragmentDoc}
`
export type UpdateClusterMemberMutationFn = Apollo.MutationFunction<
  UpdateClusterMemberMutation,
  UpdateClusterMemberMutationVariables
>

/**
 * __useUpdateClusterMemberMutation__
 *
 * To run a mutation, you first call `useUpdateClusterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClusterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClusterMemberMutation, { data, loading, error }] = useUpdateClusterMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClusterMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClusterMemberMutation,
    UpdateClusterMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateClusterMemberMutation,
    UpdateClusterMemberMutationVariables
  >(UpdateClusterMemberDocument, options)
}
export type UpdateClusterMemberMutationHookResult = ReturnType<
  typeof useUpdateClusterMemberMutation
>
export type UpdateClusterMemberMutationResult =
  Apollo.MutationResult<UpdateClusterMemberMutation>
export type UpdateClusterMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateClusterMemberMutation,
  UpdateClusterMemberMutationVariables
>
export const CreateClusterMemberDocument = gql`
  mutation createClusterMember($input: CreateClusterMemberInput!) {
    createClusterMember(input: $input) {
      ...ClusterMemberDetail
    }
  }
  ${ClusterMemberDetailFragmentDoc}
`
export type CreateClusterMemberMutationFn = Apollo.MutationFunction<
  CreateClusterMemberMutation,
  CreateClusterMemberMutationVariables
>

/**
 * __useCreateClusterMemberMutation__
 *
 * To run a mutation, you first call `useCreateClusterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClusterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClusterMemberMutation, { data, loading, error }] = useCreateClusterMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClusterMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClusterMemberMutation,
    CreateClusterMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateClusterMemberMutation,
    CreateClusterMemberMutationVariables
  >(CreateClusterMemberDocument, options)
}
export type CreateClusterMemberMutationHookResult = ReturnType<
  typeof useCreateClusterMemberMutation
>
export type CreateClusterMemberMutationResult =
  Apollo.MutationResult<CreateClusterMemberMutation>
export type CreateClusterMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateClusterMemberMutation,
  CreateClusterMemberMutationVariables
>
export const RemoveClusterMemberDocument = gql`
  mutation removeClusterMember($id: ID!) {
    removeClusterMember(id: $id)
  }
`
export type RemoveClusterMemberMutationFn = Apollo.MutationFunction<
  RemoveClusterMemberMutation,
  RemoveClusterMemberMutationVariables
>

/**
 * __useRemoveClusterMemberMutation__
 *
 * To run a mutation, you first call `useRemoveClusterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClusterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClusterMemberMutation, { data, loading, error }] = useRemoveClusterMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClusterMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClusterMemberMutation,
    RemoveClusterMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveClusterMemberMutation,
    RemoveClusterMemberMutationVariables
  >(RemoveClusterMemberDocument, options)
}
export type RemoveClusterMemberMutationHookResult = ReturnType<
  typeof useRemoveClusterMemberMutation
>
export type RemoveClusterMemberMutationResult =
  Apollo.MutationResult<RemoveClusterMemberMutation>
export type RemoveClusterMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveClusterMemberMutation,
  RemoveClusterMemberMutationVariables
>
export const GetMembersByClusterIdDocument = gql`
  query getMembersByClusterId($clusterId: ID!) {
    clusterMembersByClusterId(clusterId: $clusterId) {
      ...ClusterMemberDetail
    }
  }
  ${ClusterMemberDetailFragmentDoc}
`

/**
 * __useGetMembersByClusterIdQuery__
 *
 * To run a query within a React component, call `useGetMembersByClusterIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersByClusterIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersByClusterIdQuery({
 *   variables: {
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useGetMembersByClusterIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMembersByClusterIdQuery,
    GetMembersByClusterIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMembersByClusterIdQuery,
    GetMembersByClusterIdQueryVariables
  >(GetMembersByClusterIdDocument, options)
}
export function useGetMembersByClusterIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMembersByClusterIdQuery,
    GetMembersByClusterIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMembersByClusterIdQuery,
    GetMembersByClusterIdQueryVariables
  >(GetMembersByClusterIdDocument, options)
}
export function useGetMembersByClusterIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMembersByClusterIdQuery,
    GetMembersByClusterIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetMembersByClusterIdQuery,
    GetMembersByClusterIdQueryVariables
  >(GetMembersByClusterIdDocument, options)
}
export type GetMembersByClusterIdQueryHookResult = ReturnType<
  typeof useGetMembersByClusterIdQuery
>
export type GetMembersByClusterIdLazyQueryHookResult = ReturnType<
  typeof useGetMembersByClusterIdLazyQuery
>
export type GetMembersByClusterIdSuspenseQueryHookResult = ReturnType<
  typeof useGetMembersByClusterIdSuspenseQuery
>
export type GetMembersByClusterIdQueryResult = Apollo.QueryResult<
  GetMembersByClusterIdQuery,
  GetMembersByClusterIdQueryVariables
>
export const CreateClusterDocument = gql`
  mutation createCluster($input: CreateClusterInput!) {
    createCluster(input: $input) {
      ...ClusterDetail
      isModerator
      moderators {
        ...ClusterMemberDetail
      }
    }
  }
  ${ClusterDetailFragmentDoc}
  ${ClusterMemberDetailFragmentDoc}
`
export type CreateClusterMutationFn = Apollo.MutationFunction<
  CreateClusterMutation,
  CreateClusterMutationVariables
>

/**
 * __useCreateClusterMutation__
 *
 * To run a mutation, you first call `useCreateClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClusterMutation, { data, loading, error }] = useCreateClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClusterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClusterMutation,
    CreateClusterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateClusterMutation,
    CreateClusterMutationVariables
  >(CreateClusterDocument, options)
}
export type CreateClusterMutationHookResult = ReturnType<
  typeof useCreateClusterMutation
>
export type CreateClusterMutationResult =
  Apollo.MutationResult<CreateClusterMutation>
export type CreateClusterMutationOptions = Apollo.BaseMutationOptions<
  CreateClusterMutation,
  CreateClusterMutationVariables
>
export const UpdateClusterDocument = gql`
  mutation updateCluster($input: UpdateClusterInput!) {
    updateCluster(input: $input) {
      ...ClusterDetail
      isModerator
      moderators {
        ...ClusterMemberDetail
      }
    }
  }
  ${ClusterDetailFragmentDoc}
  ${ClusterMemberDetailFragmentDoc}
`
export type UpdateClusterMutationFn = Apollo.MutationFunction<
  UpdateClusterMutation,
  UpdateClusterMutationVariables
>

/**
 * __useUpdateClusterMutation__
 *
 * To run a mutation, you first call `useUpdateClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClusterMutation, { data, loading, error }] = useUpdateClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClusterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClusterMutation,
    UpdateClusterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateClusterMutation,
    UpdateClusterMutationVariables
  >(UpdateClusterDocument, options)
}
export type UpdateClusterMutationHookResult = ReturnType<
  typeof useUpdateClusterMutation
>
export type UpdateClusterMutationResult =
  Apollo.MutationResult<UpdateClusterMutation>
export type UpdateClusterMutationOptions = Apollo.BaseMutationOptions<
  UpdateClusterMutation,
  UpdateClusterMutationVariables
>
export const DeleteClusterDocument = gql`
  mutation deleteCluster($id: ID!) {
    deleteCluster(id: $id)
  }
`
export type DeleteClusterMutationFn = Apollo.MutationFunction<
  DeleteClusterMutation,
  DeleteClusterMutationVariables
>

/**
 * __useDeleteClusterMutation__
 *
 * To run a mutation, you first call `useDeleteClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClusterMutation, { data, loading, error }] = useDeleteClusterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClusterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClusterMutation,
    DeleteClusterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteClusterMutation,
    DeleteClusterMutationVariables
  >(DeleteClusterDocument, options)
}
export type DeleteClusterMutationHookResult = ReturnType<
  typeof useDeleteClusterMutation
>
export type DeleteClusterMutationResult =
  Apollo.MutationResult<DeleteClusterMutation>
export type DeleteClusterMutationOptions = Apollo.BaseMutationOptions<
  DeleteClusterMutation,
  DeleteClusterMutationVariables
>
export const AvailableClustersDocument = gql`
  query availableClusters($nodeId: ID!) {
    availableClusters(nodeId: $nodeId) {
      ...ClusterDetail
      moderators {
        ...ClusterMemberDetail
      }
      isModerator
      articleVotes {
        articleId
        userId
        justification
        user {
          id
          username
        }
      }
      articles {
        contributionCount
        ...ArticleDetail
        latestContent
        contributions {
          ...ContributionDetail
        }
      }
    }
  }
  ${ClusterDetailFragmentDoc}
  ${ClusterMemberDetailFragmentDoc}
  ${ArticleDetailFragmentDoc}
  ${ContributionDetailFragmentDoc}
`

/**
 * __useAvailableClustersQuery__
 *
 * To run a query within a React component, call `useAvailableClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableClustersQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useAvailableClustersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableClustersQuery,
    AvailableClustersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AvailableClustersQuery,
    AvailableClustersQueryVariables
  >(AvailableClustersDocument, options)
}
export function useAvailableClustersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableClustersQuery,
    AvailableClustersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AvailableClustersQuery,
    AvailableClustersQueryVariables
  >(AvailableClustersDocument, options)
}
export function useAvailableClustersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AvailableClustersQuery,
    AvailableClustersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AvailableClustersQuery,
    AvailableClustersQueryVariables
  >(AvailableClustersDocument, options)
}
export type AvailableClustersQueryHookResult = ReturnType<
  typeof useAvailableClustersQuery
>
export type AvailableClustersLazyQueryHookResult = ReturnType<
  typeof useAvailableClustersLazyQuery
>
export type AvailableClustersSuspenseQueryHookResult = ReturnType<
  typeof useAvailableClustersSuspenseQuery
>
export type AvailableClustersQueryResult = Apollo.QueryResult<
  AvailableClustersQuery,
  AvailableClustersQueryVariables
>
export const ClusterForPerspectiveDocument = gql`
  query clusterForPerspective($id: ID!) {
    cluster(id: $id) {
      ...ClusterDetail
      isModerator
      articleVotes {
        articleId
        userId
        justification
        user {
          id
          username
        }
      }
      articles {
        ...ArticleDetail
        title
        latestContent
        originalContent
        contributions {
          ...ContributionDetail
        }
      }
    }
  }
  ${ClusterDetailFragmentDoc}
  ${ArticleDetailFragmentDoc}
  ${ContributionDetailFragmentDoc}
`

/**
 * __useClusterForPerspectiveQuery__
 *
 * To run a query within a React component, call `useClusterForPerspectiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useClusterForPerspectiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClusterForPerspectiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClusterForPerspectiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClusterForPerspectiveQuery,
    ClusterForPerspectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ClusterForPerspectiveQuery,
    ClusterForPerspectiveQueryVariables
  >(ClusterForPerspectiveDocument, options)
}
export function useClusterForPerspectiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClusterForPerspectiveQuery,
    ClusterForPerspectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ClusterForPerspectiveQuery,
    ClusterForPerspectiveQueryVariables
  >(ClusterForPerspectiveDocument, options)
}
export function useClusterForPerspectiveSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ClusterForPerspectiveQuery,
    ClusterForPerspectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ClusterForPerspectiveQuery,
    ClusterForPerspectiveQueryVariables
  >(ClusterForPerspectiveDocument, options)
}
export type ClusterForPerspectiveQueryHookResult = ReturnType<
  typeof useClusterForPerspectiveQuery
>
export type ClusterForPerspectiveLazyQueryHookResult = ReturnType<
  typeof useClusterForPerspectiveLazyQuery
>
export type ClusterForPerspectiveSuspenseQueryHookResult = ReturnType<
  typeof useClusterForPerspectiveSuspenseQuery
>
export type ClusterForPerspectiveQueryResult = Apollo.QueryResult<
  ClusterForPerspectiveQuery,
  ClusterForPerspectiveQueryVariables
>
export const ClusterByIdDocument = gql`
  query clusterById($id: ID!) {
    clusterById(id: $id) {
      ...ClusterDetail
    }
  }
  ${ClusterDetailFragmentDoc}
`

/**
 * __useClusterByIdQuery__
 *
 * To run a query within a React component, call `useClusterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useClusterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClusterByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClusterByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClusterByIdQuery,
    ClusterByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ClusterByIdQuery, ClusterByIdQueryVariables>(
    ClusterByIdDocument,
    options
  )
}
export function useClusterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClusterByIdQuery,
    ClusterByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ClusterByIdQuery, ClusterByIdQueryVariables>(
    ClusterByIdDocument,
    options
  )
}
export function useClusterByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ClusterByIdQuery,
    ClusterByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ClusterByIdQuery, ClusterByIdQueryVariables>(
    ClusterByIdDocument,
    options
  )
}
export type ClusterByIdQueryHookResult = ReturnType<typeof useClusterByIdQuery>
export type ClusterByIdLazyQueryHookResult = ReturnType<
  typeof useClusterByIdLazyQuery
>
export type ClusterByIdSuspenseQueryHookResult = ReturnType<
  typeof useClusterByIdSuspenseQuery
>
export type ClusterByIdQueryResult = Apollo.QueryResult<
  ClusterByIdQuery,
  ClusterByIdQueryVariables
>
export const BumpsForContributionDocument = gql`
  query bumpsForContribution($contributionId: ID!) {
    contributionBumpsByContribution(contributionId: $contributionId) {
      ...ContributionBumpDetail
    }
  }
  ${ContributionBumpDetailFragmentDoc}
`

/**
 * __useBumpsForContributionQuery__
 *
 * To run a query within a React component, call `useBumpsForContributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBumpsForContributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBumpsForContributionQuery({
 *   variables: {
 *      contributionId: // value for 'contributionId'
 *   },
 * });
 */
export function useBumpsForContributionQuery(
  baseOptions: Apollo.QueryHookOptions<
    BumpsForContributionQuery,
    BumpsForContributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BumpsForContributionQuery,
    BumpsForContributionQueryVariables
  >(BumpsForContributionDocument, options)
}
export function useBumpsForContributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BumpsForContributionQuery,
    BumpsForContributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BumpsForContributionQuery,
    BumpsForContributionQueryVariables
  >(BumpsForContributionDocument, options)
}
export function useBumpsForContributionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BumpsForContributionQuery,
    BumpsForContributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BumpsForContributionQuery,
    BumpsForContributionQueryVariables
  >(BumpsForContributionDocument, options)
}
export type BumpsForContributionQueryHookResult = ReturnType<
  typeof useBumpsForContributionQuery
>
export type BumpsForContributionLazyQueryHookResult = ReturnType<
  typeof useBumpsForContributionLazyQuery
>
export type BumpsForContributionSuspenseQueryHookResult = ReturnType<
  typeof useBumpsForContributionSuspenseQuery
>
export type BumpsForContributionQueryResult = Apollo.QueryResult<
  BumpsForContributionQuery,
  BumpsForContributionQueryVariables
>
export const BumpContributionDocument = gql`
  mutation bumpContribution($input: BumpContributionInput!) {
    bumpContribution(input: $input) {
      ...ContributionBumpDetail
    }
  }
  ${ContributionBumpDetailFragmentDoc}
`
export type BumpContributionMutationFn = Apollo.MutationFunction<
  BumpContributionMutation,
  BumpContributionMutationVariables
>

/**
 * __useBumpContributionMutation__
 *
 * To run a mutation, you first call `useBumpContributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBumpContributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bumpContributionMutation, { data, loading, error }] = useBumpContributionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBumpContributionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BumpContributionMutation,
    BumpContributionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BumpContributionMutation,
    BumpContributionMutationVariables
  >(BumpContributionDocument, options)
}
export type BumpContributionMutationHookResult = ReturnType<
  typeof useBumpContributionMutation
>
export type BumpContributionMutationResult =
  Apollo.MutationResult<BumpContributionMutation>
export type BumpContributionMutationOptions = Apollo.BaseMutationOptions<
  BumpContributionMutation,
  BumpContributionMutationVariables
>
export const InboundOutboundContributionsDocument = gql`
  query inboundOutboundContributions($userId: ID!) {
    inboundOutboundContributions(userId: $userId) {
      corank
      inbound {
        ...PerTypeResponseDetail
      }
      outbound {
        ...PerTypeResponseDetail
      }
    }
  }
  ${PerTypeResponseDetailFragmentDoc}
`

/**
 * __useInboundOutboundContributionsQuery__
 *
 * To run a query within a React component, call `useInboundOutboundContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboundOutboundContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboundOutboundContributionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInboundOutboundContributionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InboundOutboundContributionsQuery,
    InboundOutboundContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    InboundOutboundContributionsQuery,
    InboundOutboundContributionsQueryVariables
  >(InboundOutboundContributionsDocument, options)
}
export function useInboundOutboundContributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InboundOutboundContributionsQuery,
    InboundOutboundContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InboundOutboundContributionsQuery,
    InboundOutboundContributionsQueryVariables
  >(InboundOutboundContributionsDocument, options)
}
export function useInboundOutboundContributionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InboundOutboundContributionsQuery,
    InboundOutboundContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    InboundOutboundContributionsQuery,
    InboundOutboundContributionsQueryVariables
  >(InboundOutboundContributionsDocument, options)
}
export type InboundOutboundContributionsQueryHookResult = ReturnType<
  typeof useInboundOutboundContributionsQuery
>
export type InboundOutboundContributionsLazyQueryHookResult = ReturnType<
  typeof useInboundOutboundContributionsLazyQuery
>
export type InboundOutboundContributionsSuspenseQueryHookResult = ReturnType<
  typeof useInboundOutboundContributionsSuspenseQuery
>
export type InboundOutboundContributionsQueryResult = Apollo.QueryResult<
  InboundOutboundContributionsQuery,
  InboundOutboundContributionsQueryVariables
>
export const ContributionsForArticleDocument = gql`
  query contributionsForArticle(
    $articleId: ID!
    $status: [ContributionStatus!]!
  ) {
    contributionsByArticle(articleId: $articleId, status: $status) {
      ...ContributionEditDetail
    }
  }
  ${ContributionEditDetailFragmentDoc}
`

/**
 * __useContributionsForArticleQuery__
 *
 * To run a query within a React component, call `useContributionsForArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useContributionsForArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContributionsForArticleQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useContributionsForArticleQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContributionsForArticleQuery,
    ContributionsForArticleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContributionsForArticleQuery,
    ContributionsForArticleQueryVariables
  >(ContributionsForArticleDocument, options)
}
export function useContributionsForArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContributionsForArticleQuery,
    ContributionsForArticleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContributionsForArticleQuery,
    ContributionsForArticleQueryVariables
  >(ContributionsForArticleDocument, options)
}
export function useContributionsForArticleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContributionsForArticleQuery,
    ContributionsForArticleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContributionsForArticleQuery,
    ContributionsForArticleQueryVariables
  >(ContributionsForArticleDocument, options)
}
export type ContributionsForArticleQueryHookResult = ReturnType<
  typeof useContributionsForArticleQuery
>
export type ContributionsForArticleLazyQueryHookResult = ReturnType<
  typeof useContributionsForArticleLazyQuery
>
export type ContributionsForArticleSuspenseQueryHookResult = ReturnType<
  typeof useContributionsForArticleSuspenseQuery
>
export type ContributionsForArticleQueryResult = Apollo.QueryResult<
  ContributionsForArticleQuery,
  ContributionsForArticleQueryVariables
>
export const ContributionsForPerspectiveDocument = gql`
  query contributionsForPerspective($articleId: ID!) {
    contributionsByPerspective(articleId: $articleId) {
      ...FullContributionDetail
    }
  }
  ${FullContributionDetailFragmentDoc}
`

/**
 * __useContributionsForPerspectiveQuery__
 *
 * To run a query within a React component, call `useContributionsForPerspectiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useContributionsForPerspectiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContributionsForPerspectiveQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useContributionsForPerspectiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContributionsForPerspectiveQuery,
    ContributionsForPerspectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContributionsForPerspectiveQuery,
    ContributionsForPerspectiveQueryVariables
  >(ContributionsForPerspectiveDocument, options)
}
export function useContributionsForPerspectiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContributionsForPerspectiveQuery,
    ContributionsForPerspectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContributionsForPerspectiveQuery,
    ContributionsForPerspectiveQueryVariables
  >(ContributionsForPerspectiveDocument, options)
}
export function useContributionsForPerspectiveSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContributionsForPerspectiveQuery,
    ContributionsForPerspectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContributionsForPerspectiveQuery,
    ContributionsForPerspectiveQueryVariables
  >(ContributionsForPerspectiveDocument, options)
}
export type ContributionsForPerspectiveQueryHookResult = ReturnType<
  typeof useContributionsForPerspectiveQuery
>
export type ContributionsForPerspectiveLazyQueryHookResult = ReturnType<
  typeof useContributionsForPerspectiveLazyQuery
>
export type ContributionsForPerspectiveSuspenseQueryHookResult = ReturnType<
  typeof useContributionsForPerspectiveSuspenseQuery
>
export type ContributionsForPerspectiveQueryResult = Apollo.QueryResult<
  ContributionsForPerspectiveQuery,
  ContributionsForPerspectiveQueryVariables
>
export const ContributionsForClusterDocument = gql`
  query contributionsForCluster($clusterId: ID!) {
    contributionsByCluster(clusterId: $clusterId) {
      ...FullContributionDetail
    }
  }
  ${FullContributionDetailFragmentDoc}
`

/**
 * __useContributionsForClusterQuery__
 *
 * To run a query within a React component, call `useContributionsForClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContributionsForClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContributionsForClusterQuery({
 *   variables: {
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useContributionsForClusterQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContributionsForClusterQuery,
    ContributionsForClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContributionsForClusterQuery,
    ContributionsForClusterQueryVariables
  >(ContributionsForClusterDocument, options)
}
export function useContributionsForClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContributionsForClusterQuery,
    ContributionsForClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContributionsForClusterQuery,
    ContributionsForClusterQueryVariables
  >(ContributionsForClusterDocument, options)
}
export function useContributionsForClusterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContributionsForClusterQuery,
    ContributionsForClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContributionsForClusterQuery,
    ContributionsForClusterQueryVariables
  >(ContributionsForClusterDocument, options)
}
export type ContributionsForClusterQueryHookResult = ReturnType<
  typeof useContributionsForClusterQuery
>
export type ContributionsForClusterLazyQueryHookResult = ReturnType<
  typeof useContributionsForClusterLazyQuery
>
export type ContributionsForClusterSuspenseQueryHookResult = ReturnType<
  typeof useContributionsForClusterSuspenseQuery
>
export type ContributionsForClusterQueryResult = Apollo.QueryResult<
  ContributionsForClusterQuery,
  ContributionsForClusterQueryVariables
>
export const ContributionsForUserDocument = gql`
  query contributionsForUser($userId: ID!, $status: [ContributionStatus!]!) {
    contributionsByUser(userId: $userId, status: $status) {
      ...FullContributionDetail
    }
  }
  ${FullContributionDetailFragmentDoc}
`

/**
 * __useContributionsForUserQuery__
 *
 * To run a query within a React component, call `useContributionsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useContributionsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContributionsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useContributionsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContributionsForUserQuery,
    ContributionsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContributionsForUserQuery,
    ContributionsForUserQueryVariables
  >(ContributionsForUserDocument, options)
}
export function useContributionsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContributionsForUserQuery,
    ContributionsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContributionsForUserQuery,
    ContributionsForUserQueryVariables
  >(ContributionsForUserDocument, options)
}
export function useContributionsForUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContributionsForUserQuery,
    ContributionsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContributionsForUserQuery,
    ContributionsForUserQueryVariables
  >(ContributionsForUserDocument, options)
}
export type ContributionsForUserQueryHookResult = ReturnType<
  typeof useContributionsForUserQuery
>
export type ContributionsForUserLazyQueryHookResult = ReturnType<
  typeof useContributionsForUserLazyQuery
>
export type ContributionsForUserSuspenseQueryHookResult = ReturnType<
  typeof useContributionsForUserSuspenseQuery
>
export type ContributionsForUserQueryResult = Apollo.QueryResult<
  ContributionsForUserQuery,
  ContributionsForUserQueryVariables
>
export const ContributionsForMyArticlesDocument = gql`
  query contributionsForMyArticles($status: [ContributionStatus!]!) {
    contributionsForMyArticles(status: $status) {
      ...ContributionDetailWithNode
    }
  }
  ${ContributionDetailWithNodeFragmentDoc}
`

/**
 * __useContributionsForMyArticlesQuery__
 *
 * To run a query within a React component, call `useContributionsForMyArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContributionsForMyArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContributionsForMyArticlesQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useContributionsForMyArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContributionsForMyArticlesQuery,
    ContributionsForMyArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContributionsForMyArticlesQuery,
    ContributionsForMyArticlesQueryVariables
  >(ContributionsForMyArticlesDocument, options)
}
export function useContributionsForMyArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContributionsForMyArticlesQuery,
    ContributionsForMyArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContributionsForMyArticlesQuery,
    ContributionsForMyArticlesQueryVariables
  >(ContributionsForMyArticlesDocument, options)
}
export function useContributionsForMyArticlesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContributionsForMyArticlesQuery,
    ContributionsForMyArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContributionsForMyArticlesQuery,
    ContributionsForMyArticlesQueryVariables
  >(ContributionsForMyArticlesDocument, options)
}
export type ContributionsForMyArticlesQueryHookResult = ReturnType<
  typeof useContributionsForMyArticlesQuery
>
export type ContributionsForMyArticlesLazyQueryHookResult = ReturnType<
  typeof useContributionsForMyArticlesLazyQuery
>
export type ContributionsForMyArticlesSuspenseQueryHookResult = ReturnType<
  typeof useContributionsForMyArticlesSuspenseQuery
>
export type ContributionsForMyArticlesQueryResult = Apollo.QueryResult<
  ContributionsForMyArticlesQuery,
  ContributionsForMyArticlesQueryVariables
>
export const UpdateContributionStatusDocument = gql`
  mutation updateContributionStatus($input: UpdateContributionInput!) {
    updateContributionStatus(input: $input) {
      ...FullContributionDetail
    }
  }
  ${FullContributionDetailFragmentDoc}
`
export type UpdateContributionStatusMutationFn = Apollo.MutationFunction<
  UpdateContributionStatusMutation,
  UpdateContributionStatusMutationVariables
>

/**
 * __useUpdateContributionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateContributionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContributionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContributionStatusMutation, { data, loading, error }] = useUpdateContributionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContributionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContributionStatusMutation,
    UpdateContributionStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContributionStatusMutation,
    UpdateContributionStatusMutationVariables
  >(UpdateContributionStatusDocument, options)
}
export type UpdateContributionStatusMutationHookResult = ReturnType<
  typeof useUpdateContributionStatusMutation
>
export type UpdateContributionStatusMutationResult =
  Apollo.MutationResult<UpdateContributionStatusMutation>
export type UpdateContributionStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateContributionStatusMutation,
    UpdateContributionStatusMutationVariables
  >
export const ReserveContributionDocument = gql`
  mutation reserveContribution($input: ReserveContributionInput!) {
    reserveContribution(input: $input) {
      ...ContributionEditDetail
    }
  }
  ${ContributionEditDetailFragmentDoc}
`
export type ReserveContributionMutationFn = Apollo.MutationFunction<
  ReserveContributionMutation,
  ReserveContributionMutationVariables
>

/**
 * __useReserveContributionMutation__
 *
 * To run a mutation, you first call `useReserveContributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveContributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveContributionMutation, { data, loading, error }] = useReserveContributionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReserveContributionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReserveContributionMutation,
    ReserveContributionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReserveContributionMutation,
    ReserveContributionMutationVariables
  >(ReserveContributionDocument, options)
}
export type ReserveContributionMutationHookResult = ReturnType<
  typeof useReserveContributionMutation
>
export type ReserveContributionMutationResult =
  Apollo.MutationResult<ReserveContributionMutation>
export type ReserveContributionMutationOptions = Apollo.BaseMutationOptions<
  ReserveContributionMutation,
  ReserveContributionMutationVariables
>
export const SubmitContributionDocument = gql`
  mutation submitContribution($input: SubmitContributionInput!) {
    submitContribution(input: $input) {
      ...ContributionEditDetail
    }
  }
  ${ContributionEditDetailFragmentDoc}
`
export type SubmitContributionMutationFn = Apollo.MutationFunction<
  SubmitContributionMutation,
  SubmitContributionMutationVariables
>

/**
 * __useSubmitContributionMutation__
 *
 * To run a mutation, you first call `useSubmitContributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitContributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitContributionMutation, { data, loading, error }] = useSubmitContributionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitContributionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitContributionMutation,
    SubmitContributionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitContributionMutation,
    SubmitContributionMutationVariables
  >(SubmitContributionDocument, options)
}
export type SubmitContributionMutationHookResult = ReturnType<
  typeof useSubmitContributionMutation
>
export type SubmitContributionMutationResult =
  Apollo.MutationResult<SubmitContributionMutation>
export type SubmitContributionMutationOptions = Apollo.BaseMutationOptions<
  SubmitContributionMutation,
  SubmitContributionMutationVariables
>
export const ReservedContributionDocument = gql`
  query reservedContribution($articleId: ID!) {
    reservedContribution(articleId: $articleId) {
      ...ContributionEditDetail
    }
  }
  ${ContributionEditDetailFragmentDoc}
`

/**
 * __useReservedContributionQuery__
 *
 * To run a query within a React component, call `useReservedContributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservedContributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservedContributionQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useReservedContributionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservedContributionQuery,
    ReservedContributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReservedContributionQuery,
    ReservedContributionQueryVariables
  >(ReservedContributionDocument, options)
}
export function useReservedContributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservedContributionQuery,
    ReservedContributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReservedContributionQuery,
    ReservedContributionQueryVariables
  >(ReservedContributionDocument, options)
}
export function useReservedContributionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReservedContributionQuery,
    ReservedContributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ReservedContributionQuery,
    ReservedContributionQueryVariables
  >(ReservedContributionDocument, options)
}
export type ReservedContributionQueryHookResult = ReturnType<
  typeof useReservedContributionQuery
>
export type ReservedContributionLazyQueryHookResult = ReturnType<
  typeof useReservedContributionLazyQuery
>
export type ReservedContributionSuspenseQueryHookResult = ReturnType<
  typeof useReservedContributionSuspenseQuery
>
export type ReservedContributionQueryResult = Apollo.QueryResult<
  ReservedContributionQuery,
  ReservedContributionQueryVariables
>
export const ContributionByIdDocument = gql`
  query contributionById($contributionId: ID!) {
    contributionById(contributionId: $contributionId) {
      ...ContributionDetail
      bumpCount
      article {
        ...ArticleDetail
      }
    }
  }
  ${ContributionDetailFragmentDoc}
  ${ArticleDetailFragmentDoc}
`

/**
 * __useContributionByIdQuery__
 *
 * To run a query within a React component, call `useContributionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContributionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContributionByIdQuery({
 *   variables: {
 *      contributionId: // value for 'contributionId'
 *   },
 * });
 */
export function useContributionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContributionByIdQuery,
    ContributionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContributionByIdQuery, ContributionByIdQueryVariables>(
    ContributionByIdDocument,
    options
  )
}
export function useContributionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContributionByIdQuery,
    ContributionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContributionByIdQuery,
    ContributionByIdQueryVariables
  >(ContributionByIdDocument, options)
}
export function useContributionByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContributionByIdQuery,
    ContributionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContributionByIdQuery,
    ContributionByIdQueryVariables
  >(ContributionByIdDocument, options)
}
export type ContributionByIdQueryHookResult = ReturnType<
  typeof useContributionByIdQuery
>
export type ContributionByIdLazyQueryHookResult = ReturnType<
  typeof useContributionByIdLazyQuery
>
export type ContributionByIdSuspenseQueryHookResult = ReturnType<
  typeof useContributionByIdSuspenseQuery
>
export type ContributionByIdQueryResult = Apollo.QueryResult<
  ContributionByIdQuery,
  ContributionByIdQueryVariables
>
export const SubscribeWebPushDocument = gql`
  mutation subscribeWebPush($input: SubscribeWebPushInput!) {
    subscribeWebPush(input: $input) {
      ...UserDetail
    }
  }
  ${UserDetailFragmentDoc}
`
export type SubscribeWebPushMutationFn = Apollo.MutationFunction<
  SubscribeWebPushMutation,
  SubscribeWebPushMutationVariables
>

/**
 * __useSubscribeWebPushMutation__
 *
 * To run a mutation, you first call `useSubscribeWebPushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeWebPushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeWebPushMutation, { data, loading, error }] = useSubscribeWebPushMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeWebPushMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeWebPushMutation,
    SubscribeWebPushMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubscribeWebPushMutation,
    SubscribeWebPushMutationVariables
  >(SubscribeWebPushDocument, options)
}
export type SubscribeWebPushMutationHookResult = ReturnType<
  typeof useSubscribeWebPushMutation
>
export type SubscribeWebPushMutationResult =
  Apollo.MutationResult<SubscribeWebPushMutation>
export type SubscribeWebPushMutationOptions = Apollo.BaseMutationOptions<
  SubscribeWebPushMutation,
  SubscribeWebPushMutationVariables
>
export const UnsubscribeWebPushDocument = gql`
  mutation unsubscribeWebPush {
    unsubscribeWebPush {
      ...UserDetail
    }
  }
  ${UserDetailFragmentDoc}
`
export type UnsubscribeWebPushMutationFn = Apollo.MutationFunction<
  UnsubscribeWebPushMutation,
  UnsubscribeWebPushMutationVariables
>

/**
 * __useUnsubscribeWebPushMutation__
 *
 * To run a mutation, you first call `useUnsubscribeWebPushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeWebPushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeWebPushMutation, { data, loading, error }] = useUnsubscribeWebPushMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnsubscribeWebPushMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeWebPushMutation,
    UnsubscribeWebPushMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnsubscribeWebPushMutation,
    UnsubscribeWebPushMutationVariables
  >(UnsubscribeWebPushDocument, options)
}
export type UnsubscribeWebPushMutationHookResult = ReturnType<
  typeof useUnsubscribeWebPushMutation
>
export type UnsubscribeWebPushMutationResult =
  Apollo.MutationResult<UnsubscribeWebPushMutation>
export type UnsubscribeWebPushMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeWebPushMutation,
  UnsubscribeWebPushMutationVariables
>
export const CheckInvitationDocument = gql`
  query checkInvitation($id: ID!) {
    validInvitation(id: $id) {
      ... on Invitation {
        id
        message
        node {
          id
          name
          description
        }
      }
      ... on InvitationError {
        errorMessage
        code
      }
    }
  }
`

/**
 * __useCheckInvitationQuery__
 *
 * To run a query within a React component, call `useCheckInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInvitationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckInvitationQuery,
    CheckInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckInvitationQuery, CheckInvitationQueryVariables>(
    CheckInvitationDocument,
    options
  )
}
export function useCheckInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckInvitationQuery,
    CheckInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CheckInvitationQuery,
    CheckInvitationQueryVariables
  >(CheckInvitationDocument, options)
}
export function useCheckInvitationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CheckInvitationQuery,
    CheckInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CheckInvitationQuery,
    CheckInvitationQueryVariables
  >(CheckInvitationDocument, options)
}
export type CheckInvitationQueryHookResult = ReturnType<
  typeof useCheckInvitationQuery
>
export type CheckInvitationLazyQueryHookResult = ReturnType<
  typeof useCheckInvitationLazyQuery
>
export type CheckInvitationSuspenseQueryHookResult = ReturnType<
  typeof useCheckInvitationSuspenseQuery
>
export type CheckInvitationQueryResult = Apollo.QueryResult<
  CheckInvitationQuery,
  CheckInvitationQueryVariables
>
export const AcceptInvitationDocument = gql`
  mutation acceptInvitation($id: ID!) {
    acceptInvitation(id: $id) {
      ... on Invitation {
        nodeId
      }
      ... on InvitationError {
        errorMessage
        code
      }
    }
  }
`
export type AcceptInvitationMutationFn = Apollo.MutationFunction<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >(AcceptInvitationDocument, options)
}
export type AcceptInvitationMutationHookResult = ReturnType<
  typeof useAcceptInvitationMutation
>
export type AcceptInvitationMutationResult =
  Apollo.MutationResult<AcceptInvitationMutation>
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>
export const GenerateOpinionDocument = gql`
  mutation GenerateOpinion(
    $prompt: String!
    $model: String!
    $clusterId: String!
  ) {
    generateOpinion(prompt: $prompt, model: $model, clusterId: $clusterId)
  }
`
export type GenerateOpinionMutationFn = Apollo.MutationFunction<
  GenerateOpinionMutation,
  GenerateOpinionMutationVariables
>

/**
 * __useGenerateOpinionMutation__
 *
 * To run a mutation, you first call `useGenerateOpinionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOpinionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOpinionMutation, { data, loading, error }] = useGenerateOpinionMutation({
 *   variables: {
 *      prompt: // value for 'prompt'
 *      model: // value for 'model'
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useGenerateOpinionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateOpinionMutation,
    GenerateOpinionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GenerateOpinionMutation,
    GenerateOpinionMutationVariables
  >(GenerateOpinionDocument, options)
}
export type GenerateOpinionMutationHookResult = ReturnType<
  typeof useGenerateOpinionMutation
>
export type GenerateOpinionMutationResult =
  Apollo.MutationResult<GenerateOpinionMutation>
export type GenerateOpinionMutationOptions = Apollo.BaseMutationOptions<
  GenerateOpinionMutation,
  GenerateOpinionMutationVariables
>
export const CreateNodeMemberDocument = gql`
  mutation createNodeMember($input: CreateNodeMemberInput!) {
    createNodeMember(input: $input) {
      ...NodeMemberDetail
    }
  }
  ${NodeMemberDetailFragmentDoc}
`
export type CreateNodeMemberMutationFn = Apollo.MutationFunction<
  CreateNodeMemberMutation,
  CreateNodeMemberMutationVariables
>

/**
 * __useCreateNodeMemberMutation__
 *
 * To run a mutation, you first call `useCreateNodeMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNodeMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNodeMemberMutation, { data, loading, error }] = useCreateNodeMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNodeMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNodeMemberMutation,
    CreateNodeMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateNodeMemberMutation,
    CreateNodeMemberMutationVariables
  >(CreateNodeMemberDocument, options)
}
export type CreateNodeMemberMutationHookResult = ReturnType<
  typeof useCreateNodeMemberMutation
>
export type CreateNodeMemberMutationResult =
  Apollo.MutationResult<CreateNodeMemberMutation>
export type CreateNodeMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateNodeMemberMutation,
  CreateNodeMemberMutationVariables
>
export const RemoveNodeMemberDocument = gql`
  mutation removeNodeMember($id: ID!) {
    removeNodeMember(id: $id)
  }
`
export type RemoveNodeMemberMutationFn = Apollo.MutationFunction<
  RemoveNodeMemberMutation,
  RemoveNodeMemberMutationVariables
>

/**
 * __useRemoveNodeMemberMutation__
 *
 * To run a mutation, you first call `useRemoveNodeMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNodeMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNodeMemberMutation, { data, loading, error }] = useRemoveNodeMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveNodeMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveNodeMemberMutation,
    RemoveNodeMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveNodeMemberMutation,
    RemoveNodeMemberMutationVariables
  >(RemoveNodeMemberDocument, options)
}
export type RemoveNodeMemberMutationHookResult = ReturnType<
  typeof useRemoveNodeMemberMutation
>
export type RemoveNodeMemberMutationResult =
  Apollo.MutationResult<RemoveNodeMemberMutation>
export type RemoveNodeMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveNodeMemberMutation,
  RemoveNodeMemberMutationVariables
>
export const NodeMembersByNodeIdDocument = gql`
  query nodeMembersByNodeId($nodeId: ID!) {
    nodeMembersByNodeId(nodeId: $nodeId) {
      ...NodeMemberDetail
    }
  }
  ${NodeMemberDetailFragmentDoc}
`

/**
 * __useNodeMembersByNodeIdQuery__
 *
 * To run a query within a React component, call `useNodeMembersByNodeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeMembersByNodeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeMembersByNodeIdQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useNodeMembersByNodeIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    NodeMembersByNodeIdQuery,
    NodeMembersByNodeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    NodeMembersByNodeIdQuery,
    NodeMembersByNodeIdQueryVariables
  >(NodeMembersByNodeIdDocument, options)
}
export function useNodeMembersByNodeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NodeMembersByNodeIdQuery,
    NodeMembersByNodeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    NodeMembersByNodeIdQuery,
    NodeMembersByNodeIdQueryVariables
  >(NodeMembersByNodeIdDocument, options)
}
export function useNodeMembersByNodeIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NodeMembersByNodeIdQuery,
    NodeMembersByNodeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    NodeMembersByNodeIdQuery,
    NodeMembersByNodeIdQueryVariables
  >(NodeMembersByNodeIdDocument, options)
}
export type NodeMembersByNodeIdQueryHookResult = ReturnType<
  typeof useNodeMembersByNodeIdQuery
>
export type NodeMembersByNodeIdLazyQueryHookResult = ReturnType<
  typeof useNodeMembersByNodeIdLazyQuery
>
export type NodeMembersByNodeIdSuspenseQueryHookResult = ReturnType<
  typeof useNodeMembersByNodeIdSuspenseQuery
>
export type NodeMembersByNodeIdQueryResult = Apollo.QueryResult<
  NodeMembersByNodeIdQuery,
  NodeMembersByNodeIdQueryVariables
>
export const AvailableNodesDocument = gql`
  query availableNodes {
    availableNodes {
      id
      name
      isPrivate
      description
    }
  }
`

/**
 * __useAvailableNodesQuery__
 *
 * To run a query within a React component, call `useAvailableNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableNodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableNodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableNodesQuery,
    AvailableNodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AvailableNodesQuery, AvailableNodesQueryVariables>(
    AvailableNodesDocument,
    options
  )
}
export function useAvailableNodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableNodesQuery,
    AvailableNodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AvailableNodesQuery, AvailableNodesQueryVariables>(
    AvailableNodesDocument,
    options
  )
}
export function useAvailableNodesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AvailableNodesQuery,
    AvailableNodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AvailableNodesQuery,
    AvailableNodesQueryVariables
  >(AvailableNodesDocument, options)
}
export type AvailableNodesQueryHookResult = ReturnType<
  typeof useAvailableNodesQuery
>
export type AvailableNodesLazyQueryHookResult = ReturnType<
  typeof useAvailableNodesLazyQuery
>
export type AvailableNodesSuspenseQueryHookResult = ReturnType<
  typeof useAvailableNodesSuspenseQuery
>
export type AvailableNodesQueryResult = Apollo.QueryResult<
  AvailableNodesQuery,
  AvailableNodesQueryVariables
>
export const NodeStatsDocument = gql`
  query nodeStats($input: NodeStatsInput!) {
    nodeStats(input: $input) {
      nodeId
      node {
        id
        name
        description
        isPrivate
        isAdmin
      }
      perspectives {
        id
        title
        voteCount
        cluster {
          id
          topic
        }
      }
      contributions {
        ...FullContributionDetail
        bumpScore
      }
      users {
        id
        username
      }
    }
  }
  ${FullContributionDetailFragmentDoc}
`

/**
 * __useNodeStatsQuery__
 *
 * To run a query within a React component, call `useNodeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNodeStatsQuery(
  baseOptions: Apollo.QueryHookOptions<NodeStatsQuery, NodeStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NodeStatsQuery, NodeStatsQueryVariables>(
    NodeStatsDocument,
    options
  )
}
export function useNodeStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NodeStatsQuery,
    NodeStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NodeStatsQuery, NodeStatsQueryVariables>(
    NodeStatsDocument,
    options
  )
}
export function useNodeStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NodeStatsQuery,
    NodeStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NodeStatsQuery, NodeStatsQueryVariables>(
    NodeStatsDocument,
    options
  )
}
export type NodeStatsQueryHookResult = ReturnType<typeof useNodeStatsQuery>
export type NodeStatsLazyQueryHookResult = ReturnType<
  typeof useNodeStatsLazyQuery
>
export type NodeStatsSuspenseQueryHookResult = ReturnType<
  typeof useNodeStatsSuspenseQuery
>
export type NodeStatsQueryResult = Apollo.QueryResult<
  NodeStatsQuery,
  NodeStatsQueryVariables
>
export const NodeByIdDocument = gql`
  query nodeById($id: ID!) {
    nodeById(id: $id) {
      id
      name
      description
      isPrivate
      isAdmin
    }
  }
`

/**
 * __useNodeByIdQuery__
 *
 * To run a query within a React component, call `useNodeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<NodeByIdQuery, NodeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NodeByIdQuery, NodeByIdQueryVariables>(
    NodeByIdDocument,
    options
  )
}
export function useNodeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NodeByIdQuery,
    NodeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NodeByIdQuery, NodeByIdQueryVariables>(
    NodeByIdDocument,
    options
  )
}
export function useNodeByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NodeByIdQuery,
    NodeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NodeByIdQuery, NodeByIdQueryVariables>(
    NodeByIdDocument,
    options
  )
}
export type NodeByIdQueryHookResult = ReturnType<typeof useNodeByIdQuery>
export type NodeByIdLazyQueryHookResult = ReturnType<
  typeof useNodeByIdLazyQuery
>
export type NodeByIdSuspenseQueryHookResult = ReturnType<
  typeof useNodeByIdSuspenseQuery
>
export type NodeByIdQueryResult = Apollo.QueryResult<
  NodeByIdQuery,
  NodeByIdQueryVariables
>
export const CreateTaskDocument = gql`
  mutation createTask($input: CreateMelddTaskInput!) {
    createTask(input: $input) {
      id
      title
      type
      targetId
      targetType
    }
  }
`
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options
  )
}
export type CreateTaskMutationHookResult = ReturnType<
  typeof useCreateTaskMutation
>
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>
export const AssignTaskDocument = gql`
  mutation assignTask($input: AssignTaskInput!) {
    assignTask(input: $input)
  }
`
export type AssignTaskMutationFn = Apollo.MutationFunction<
  AssignTaskMutation,
  AssignTaskMutationVariables
>

/**
 * __useAssignTaskMutation__
 *
 * To run a mutation, you first call `useAssignTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskMutation, { data, loading, error }] = useAssignTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignTaskMutation,
    AssignTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AssignTaskMutation, AssignTaskMutationVariables>(
    AssignTaskDocument,
    options
  )
}
export type AssignTaskMutationHookResult = ReturnType<
  typeof useAssignTaskMutation
>
export type AssignTaskMutationResult = Apollo.MutationResult<AssignTaskMutation>
export type AssignTaskMutationOptions = Apollo.BaseMutationOptions<
  AssignTaskMutation,
  AssignTaskMutationVariables
>
export const ArchiveTaskDocument = gql`
  mutation archiveTask($input: TaskIdInput!) {
    archiveTask(input: $input)
  }
`
export type ArchiveTaskMutationFn = Apollo.MutationFunction<
  ArchiveTaskMutation,
  ArchiveTaskMutationVariables
>

/**
 * __useArchiveTaskMutation__
 *
 * To run a mutation, you first call `useArchiveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTaskMutation, { data, loading, error }] = useArchiveTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveTaskMutation,
    ArchiveTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ArchiveTaskMutation, ArchiveTaskMutationVariables>(
    ArchiveTaskDocument,
    options
  )
}
export type ArchiveTaskMutationHookResult = ReturnType<
  typeof useArchiveTaskMutation
>
export type ArchiveTaskMutationResult =
  Apollo.MutationResult<ArchiveTaskMutation>
export type ArchiveTaskMutationOptions = Apollo.BaseMutationOptions<
  ArchiveTaskMutation,
  ArchiveTaskMutationVariables
>
export const GetTaskDocument = gql`
  query getTask($taskId: String!) {
    getTask(taskId: $taskId) {
      ...TaskDetail
    }
  }
  ${TaskDetailFragmentDoc}
`

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(
    GetTaskDocument,
    options
  )
}
export function useGetTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(
    GetTaskDocument,
    options
  )
}
export function useGetTaskSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTaskQuery,
    GetTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTaskQuery, GetTaskQueryVariables>(
    GetTaskDocument,
    options
  )
}
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>
export type GetTaskSuspenseQueryHookResult = ReturnType<
  typeof useGetTaskSuspenseQuery
>
export type GetTaskQueryResult = Apollo.QueryResult<
  GetTaskQuery,
  GetTaskQueryVariables
>
export const GetTaskContributionsDocument = gql`
  query getTaskContributions(
    $input: GetContributionsInput!
    $offset: Float!
    $size: Float!
  ) {
    getTaskContributions(input: $input, offset: $offset, size: $size) {
      results {
        ...TaskContributor
      }
      count
    }
  }
  ${TaskContributorFragmentDoc}
`

/**
 * __useGetTaskContributionsQuery__
 *
 * To run a query within a React component, call `useGetTaskContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskContributionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetTaskContributionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskContributionsQuery,
    GetTaskContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTaskContributionsQuery,
    GetTaskContributionsQueryVariables
  >(GetTaskContributionsDocument, options)
}
export function useGetTaskContributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskContributionsQuery,
    GetTaskContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTaskContributionsQuery,
    GetTaskContributionsQueryVariables
  >(GetTaskContributionsDocument, options)
}
export function useGetTaskContributionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTaskContributionsQuery,
    GetTaskContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTaskContributionsQuery,
    GetTaskContributionsQueryVariables
  >(GetTaskContributionsDocument, options)
}
export type GetTaskContributionsQueryHookResult = ReturnType<
  typeof useGetTaskContributionsQuery
>
export type GetTaskContributionsLazyQueryHookResult = ReturnType<
  typeof useGetTaskContributionsLazyQuery
>
export type GetTaskContributionsSuspenseQueryHookResult = ReturnType<
  typeof useGetTaskContributionsSuspenseQuery
>
export type GetTaskContributionsQueryResult = Apollo.QueryResult<
  GetTaskContributionsQuery,
  GetTaskContributionsQueryVariables
>
export const GetTasksDocument = gql`
  query getTasks($input: GetTasksInput!, $offset: Float!, $size: Float!) {
    getTasks(input: $input, offset: $offset, size: $size) {
      results {
        ...TaskDetail
      }
      count
    }
  }
  ${TaskDetailFragmentDoc}
`

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetTasksQuery(
  baseOptions: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(
    GetTasksDocument,
    options
  )
}
export function useGetTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTasksQuery,
    GetTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(
    GetTasksDocument,
    options
  )
}
export function useGetTasksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTasksQuery,
    GetTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetTasksQuery, GetTasksQueryVariables>(
    GetTasksDocument,
    options
  )
}
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>
export type GetTasksLazyQueryHookResult = ReturnType<
  typeof useGetTasksLazyQuery
>
export type GetTasksSuspenseQueryHookResult = ReturnType<
  typeof useGetTasksSuspenseQuery
>
export type GetTasksQueryResult = Apollo.QueryResult<
  GetTasksQuery,
  GetTasksQueryVariables
>
export const GetTargetContributionsDocument = gql`
  query getTargetContributions(
    $input: GetContributionsByTargetInput!
    $offset: Float!
    $size: Float!
  ) {
    getTargetContributions(input: $input, offset: $offset, size: $size) {
      results {
        ...TaskContributor
      }
      count
    }
  }
  ${TaskContributorFragmentDoc}
`

/**
 * __useGetTargetContributionsQuery__
 *
 * To run a query within a React component, call `useGetTargetContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetContributionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetTargetContributionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTargetContributionsQuery,
    GetTargetContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTargetContributionsQuery,
    GetTargetContributionsQueryVariables
  >(GetTargetContributionsDocument, options)
}
export function useGetTargetContributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTargetContributionsQuery,
    GetTargetContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTargetContributionsQuery,
    GetTargetContributionsQueryVariables
  >(GetTargetContributionsDocument, options)
}
export function useGetTargetContributionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTargetContributionsQuery,
    GetTargetContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTargetContributionsQuery,
    GetTargetContributionsQueryVariables
  >(GetTargetContributionsDocument, options)
}
export type GetTargetContributionsQueryHookResult = ReturnType<
  typeof useGetTargetContributionsQuery
>
export type GetTargetContributionsLazyQueryHookResult = ReturnType<
  typeof useGetTargetContributionsLazyQuery
>
export type GetTargetContributionsSuspenseQueryHookResult = ReturnType<
  typeof useGetTargetContributionsSuspenseQuery
>
export type GetTargetContributionsQueryResult = Apollo.QueryResult<
  GetTargetContributionsQuery,
  GetTargetContributionsQueryVariables
>
export const GetMyContributionByTaskIdDocument = gql`
  query getMyContributionByTaskId($taskId: String!) {
    getMyContributionByTaskId(taskId: $taskId) {
      ...TaskContributor
    }
  }
  ${TaskContributorFragmentDoc}
`

/**
 * __useGetMyContributionByTaskIdQuery__
 *
 * To run a query within a React component, call `useGetMyContributionByTaskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyContributionByTaskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyContributionByTaskIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetMyContributionByTaskIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyContributionByTaskIdQuery,
    GetMyContributionByTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMyContributionByTaskIdQuery,
    GetMyContributionByTaskIdQueryVariables
  >(GetMyContributionByTaskIdDocument, options)
}
export function useGetMyContributionByTaskIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyContributionByTaskIdQuery,
    GetMyContributionByTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMyContributionByTaskIdQuery,
    GetMyContributionByTaskIdQueryVariables
  >(GetMyContributionByTaskIdDocument, options)
}
export function useGetMyContributionByTaskIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyContributionByTaskIdQuery,
    GetMyContributionByTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetMyContributionByTaskIdQuery,
    GetMyContributionByTaskIdQueryVariables
  >(GetMyContributionByTaskIdDocument, options)
}
export type GetMyContributionByTaskIdQueryHookResult = ReturnType<
  typeof useGetMyContributionByTaskIdQuery
>
export type GetMyContributionByTaskIdLazyQueryHookResult = ReturnType<
  typeof useGetMyContributionByTaskIdLazyQuery
>
export type GetMyContributionByTaskIdSuspenseQueryHookResult = ReturnType<
  typeof useGetMyContributionByTaskIdSuspenseQuery
>
export type GetMyContributionByTaskIdQueryResult = Apollo.QueryResult<
  GetMyContributionByTaskIdQuery,
  GetMyContributionByTaskIdQueryVariables
>
export const GetMyContributionsDocument = gql`
  query getMyContributions(
    $input: GetContributionsFilter!
    $offset: Float!
    $size: Float!
  ) {
    getMyContributions(input: $input, offset: $offset, size: $size) {
      results {
        ...TaskContributor
      }
      count
    }
  }
  ${TaskContributorFragmentDoc}
`

/**
 * __useGetMyContributionsQuery__
 *
 * To run a query within a React component, call `useGetMyContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyContributionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetMyContributionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyContributionsQuery,
    GetMyContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMyContributionsQuery,
    GetMyContributionsQueryVariables
  >(GetMyContributionsDocument, options)
}
export function useGetMyContributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyContributionsQuery,
    GetMyContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMyContributionsQuery,
    GetMyContributionsQueryVariables
  >(GetMyContributionsDocument, options)
}
export function useGetMyContributionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyContributionsQuery,
    GetMyContributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetMyContributionsQuery,
    GetMyContributionsQueryVariables
  >(GetMyContributionsDocument, options)
}
export type GetMyContributionsQueryHookResult = ReturnType<
  typeof useGetMyContributionsQuery
>
export type GetMyContributionsLazyQueryHookResult = ReturnType<
  typeof useGetMyContributionsLazyQuery
>
export type GetMyContributionsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyContributionsSuspenseQuery
>
export type GetMyContributionsQueryResult = Apollo.QueryResult<
  GetMyContributionsQuery,
  GetMyContributionsQueryVariables
>
export const ContributeOnTaskDocument = gql`
  mutation contributeOnTask($input: TaskIdInput!) {
    contributeOnTask(input: $input) {
      ...TaskContributor
    }
  }
  ${TaskContributorFragmentDoc}
`
export type ContributeOnTaskMutationFn = Apollo.MutationFunction<
  ContributeOnTaskMutation,
  ContributeOnTaskMutationVariables
>

/**
 * __useContributeOnTaskMutation__
 *
 * To run a mutation, you first call `useContributeOnTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContributeOnTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contributeOnTaskMutation, { data, loading, error }] = useContributeOnTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContributeOnTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContributeOnTaskMutation,
    ContributeOnTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ContributeOnTaskMutation,
    ContributeOnTaskMutationVariables
  >(ContributeOnTaskDocument, options)
}
export type ContributeOnTaskMutationHookResult = ReturnType<
  typeof useContributeOnTaskMutation
>
export type ContributeOnTaskMutationResult =
  Apollo.MutationResult<ContributeOnTaskMutation>
export type ContributeOnTaskMutationOptions = Apollo.BaseMutationOptions<
  ContributeOnTaskMutation,
  ContributeOnTaskMutationVariables
>
export const DeleteTaskContributionDocument = gql`
  mutation deleteTaskContribution($taskContributionId: String!) {
    deleteTaskContribution(taskContributionId: $taskContributionId)
  }
`
export type DeleteTaskContributionMutationFn = Apollo.MutationFunction<
  DeleteTaskContributionMutation,
  DeleteTaskContributionMutationVariables
>

/**
 * __useDeleteTaskContributionMutation__
 *
 * To run a mutation, you first call `useDeleteTaskContributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskContributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskContributionMutation, { data, loading, error }] = useDeleteTaskContributionMutation({
 *   variables: {
 *      taskContributionId: // value for 'taskContributionId'
 *   },
 * });
 */
export function useDeleteTaskContributionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskContributionMutation,
    DeleteTaskContributionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteTaskContributionMutation,
    DeleteTaskContributionMutationVariables
  >(DeleteTaskContributionDocument, options)
}
export type DeleteTaskContributionMutationHookResult = ReturnType<
  typeof useDeleteTaskContributionMutation
>
export type DeleteTaskContributionMutationResult =
  Apollo.MutationResult<DeleteTaskContributionMutation>
export type DeleteTaskContributionMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskContributionMutation,
  DeleteTaskContributionMutationVariables
>
export const PendingTasksDocument = gql`
  query pendingTasks($targetId: String) {
    pendingTasks(targetId: $targetId) {
      pendingTasks
      pendingContributions
    }
  }
`

/**
 * __usePendingTasksQuery__
 *
 * To run a query within a React component, call `usePendingTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingTasksQuery({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function usePendingTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PendingTasksQuery,
    PendingTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PendingTasksQuery, PendingTasksQueryVariables>(
    PendingTasksDocument,
    options
  )
}
export function usePendingTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingTasksQuery,
    PendingTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PendingTasksQuery, PendingTasksQueryVariables>(
    PendingTasksDocument,
    options
  )
}
export function usePendingTasksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PendingTasksQuery,
    PendingTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PendingTasksQuery, PendingTasksQueryVariables>(
    PendingTasksDocument,
    options
  )
}
export type PendingTasksQueryHookResult = ReturnType<
  typeof usePendingTasksQuery
>
export type PendingTasksLazyQueryHookResult = ReturnType<
  typeof usePendingTasksLazyQuery
>
export type PendingTasksSuspenseQueryHookResult = ReturnType<
  typeof usePendingTasksSuspenseQuery
>
export type PendingTasksQueryResult = Apollo.QueryResult<
  PendingTasksQuery,
  PendingTasksQueryVariables
>
export const UserDocument = gql`
  query user($userId: ID!) {
    user(id: $userId) {
      ...UserWithBioDetail
    }
  }
  ${UserWithBioDetailFragmentDoc}
`

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options)
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  )
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  )
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>
export type UserSuspenseQueryHookResult = ReturnType<
  typeof useUserSuspenseQuery
>
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>
export const SearchUserDocument = gql`
  query searchUser($username: String!) {
    usersBySearchUsername(username: $username) {
      id
      username
    }
  }
`

/**
 * __useSearchUserQuery__
 *
 * To run a query within a React component, call `useSearchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useSearchUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUserQuery,
    SearchUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchUserQuery, SearchUserQueryVariables>(
    SearchUserDocument,
    options
  )
}
export function useSearchUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUserQuery,
    SearchUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchUserQuery, SearchUserQueryVariables>(
    SearchUserDocument,
    options
  )
}
export function useSearchUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchUserQuery,
    SearchUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchUserQuery, SearchUserQueryVariables>(
    SearchUserDocument,
    options
  )
}
export type SearchUserQueryHookResult = ReturnType<typeof useSearchUserQuery>
export type SearchUserLazyQueryHookResult = ReturnType<
  typeof useSearchUserLazyQuery
>
export type SearchUserSuspenseQueryHookResult = ReturnType<
  typeof useSearchUserSuspenseQuery
>
export type SearchUserQueryResult = Apollo.QueryResult<
  SearchUserQuery,
  SearchUserQueryVariables
>
export const SignUpDocument = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      accessToken
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  )
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>
export const LoginDocument = gql`
  mutation login($input: LoginUserInput!) {
    login(loginUserInput: $input) {
      accessToken
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
export const LoginOtpDocument = gql`
  mutation loginOTP($input: LoginUserOtpInput!) {
    loginOTP(input: $input) {
      accessToken
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type LoginOtpMutationFn = Apollo.MutationFunction<
  LoginOtpMutation,
  LoginOtpMutationVariables
>

/**
 * __useLoginOtpMutation__
 *
 * To run a mutation, you first call `useLoginOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginOtpMutation, { data, loading, error }] = useLoginOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginOtpMutation,
    LoginOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginOtpMutation, LoginOtpMutationVariables>(
    LoginOtpDocument,
    options
  )
}
export type LoginOtpMutationHookResult = ReturnType<typeof useLoginOtpMutation>
export type LoginOtpMutationResult = Apollo.MutationResult<LoginOtpMutation>
export type LoginOtpMutationOptions = Apollo.BaseMutationOptions<
  LoginOtpMutation,
  LoginOtpMutationVariables
>
export const LoginMobileOtpDocument = gql`
  mutation loginMobileOTP($input: LoginUserMobileOtpInput!) {
    loginMobileOTP(input: $input) {
      accessToken
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type LoginMobileOtpMutationFn = Apollo.MutationFunction<
  LoginMobileOtpMutation,
  LoginMobileOtpMutationVariables
>

/**
 * __useLoginMobileOtpMutation__
 *
 * To run a mutation, you first call `useLoginMobileOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMobileOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMobileOtpMutation, { data, loading, error }] = useLoginMobileOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMobileOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMobileOtpMutation,
    LoginMobileOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LoginMobileOtpMutation,
    LoginMobileOtpMutationVariables
  >(LoginMobileOtpDocument, options)
}
export type LoginMobileOtpMutationHookResult = ReturnType<
  typeof useLoginMobileOtpMutation
>
export type LoginMobileOtpMutationResult =
  Apollo.MutationResult<LoginMobileOtpMutation>
export type LoginMobileOtpMutationOptions = Apollo.BaseMutationOptions<
  LoginMobileOtpMutation,
  LoginMobileOtpMutationVariables
>
export const RequestMobileVerificationCodeDocument = gql`
  mutation requestMobileVerificationCode(
    $input: RequestMobileVerificationInput!
  ) {
    requestMobileVerificationCode(input: $input) {
      isSent
    }
  }
`
export type RequestMobileVerificationCodeMutationFn = Apollo.MutationFunction<
  RequestMobileVerificationCodeMutation,
  RequestMobileVerificationCodeMutationVariables
>

/**
 * __useRequestMobileVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestMobileVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMobileVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMobileVerificationCodeMutation, { data, loading, error }] = useRequestMobileVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMobileVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMobileVerificationCodeMutation,
    RequestMobileVerificationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestMobileVerificationCodeMutation,
    RequestMobileVerificationCodeMutationVariables
  >(RequestMobileVerificationCodeDocument, options)
}
export type RequestMobileVerificationCodeMutationHookResult = ReturnType<
  typeof useRequestMobileVerificationCodeMutation
>
export type RequestMobileVerificationCodeMutationResult =
  Apollo.MutationResult<RequestMobileVerificationCodeMutation>
export type RequestMobileVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    RequestMobileVerificationCodeMutation,
    RequestMobileVerificationCodeMutationVariables
  >
export const RequestOtpDocument = gql`
  mutation requestOTP($input: RequestOtpInput!) {
    requestOTP(input: $input) {
      isSent
    }
  }
`
export type RequestOtpMutationFn = Apollo.MutationFunction<
  RequestOtpMutation,
  RequestOtpMutationVariables
>

/**
 * __useRequestOtpMutation__
 *
 * To run a mutation, you first call `useRequestOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtpMutation, { data, loading, error }] = useRequestOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestOtpMutation,
    RequestOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestOtpMutation, RequestOtpMutationVariables>(
    RequestOtpDocument,
    options
  )
}
export type RequestOtpMutationHookResult = ReturnType<
  typeof useRequestOtpMutation
>
export type RequestOtpMutationResult = Apollo.MutationResult<RequestOtpMutation>
export type RequestOtpMutationOptions = Apollo.BaseMutationOptions<
  RequestOtpMutation,
  RequestOtpMutationVariables
>
export const GoogleLoginDocument = gql`
  mutation googleLogin($input: GoogleLoginInput!) {
    googleLogin(input: $input) {
      accessToken
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type GoogleLoginMutationFn = Apollo.MutationFunction<
  GoogleLoginMutation,
  GoogleLoginMutationVariables
>

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoogleLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GoogleLoginMutation,
    GoogleLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(
    GoogleLoginDocument,
    options
  )
}
export type GoogleLoginMutationHookResult = ReturnType<
  typeof useGoogleLoginMutation
>
export type GoogleLoginMutationResult =
  Apollo.MutationResult<GoogleLoginMutation>
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<
  GoogleLoginMutation,
  GoogleLoginMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...UserWithBioDetail
    }
  }
  ${UserWithBioDetailFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const GoogleCheckDocument = gql`
  mutation googleCheck($input: GoogleLoginInput!) {
    googleCheck(input: $input) {
      accountExists
      ssoName
    }
  }
`
export type GoogleCheckMutationFn = Apollo.MutationFunction<
  GoogleCheckMutation,
  GoogleCheckMutationVariables
>

/**
 * __useGoogleCheckMutation__
 *
 * To run a mutation, you first call `useGoogleCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleCheckMutation, { data, loading, error }] = useGoogleCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoogleCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GoogleCheckMutation,
    GoogleCheckMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GoogleCheckMutation, GoogleCheckMutationVariables>(
    GoogleCheckDocument,
    options
  )
}
export type GoogleCheckMutationHookResult = ReturnType<
  typeof useGoogleCheckMutation
>
export type GoogleCheckMutationResult =
  Apollo.MutationResult<GoogleCheckMutation>
export type GoogleCheckMutationOptions = Apollo.BaseMutationOptions<
  GoogleCheckMutation,
  GoogleCheckMutationVariables
>
export const LogoutDocument = gql`
  mutation logout($input: LogoutInput) {
    logout(input: $input) {
      isSuccess
    }
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  )
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>
export const ValidAppAccessKeyDocument = gql`
  query validAppAccessKey($code: String!) {
    validAppAccessKey(code: $code) {
      isValid
    }
  }
`

/**
 * __useValidAppAccessKeyQuery__
 *
 * To run a query within a React component, call `useValidAppAccessKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidAppAccessKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidAppAccessKeyQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidAppAccessKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidAppAccessKeyQuery,
    ValidAppAccessKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ValidAppAccessKeyQuery,
    ValidAppAccessKeyQueryVariables
  >(ValidAppAccessKeyDocument, options)
}
export function useValidAppAccessKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidAppAccessKeyQuery,
    ValidAppAccessKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ValidAppAccessKeyQuery,
    ValidAppAccessKeyQueryVariables
  >(ValidAppAccessKeyDocument, options)
}
export function useValidAppAccessKeySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ValidAppAccessKeyQuery,
    ValidAppAccessKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ValidAppAccessKeyQuery,
    ValidAppAccessKeyQueryVariables
  >(ValidAppAccessKeyDocument, options)
}
export type ValidAppAccessKeyQueryHookResult = ReturnType<
  typeof useValidAppAccessKeyQuery
>
export type ValidAppAccessKeyLazyQueryHookResult = ReturnType<
  typeof useValidAppAccessKeyLazyQuery
>
export type ValidAppAccessKeySuspenseQueryHookResult = ReturnType<
  typeof useValidAppAccessKeySuspenseQuery
>
export type ValidAppAccessKeyQueryResult = Apollo.QueryResult<
  ValidAppAccessKeyQuery,
  ValidAppAccessKeyQueryVariables
>
export const SuggestUsernameDocument = gql`
  query suggestUsername($input: SuggestUsernameInput!) {
    suggestUsername(input: $input) {
      username
    }
  }
`

/**
 * __useSuggestUsernameQuery__
 *
 * To run a query within a React component, call `useSuggestUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestUsernameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<
    SuggestUsernameQuery,
    SuggestUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SuggestUsernameQuery, SuggestUsernameQueryVariables>(
    SuggestUsernameDocument,
    options
  )
}
export function useSuggestUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestUsernameQuery,
    SuggestUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SuggestUsernameQuery,
    SuggestUsernameQueryVariables
  >(SuggestUsernameDocument, options)
}
export function useSuggestUsernameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SuggestUsernameQuery,
    SuggestUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SuggestUsernameQuery,
    SuggestUsernameQueryVariables
  >(SuggestUsernameDocument, options)
}
export type SuggestUsernameQueryHookResult = ReturnType<
  typeof useSuggestUsernameQuery
>
export type SuggestUsernameLazyQueryHookResult = ReturnType<
  typeof useSuggestUsernameLazyQuery
>
export type SuggestUsernameSuspenseQueryHookResult = ReturnType<
  typeof useSuggestUsernameSuspenseQuery
>
export type SuggestUsernameQueryResult = Apollo.QueryResult<
  SuggestUsernameQuery,
  SuggestUsernameQueryVariables
>
export const ValidUsernameDocument = gql`
  query validUsername($username: String!) {
    usernameAvailable(username: $username) {
      isAvailable
      message
    }
  }
`

/**
 * __useValidUsernameQuery__
 *
 * To run a query within a React component, call `useValidUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useValidUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidUsernameQuery,
    ValidUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ValidUsernameQuery, ValidUsernameQueryVariables>(
    ValidUsernameDocument,
    options
  )
}
export function useValidUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidUsernameQuery,
    ValidUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ValidUsernameQuery, ValidUsernameQueryVariables>(
    ValidUsernameDocument,
    options
  )
}
export function useValidUsernameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ValidUsernameQuery,
    ValidUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ValidUsernameQuery,
    ValidUsernameQueryVariables
  >(ValidUsernameDocument, options)
}
export type ValidUsernameQueryHookResult = ReturnType<
  typeof useValidUsernameQuery
>
export type ValidUsernameLazyQueryHookResult = ReturnType<
  typeof useValidUsernameLazyQuery
>
export type ValidUsernameSuspenseQueryHookResult = ReturnType<
  typeof useValidUsernameSuspenseQuery
>
export type ValidUsernameQueryResult = Apollo.QueryResult<
  ValidUsernameQuery,
  ValidUsernameQueryVariables
>
export const DeleteAccountDocument = gql`
  mutation deleteAccount {
    deleteAccount
  }
`
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options)
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>
export type DeleteAccountMutationResult =
  Apollo.MutationResult<DeleteAccountMutation>
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>
export const RegisterPushTokenDocument = gql`
  mutation registerPushToken($input: RegisterPushTokenInput!) {
    registerPushToken(input: $input) {
      success
    }
  }
`
export type RegisterPushTokenMutationFn = Apollo.MutationFunction<
  RegisterPushTokenMutation,
  RegisterPushTokenMutationVariables
>

/**
 * __useRegisterPushTokenMutation__
 *
 * To run a mutation, you first call `useRegisterPushTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPushTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPushTokenMutation, { data, loading, error }] = useRegisterPushTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPushTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPushTokenMutation,
    RegisterPushTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterPushTokenMutation,
    RegisterPushTokenMutationVariables
  >(RegisterPushTokenDocument, options)
}
export type RegisterPushTokenMutationHookResult = ReturnType<
  typeof useRegisterPushTokenMutation
>
export type RegisterPushTokenMutationResult =
  Apollo.MutationResult<RegisterPushTokenMutation>
export type RegisterPushTokenMutationOptions = Apollo.BaseMutationOptions<
  RegisterPushTokenMutation,
  RegisterPushTokenMutationVariables
>
export const VerifyMobileDocument = gql`
  mutation verifyMobile($input: VerifyMobileInput!) {
    verifyMobile(input: $input)
  }
`
export type VerifyMobileMutationFn = Apollo.MutationFunction<
  VerifyMobileMutation,
  VerifyMobileMutationVariables
>

/**
 * __useVerifyMobileMutation__
 *
 * To run a mutation, you first call `useVerifyMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMobileMutation, { data, loading, error }] = useVerifyMobileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMobileMutation,
    VerifyMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    VerifyMobileMutation,
    VerifyMobileMutationVariables
  >(VerifyMobileDocument, options)
}
export type VerifyMobileMutationHookResult = ReturnType<
  typeof useVerifyMobileMutation
>
export type VerifyMobileMutationResult =
  Apollo.MutationResult<VerifyMobileMutation>
export type VerifyMobileMutationOptions = Apollo.BaseMutationOptions<
  VerifyMobileMutation,
  VerifyMobileMutationVariables
>
export const LinkWithGoogleDocument = gql`
  mutation linkWithGoogle($input: GoogleLoginInput!) {
    linkWithGoogle(input: $input)
  }
`
export type LinkWithGoogleMutationFn = Apollo.MutationFunction<
  LinkWithGoogleMutation,
  LinkWithGoogleMutationVariables
>

/**
 * __useLinkWithGoogleMutation__
 *
 * To run a mutation, you first call `useLinkWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkWithGoogleMutation, { data, loading, error }] = useLinkWithGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkWithGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkWithGoogleMutation,
    LinkWithGoogleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LinkWithGoogleMutation,
    LinkWithGoogleMutationVariables
  >(LinkWithGoogleDocument, options)
}
export type LinkWithGoogleMutationHookResult = ReturnType<
  typeof useLinkWithGoogleMutation
>
export type LinkWithGoogleMutationResult =
  Apollo.MutationResult<LinkWithGoogleMutation>
export type LinkWithGoogleMutationOptions = Apollo.BaseMutationOptions<
  LinkWithGoogleMutation,
  LinkWithGoogleMutationVariables
>
export const RevokeGoogleDocument = gql`
  mutation revokeGoogle {
    revokeGoogle
  }
`
export type RevokeGoogleMutationFn = Apollo.MutationFunction<
  RevokeGoogleMutation,
  RevokeGoogleMutationVariables
>

/**
 * __useRevokeGoogleMutation__
 *
 * To run a mutation, you first call `useRevokeGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeGoogleMutation, { data, loading, error }] = useRevokeGoogleMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevokeGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeGoogleMutation,
    RevokeGoogleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RevokeGoogleMutation,
    RevokeGoogleMutationVariables
  >(RevokeGoogleDocument, options)
}
export type RevokeGoogleMutationHookResult = ReturnType<
  typeof useRevokeGoogleMutation
>
export type RevokeGoogleMutationResult =
  Apollo.MutationResult<RevokeGoogleMutation>
export type RevokeGoogleMutationOptions = Apollo.BaseMutationOptions<
  RevokeGoogleMutation,
  RevokeGoogleMutationVariables
>
export const RevokeWechatDocument = gql`
  mutation revokeWechat {
    revokeWechat
  }
`
export type RevokeWechatMutationFn = Apollo.MutationFunction<
  RevokeWechatMutation,
  RevokeWechatMutationVariables
>

/**
 * __useRevokeWechatMutation__
 *
 * To run a mutation, you first call `useRevokeWechatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeWechatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeWechatMutation, { data, loading, error }] = useRevokeWechatMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevokeWechatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeWechatMutation,
    RevokeWechatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RevokeWechatMutation,
    RevokeWechatMutationVariables
  >(RevokeWechatDocument, options)
}
export type RevokeWechatMutationHookResult = ReturnType<
  typeof useRevokeWechatMutation
>
export type RevokeWechatMutationResult =
  Apollo.MutationResult<RevokeWechatMutation>
export type RevokeWechatMutationOptions = Apollo.BaseMutationOptions<
  RevokeWechatMutation,
  RevokeWechatMutationVariables
>
export const WechatLoginDocument = gql`
  mutation wechatLogin($input: WechatLoginInput!) {
    wechatLogin(input: $input) {
      accessToken
      user {
        ...UserDetail
      }
    }
  }
  ${UserDetailFragmentDoc}
`
export type WechatLoginMutationFn = Apollo.MutationFunction<
  WechatLoginMutation,
  WechatLoginMutationVariables
>

/**
 * __useWechatLoginMutation__
 *
 * To run a mutation, you first call `useWechatLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWechatLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wechatLoginMutation, { data, loading, error }] = useWechatLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWechatLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WechatLoginMutation,
    WechatLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<WechatLoginMutation, WechatLoginMutationVariables>(
    WechatLoginDocument,
    options
  )
}
export type WechatLoginMutationHookResult = ReturnType<
  typeof useWechatLoginMutation
>
export type WechatLoginMutationResult =
  Apollo.MutationResult<WechatLoginMutation>
export type WechatLoginMutationOptions = Apollo.BaseMutationOptions<
  WechatLoginMutation,
  WechatLoginMutationVariables
>
export const WechatCheckDocument = gql`
  mutation wechatCheck($input: WechatLoginInput!) {
    wechatCheck(input: $input) {
      accountExists
      ssoName
    }
  }
`
export type WechatCheckMutationFn = Apollo.MutationFunction<
  WechatCheckMutation,
  WechatCheckMutationVariables
>

/**
 * __useWechatCheckMutation__
 *
 * To run a mutation, you first call `useWechatCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWechatCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wechatCheckMutation, { data, loading, error }] = useWechatCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWechatCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WechatCheckMutation,
    WechatCheckMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<WechatCheckMutation, WechatCheckMutationVariables>(
    WechatCheckDocument,
    options
  )
}
export type WechatCheckMutationHookResult = ReturnType<
  typeof useWechatCheckMutation
>
export type WechatCheckMutationResult =
  Apollo.MutationResult<WechatCheckMutation>
export type WechatCheckMutationOptions = Apollo.BaseMutationOptions<
  WechatCheckMutation,
  WechatCheckMutationVariables
>
export const LinkWithWechatDocument = gql`
  mutation linkWithWechat($input: WechatLoginInput!) {
    linkWithWechat(input: $input)
  }
`
export type LinkWithWechatMutationFn = Apollo.MutationFunction<
  LinkWithWechatMutation,
  LinkWithWechatMutationVariables
>

/**
 * __useLinkWithWechatMutation__
 *
 * To run a mutation, you first call `useLinkWithWechatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkWithWechatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkWithWechatMutation, { data, loading, error }] = useLinkWithWechatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkWithWechatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkWithWechatMutation,
    LinkWithWechatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LinkWithWechatMutation,
    LinkWithWechatMutationVariables
  >(LinkWithWechatDocument, options)
}
export type LinkWithWechatMutationHookResult = ReturnType<
  typeof useLinkWithWechatMutation
>
export type LinkWithWechatMutationResult =
  Apollo.MutationResult<LinkWithWechatMutation>
export type LinkWithWechatMutationOptions = Apollo.BaseMutationOptions<
  LinkWithWechatMutation,
  LinkWithWechatMutationVariables
>
export const WechatSignatureDocument = gql`
  mutation wechatSignature($input: WechatJDKSignatureInput!) {
    wechatSignature(input: $input) {
      nonceStr
      signature
      timestamp
    }
  }
`
export type WechatSignatureMutationFn = Apollo.MutationFunction<
  WechatSignatureMutation,
  WechatSignatureMutationVariables
>

/**
 * __useWechatSignatureMutation__
 *
 * To run a mutation, you first call `useWechatSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWechatSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wechatSignatureMutation, { data, loading, error }] = useWechatSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWechatSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WechatSignatureMutation,
    WechatSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    WechatSignatureMutation,
    WechatSignatureMutationVariables
  >(WechatSignatureDocument, options)
}
export type WechatSignatureMutationHookResult = ReturnType<
  typeof useWechatSignatureMutation
>
export type WechatSignatureMutationResult =
  Apollo.MutationResult<WechatSignatureMutation>
export type WechatSignatureMutationOptions = Apollo.BaseMutationOptions<
  WechatSignatureMutation,
  WechatSignatureMutationVariables
>

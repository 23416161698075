import {
  StyleSheet,
  Text,
  TextInput as TextInputNative,
  View,
} from 'react-native'
import { Button, Dialog } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import { useContext, useEffect, useRef, useState } from 'react'
import 'react-native-get-random-values'
import Checkbox from 'expo-checkbox'

import { StackScreenProps } from '@react-navigation/stack'
import { useVerifyMobileMutation } from '../../generated/graphql'
import { MessageType } from '../../utils/message-type'
import { useIsFocused } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useForm } from 'react-hook-form'
import { useTheme } from '../../hooks/use-theme'
import ControlledCodeInput from '../../components/form/ControlledCodeInput'
import { ControlledMobileInput } from '../../components/form/ControlledMobileInput'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { useErrorTranslation } from '../../utils/error-utils'
import { LocalizationUtils } from '../../utils/localization'
import { AppNavigatorParams } from '../../navigation/types'
import { AuthContext } from '../../auth/auth-context'
import * as Sentry from '@sentry/react-native'

interface FormShape {
  mobileNumber: string
  mobileCountryCode: string
  otp: string
}

export function ProfileVerifyMobileScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'ProfileVerifyMobile'>) {
  const theme = useTheme()
  const globalStyles = useGlobalStyles()
  const { lastUserData } = useContext(AuthContext)

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    getValues,
    trigger,
  } = useForm<FormShape>({
    defaultValues: async () => {
      const countryCode =
        lastUserData.mobileCountryCode ||
        (await LocalizationUtils.get().countryCode())
      return {
        mobileNumber: lastUserData.mobileNumber || '',
        mobileCountryCode: `${countryCode}`,
        otp: '',
      }
    },
    mode: 'onTouched',
  })
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false)
  const [mobileNumber, mobileCountryCode] = watch([
    'mobileNumber',
    'mobileCountryCode',
  ])

  const [isOTPAgreed, setIsOTPAgreed] = useState<boolean>(false)
  const [error, setError] = useState('')
  const isFocused = useIsFocused()
  const otpRef = useRef<TextInputNative>(null)
  const [doVerifyMobile, { client, loading }] = useVerifyMobileMutation()
  const { t } = useTranslation('login_register_reset')
  const tError = useErrorTranslation()

  useEffect(() => {
    if (isFocused && otpRef.current) {
      otpRef.current.focus()
    }
  }, [isFocused, otpRef])

  const submit = async (data: FormShape) => {
    const { mobileNumber, mobileCountryCode, otp } = data

    try {
      await doVerifyMobile({
        variables: {
          input: {
            mobileNumber,
            mobileCountryCode: parseInt(mobileCountryCode || '0'),
            otp,
          },
        },
      })
      client.refetchQueries({ include: ['user'] })

      Toast.show(t('Success'), MessageType.info)
      navigation.goBack()
    } catch (e) {
      console.error(e)
      setError(tError(e))
      // TODO:! Toast are not working in this page, so we are displaying the error
      Toast.show(tError(e), MessageType.error)
      Sentry.captureException(e)
    }
  }

  const styles = StyleSheet.create({
    container: {
      padding: theme.components.pageContainer.padding,
      paddingTop: theme.spacing(10),
    },
    row: {
      marginVertical: theme.spacing(0),
    },
    flex: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
    },
    pageContainer: {
      padding: theme.components.pageContainer.padding,
      alignContent: 'stretch',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    linksCtn: {
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignContent: 'stretch',
      flexGrow: 1,
      alignItems: 'center',
      marginVertical: 5,
    },
    error: {
      color: 'red',
    },
  })

  return (
    <View>
      <Toast visible={true}>Thanks for subscribing!</Toast>

      <View style={styles.container}>
        <ControlledMobileInput
          control={control}
          names={['mobileCountryCode', 'mobileNumber']}
          error={!!errors.mobileNumber}
          getValues={getValues}
          trigger={trigger}
          helperText={errors.mobileNumber?.message}
          wrapperStyle={globalStyles.flexNoWrap}
          onIsValid={setIsMobileNumberValid}
        ></ControlledMobileInput>
        <ControlledCodeInput
          mobileNumber={mobileNumber}
          countryCode={mobileCountryCode}
          name="otp"
          control={control}
          error={errors.otp}
        />

        <View style={styles.linksCtn}>
          <Checkbox
            style={{ marginBottom: 0, marginRight: 10 }}
            disabled={false}
            value={isOTPAgreed}
            onValueChange={(newValue: boolean) => setIsOTPAgreed(!isOTPAgreed)}
          />
          <Text>{t('verificationOTPMessage')}</Text>
        </View>
        {error && <Text style={styles.error}>{error}</Text>}
        <View style={styles.linksCtn}>
          <Button
            style={{ flex: 1 }}
            mode={'contained'}
            onPress={handleSubmit(submit)}
            disabled={
              !isMobileNumberValid || !isValid || isSubmitting || !isOTPAgreed
            }
          >
            {t('Verify Mobile')}
          </Button>
        </View>
      </View>
    </View>
  )
}

export default ProfileVerifyMobileScreen

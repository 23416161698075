import React, { forwardRef } from 'react'
import { Controller, UseControllerProps } from 'react-hook-form'
import {
  StyleProp,
  TextInput as TextInputNative,
  View,
  ViewStyle,
} from 'react-native'
import { HelperText, TextInput } from 'react-native-paper'

type ControllerTextInputProps = React.ComponentProps<typeof TextInput> & {
  control: any
  name: string
  rules?: UseControllerProps['rules']
  error?: any
  multiline?: boolean
  helperText?: string
  autoComplete?: string
  wrapperStyle?: StyleProp<ViewStyle>
}

export const ControlledTextInput = forwardRef<
  TextInputNative,
  ControllerTextInputProps
>(
  (
    {
      wrapperStyle,
      control,
      name,
      rules,
      autoComplete,
      error,
      helperText,
      multiline,
      ...textInputProps
    },
    ref
  ) => {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={wrapperStyle}>
            <TextInput
              ref={ref}
              autoComplete={autoComplete}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              error={error || false}
              multiline={multiline || false}
              {...textInputProps}
            />
            <HelperText type={error ? 'error' : 'info'} visible={!!helperText}>
              {helperText}
            </HelperText>
          </View>
        )}
      />
    )
  }
)

import React from 'react'
import { View, ViewStyle, StyleSheet } from 'react-native'

interface WhiteCardProps {
  children: React.ReactNode
  style?: ViewStyle
}

export const WhiteCard: React.FC<WhiteCardProps> = ({ children, style }) => {
  return <View style={[styles.container, style]}>{children}</View>
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
})

import { useWindowDimensions } from 'react-native'

export const useIsMobileDevice = (): boolean => {
  const { width } = useWindowDimensions()

  // Common breakpoint for mobile devices (you can adjust this value)
  const MOBILE_BREAKPOINT = 768

  return width < MOBILE_BREAKPOINT
}

import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import Toast from 'react-native-root-toast'
import { AuthContext } from '../auth/auth-context'
import { useFeatures } from '../contexts/FeatureProvider'
import { Env } from '../env'
import {
  useSubscribeWebPushMutation,
  useUnsubscribeWebPushMutation,
} from '../generated/graphql'
import { getBrowserInfo } from '../utils/browser'
import * as Sentry from '@sentry/react-native'
export const useNotifications = () => {
  const { t } = useTranslation('errors')
  const {
    isAnonymous,
    isPushNotificationsEnabled,
    notificationsDeviceInfo,
    updateUserData,
  } = useContext(AuthContext)
  const { setNotifications, setNotificationsSound } = useFeatures()
  const [permission, setPermission] = useState<NotificationPermission>(
    'Notification' in window ? Notification.permission : 'denied'
  )
  const [doSubscribe] = useSubscribeWebPushMutation()
  const [doUnsubscribe] = useUnsubscribeWebPushMutation()

  const { notificationsSound } = useFeatures()
  const notificationsSoundRef = useRef(notificationsSound)

  const [enablingNotifications, setEnablingNotifications] = useState(false)
  const [disablingNotifications, setDisablingNotifications] = useState(false)

  const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }

    return outputArray
  }

  const isSupported = () => {
    return (
      Platform.OS === 'web' &&
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    )
  }

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission()
      setPermission(permission)
      if (permission !== 'granted') {
        setNotifications(false)
        setNotificationsSound(false)
        Toast.show(t('notifications-permission-denied'), {
          duration: Toast.durations.LONG,
        })
        Sentry.captureMessage('Notification permission denied', {
          level: 'warning',
          extra: {
            permission,
          },
        })
      }
    } catch (error) {
      console.error('Permission request failed:', error)
      Sentry.captureException(error)
    }
  }

  const registerServiceWorker = async () => {
    await navigator.serviceWorker.register('/sw.js', { scope: '/' })
  }

  const subscribe = async () => {
    try {
      await registerServiceWorker()
      const registration = await navigator.serviceWorker.ready
      if (!registration) return
      const pushSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(Env.VAPID_PUBLIC_KEY),
      })
      const { data } = await doSubscribe({
        variables: {
          input: {
            deviceInfo: getBrowserInfo(),
            subscription: JSON.stringify(pushSubscription),
          },
        },
      })
      const user = data?.subscribeWebPush
      if (user) {
        updateUserData(user)
        setNotifications(true)
        setNotificationsSound(true)
      }
    } catch (error) {
      console.error('Failed to subscribe:', error)
      Sentry.captureException(error)
    }
  }

  const unsubscribe = async () => {
    try {
      const { data } = await doUnsubscribe()
      const user = data?.unsubscribeWebPush
      if (user) {
        updateUserData(user)
        setNotifications(false)
        setNotificationsSound(false)
      }

      const registration = await navigator.serviceWorker?.getRegistration()
      if (!registration) return
      const subscription = await registration.pushManager?.getSubscription()
      if (!subscription) return
      await subscription.unsubscribe()
    } catch (error) {
      console.error('Failed to unsubscribe:', error)
      Sentry.captureException(error)
    }
  }

  const playCustomSound = async () => {
    const audio = new Audio(require('../assets/audio/notification.mp3'))
    await audio.play()
  }

  const addRedDotToFavicon = () => {
    const favicon = document.querySelector(
      'link[rel="shortcut icon"]'
    ) as HTMLLinkElement
    const faviconURL = favicon.href
    const oldLinks = document.querySelectorAll('link[rel="icon"]')
    oldLinks.forEach((e) => e.parentNode?.removeChild(e))
    const canvas = document.createElement('canvas')
    const img = document.createElement('img')

    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
      ctx.drawImage(img, 0, 0, img.width, img.height)

      // Draw red dot
      ctx.beginPath()
      const dotSize = img.width / 3 // Diameter of the dot
      ctx.arc(img.width - dotSize / 2, dotSize / 2, dotSize / 2, 0, 2 * Math.PI) // Position the dot in the top-right
      ctx.fillStyle = 'red'
      ctx.fill()

      const newFavicon = canvas.toDataURL('image/png')
      favicon.href = newFavicon
    }
    img.src = faviconURL

    window.addEventListener('focus', () => {
      favicon.href = faviconURL
    })
  }

  const listenForMessages = async (event: MessageEvent) => {
    const data = event.data
    if (data.type === 'newMessage') {
      addRedDotToFavicon()
      if (notificationsSoundRef.current) {
        await playCustomSound()
      }
    }
  }

  const enableNotifications = async () => {
    if (enablingNotifications) return
    setEnablingNotifications(true)
    await subscribe()
    navigator.serviceWorker.addEventListener('message', listenForMessages)
    setEnablingNotifications(false)
  }

  const disableNotifications = async () => {
    if (disablingNotifications) return
    setDisablingNotifications(true)
    await unsubscribe()
    navigator.serviceWorker.removeEventListener('message', listenForMessages)
    setDisablingNotifications(false)
  }

  const setupNotifications = useCallback(async () => {
    // Only run on web platform with notification support
    if (!isSupported || isAnonymous) {
      return
    }
    if (permission !== 'granted') {
      const registration = await navigator.serviceWorker.getRegistration()
      if (registration) {
        await disableNotifications()
      }
      await requestPermission()
      return
    }
    // If permission is granted and isPushNotificationsEnabled is enabled from the database, enable notifications
    if (permission === 'granted' && isPushNotificationsEnabled) {
      const registration = await navigator.serviceWorker.getRegistration()
      if (!registration || !notificationsDeviceInfo) {
        await enableNotifications()
      }
      /**
       * If we refresh the page, and Service Worker is enabled,
       * we'll not go through the enableNotifications() function,
       * and the message event will lose listener.
       *
       * So we need to listen for messages from Service Worker
       * in a case like the page is refreshed and Service Worker is enabled.
       */
      navigator.serviceWorker.addEventListener('message', listenForMessages)
    }
  }, [isAnonymous, permission])

  // Keep ref updated to avoid the closure issue
  // Without this, the listener will always use the initial value of notificationsSound
  // and not the updated value when notificationsSound changes
  useEffect(() => {
    notificationsSoundRef.current = notificationsSound
  }, [notificationsSound])

  return {
    permission,
    requestPermission,
    setupNotifications,
    enableNotifications,
    disableNotifications,
    isSupported: isSupported(),
  }
}

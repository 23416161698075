import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { Dimensions, ScaledSize, StyleSheet } from 'react-native'
import {
  AppTheme,
  CombinedDefaultTheme,
  ThemeType,
  useTheme,
} from '../hooks/use-theme'
import { useIsMobileDevice } from '../hooks/useIsMobile'

// TODO (ABEL): Remove the non really global sytles, add common and usefull styles like rows and flex columns

const createStyles = (theme: ThemeType) =>
  StyleSheet.create({
    /**
     * Good Styles
     */
    headerTitle: {
      fontSize: theme.fonts.titleMedium.fontSize,
      fontWeight: theme.fonts.titleMedium.fontWeight,
      fontFamily: theme.fonts.titleMedium.fontFamily,
    },
    pageContainer: {
      padding: theme.components.pageContainer.padding,
      alignContent: 'stretch',
      flexDirection: 'column',
      height: Dimensions.get('window').height - 64,
    },
    flexNoWrap: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    rowCenter: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
    },

    /**
     * Requires more info here
     */
    verticalSpacer: {
      height: theme.spacing(6),
    },
    horizontalSpacer: {
      width: theme.spacing(8),
    },
    /**
     * To Be refactored t
     */
    // THis is not a row, is 0 spacing
    row: {
      marginVertical: theme.spacing(0),
    },
    // This change to centerRow or something like that, flex is misleading
    flex: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
    },
  })
type GlobalStyles = ReturnType<typeof createStyles>

const INITIAL_VALUE: GlobalStyles = createStyles(CombinedDefaultTheme)
export const GlobalStyleContext = createContext<GlobalStyles>(INITIAL_VALUE)

type GlobalStyleProviderProps = {
  children: ReactNode
}
export const GlobalStylesProvider = ({
  children,
}: GlobalStyleProviderProps) => {
  const theme = useTheme()
  const value = React.useMemo(() => createStyles(theme), [theme])

  return (
    <GlobalStyleContext.Provider value={value}>
      {children}
    </GlobalStyleContext.Provider>
  )
}

export const useGlobalStyles = () => {
  return useContext(GlobalStyleContext)
}

/**
 * Simplifies the create style returning a hook including global styles and theme
 */
export function createStyle<
  T extends StyleSheet.NamedStyles<T> | StyleSheet.NamedStyles<any>
>(
  styleFactoryFunc: ({
    theme,
    globalStyles,
    dimensions,
    isMobile,
  }: {
    theme: AppTheme
    globalStyles: GlobalStyles
    dimensions: ScaledSize
    isMobile: boolean
  }) => T & StyleSheet.NamedStyles<any>
) {
  return () => {
    const theme = useTheme()
    const globalStyles = useGlobalStyles()
    const dimensions = Dimensions.get('window')
    const isMobile = useIsMobileDevice()
    return useMemo(
      () =>
        StyleSheet.create({
          ...globalStyles,
          ...styleFactoryFunc({ theme, globalStyles, dimensions, isMobile }),
        }),
      [theme, globalStyles, dimensions, styleFactoryFunc, isMobile]
    )
  }
}

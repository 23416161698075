import React, { useContext, useEffect } from 'react'
import { View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { AuthContext } from '../auth/auth-context'
import { AppNavigatorParams } from '../navigation/types'
import { ScreenWrapper } from '../components/ScreenWrapper'
import { Copyright } from '../components/Copyright'
import { useGlobalStyles } from '../contexts/GlobalStylesProvider'
import { RegisterForm } from '../components/Register/RegisterForm'
import { goAfterLogin } from './Login/utils'

export function RegisterScreen({
  navigation,
  route: { params },
}: StackScreenProps<AppNavigatorParams, 'Register'>) {
  const authCtx = useContext(AuthContext)
  const globalStyles = useGlobalStyles()
  const returnPath = params?.returnPath || ''

  useEffect(() => {
    if (!authCtx.isAnonymous) {
      goAfterLogin({ navigation, returnPath })
      return
    }
  }, [authCtx.isAnonymous, returnPath])

  return (
    <View style={{ flex: 1 }}>
      <ScreenWrapper contentContainerStyle={globalStyles.pageContainer}>
        <RegisterForm
          initialMobileNumber={params?.mobileNumber}
          initialCountryCode={params?.mobileCountryCode}
          returnPath={returnPath}
        />
      </ScreenWrapper>
      <Copyright />
    </View>
  )
}

import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, Portal, Button, Text, TextInput } from 'react-native-paper'
import { useTheme } from '../../hooks/use-theme'
import { StyleSheet } from 'react-native'
import { VoteButtonPerspective } from './VoteButton'
import { TextInput as RNTextInput } from 'react-native'
export const CANCEL_VOTE = Symbol('CANCEL_VOTE')

type JustificationProviderProviderProps = {
  vote: (justification: string) => void
  onDismiss: () => void
  open: boolean
  perspective: VoteButtonPerspective
}
export const JustificationModal = ({
  perspective,
  vote,
  onDismiss,
  open,
}: JustificationProviderProviderProps) => {
  const [justification, setJustification] = React.useState<string>('')
  const [disabled, setDisabled] = React.useState(false)
  const { t } = useTranslation('contributions')
  const theme = useTheme()
  const inputRef = useRef<RNTextInput>(null)
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dialog: {
          backgroundColor: theme.colors.surfaceVariant,
        },
        title: {
          fontWeight: 'bold',
        },
        heading: {
          color: theme.colors.onSurfaceVariant,
        },
        button: {
          paddingHorizontal: theme.spacing(2),
        },
      }),
    [theme]
  )
  useEffect(() => {
    if (!open) return
    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }, [open])

  return (
    <Portal>
      <Dialog
        style={styles.dialog}
        visible={!!open}
        onDismiss={() => onDismiss()}
      >
        <Dialog.Title>
          <Text variant="bodyMedium" style={styles.heading}>
            {t('Vote for:')}
          </Text>
        </Dialog.Title>
        <Dialog.Content>
          <Text style={styles.title} variant="bodyMedium">
            {perspective.title}
          </Text>
          <TextInput
            ref={inputRef}
            mode={'outlined'}
            onChangeText={setJustification}
            label={t('Reason (optional)')}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button disabled={disabled} onPress={() => onDismiss()}>
            {t('Cancel')}
          </Button>
          <Button
            mode={'contained'}
            style={styles.button}
            disabled={disabled}
            onPress={() => {
              setDisabled(true)
              vote(justification)
            }}
          >
            {t('Vote')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}

import 'react-native-get-random-values'
import { Checkbox } from 'expo-checkbox'
import {
  SignUpInput,
  useSignUpMutation,
  useSuggestUsernameLazyQuery,
  useValidUsernameLazyQuery,
} from '../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { ControlledTextInput } from '../form/ControlledTextInput'
import {
  createStyle,
  useGlobalStyles,
} from '../../contexts/GlobalStylesProvider'
import { useErrorTranslation } from '../../utils/error-utils'
import { HelperText, Text, TextInput } from 'react-native-paper'
import { View } from 'react-native'
import { Button } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../navigation/types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Toast from 'react-native-root-toast'
import { MessageType } from '../../utils/message-type'
import { goAfterLogin } from '../../screens/Login/utils'
import { AuthContext } from '../../auth/auth-context'
import { debounce } from 'lodash'
import { ControlledMobileInput } from '../form/ControlledMobileInput'
import ControlledCodeInput from '../form/ControlledCodeInput'
import * as Sentry from '@sentry/react-native'

interface FormShape {
  firstName: string
  lastName: string
  username: string
  mobileNumber: string
  countryCode: string
  verificationCode: string
}

interface RegisterFormProps {
  initialMobileNumber?: string
  initialCountryCode?: string
  returnPath?: string
  disabled?: boolean
}

const initialFormState: FormShape = {
  firstName: '',
  lastName: '',
  username: '',
  mobileNumber: '',
  countryCode: '',
  verificationCode: '',
}

export function RegisterForm({
  initialMobileNumber = '',
  initialCountryCode = '',
  returnPath = '',
}: RegisterFormProps) {
  const globalStyles = useGlobalStyles()
  const { t } = useTranslation('login_register_reset')
  const tError = useErrorTranslation()
  const styles = useStyle()
  const authCtx = useContext(AuthContext)
  const navigation = useNavigation<AppNavigation>()
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false)

  const [doSignUp, { loading: submitting }] = useSignUpMutation({
    async onCompleted({ signUp }) {
      Toast.show(t('Success'), MessageType.info)
      authCtx.setCurrentUser(signUp)
      goAfterLogin({ returnPath, navigation })
    },
    async onError(e) {
      const message = tError(e)
      return Toast.show(message, MessageType.error)
    },
  })
  // Form setup and state management
  const { handleSubmit, control, watch, formState, getValues, trigger } =
    useForm<FormShape>({
      defaultValues: {
        ...initialFormState,
        mobileNumber: initialMobileNumber,
        countryCode: initialCountryCode,
      },
      mode: 'onTouched',
    })

  const { errors, isValid } = formState
  const [accessKey, setAccessKey] = useState('')
  const [isPolicyChecked, setIsPolicyChecked] = useState(false)
  const [isOTPAgreed, setIsOTPAgreed] = useState(false)
  const [mobileNumber, countryCode, firstName, lastName, username] = watch([
    'mobileNumber',
    'countryCode',
    'firstName',
    'lastName',
    'username',
  ])

  const [doSuggestUsername, { data: suggestedUsernameData }] =
    useSuggestUsernameLazyQuery()
  const [doValidateUsername, { data: validUsernameData }] =
    useValidUsernameLazyQuery()
  const [suggestedUsername, setSuggestedUsername] = useState('')

  const suggestUsername = useCallback(
    debounce((firstName: string, lastName: string) => {
      doSuggestUsername({ variables: { input: { firstName, lastName } } })
        .then((value) => {
          setSuggestedUsername(value.data?.suggestUsername.username || '')
        })
        .catch((err) => {
          Sentry.captureException(err)
          console.log(err)
        })
    }, 200),
    []
  )

  useEffect(() => {
    suggestUsername(firstName || 'First', lastName || 'Last')
    return () => suggestUsername.cancel()
  }, [firstName, lastName])

  function submit(data: FormShape) {
    const {
      mobileNumber,
      firstName,
      lastName,
      verificationCode,
      countryCode,
      username,
    } = data
    const input: SignUpInput = {
      firstName,
      lastName,
      mobileNumber,
      mobileCountryCode: Number(countryCode),
      smsCode: verificationCode,
      appAccessKey: accessKey,
      username: username || suggestedUsername,
    }

    doSignUp({ variables: { input } })
  }

  return (
    <>
      <ControlledTextInput
        control={control}
        name="firstName"
        autoComplete="given-name"
        placeholder={t('First name')}
        label={t('First name')}
        mode="outlined"
        autoCapitalize="none"
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        wrapperStyle={globalStyles.row}
      />
      <ControlledTextInput
        control={control}
        name="lastName"
        autoComplete="family-name"
        placeholder={t('Last name')}
        label={t('Last name')}
        mode="outlined"
        autoCapitalize="none"
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
        wrapperStyle={globalStyles.row}
      />
      <Controller
        control={control}
        name="username"
        rules={{
          validate: async (value: any) => {
            if (!value) {
              return true
            }
            try {
              const result = await doValidateUsername({
                variables: { username: value },
              })
              if (result.error) {
                return t(`Couldn't validate username`)
              } else if (result.data!.usernameAvailable.isAvailable) {
                return true
              } else {
                return t(
                  result.data!.usernameAvailable.message ||
                    'Username not available'
                )
              }
            } catch (e) {
              console.error(e)
              Sentry.captureException(e)
              return t(`Couldn't validate username`)
            }
          },
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={globalStyles.row}>
            <TextInput
              label={t('Username')}
              mode="outlined"
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete={'username'}
              textContentType={'username'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value || suggestedUsername}
              error={!!errors.username || false}
            />
            <HelperText type="error" visible={!!errors.username}>
              {errors.username?.message}
            </HelperText>
          </View>
        )}
      />
      <ControlledMobileInput
        control={control}
        names={['countryCode', 'mobileNumber']}
        error={!!errors.mobileNumber}
        getValues={getValues}
        trigger={trigger}
        helperText={errors.mobileNumber?.message}
        wrapperStyle={globalStyles.flexNoWrap}
        onIsValid={setIsMobileNumberValid}
      ></ControlledMobileInput>

      <ControlledCodeInput
        countryCode={countryCode}
        mobileNumber={isMobileNumberValid ? mobileNumber : undefined}
        name="verificationCode"
        control={control}
        error={errors.verificationCode}
      />
      <View style={styles.linksCtn}>
        <Checkbox
          style={{ marginTop: 10 }}
          disabled={false}
          value={isPolicyChecked}
          onValueChange={setIsPolicyChecked}
        />
        <Button onPress={() => navigation.navigate('PrivacyPolicy')}>
          <Text style={{ textDecorationLine: 'underline' }}>
            {t('Privacy policy')}
          </Text>
        </Button>
      </View>
      <View style={styles.linksCtn}>
        <Checkbox
          style={{ marginBottom: 10, marginRight: 10 }}
          disabled={false}
          value={isOTPAgreed}
          onValueChange={setIsOTPAgreed}
        />
        <Text>{t('verificationOTPMessage')}</Text>
      </View>

      <View style={globalStyles.verticalSpacer} />

      <Button
        mode="elevated"
        onPress={handleSubmit(submit)}
        disabled={!isValid || submitting || !isOTPAgreed || !isPolicyChecked}
      >
        {t('Sign Up')}
      </Button>
    </>
  )
}

const useStyle = createStyle(({ theme }) => ({
  linksCtn: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignContent: 'stretch',
  },
  ssoButtons: {
    marginTop: 25,
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
}))

import { StackScreenProps } from '@react-navigation/stack'
import * as Clipboard from 'expo-clipboard'
import { IconButton } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import MButton from '../../components/m-button/MButton'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { Env } from '../../env'
import { Contribution, useContributionByIdQuery } from '../../generated/graphql'
import { useTheme } from '../../hooks/use-theme'
import { useWechatShareInit } from '../../hooks/use-wechat-share'
import { AppNavigatorParams } from '../../navigation/types'
import { MelddTaskTargetType } from '../tasks/task.types'
import { ContributionCard } from './components/ContributionCard'
import { View } from 'react-native'

export function PerspectiveContributionScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'PerspectiveContribution'>) {
  const { contributionId } = route.params
  const theme = useTheme()
  const globalStyles = useGlobalStyles()

  const { data: contributionData } = useContributionByIdQuery({
    variables: { contributionId },
  })
  const contribution = contributionData?.contributionById as Contribution

  const saveContributionUrl = async () => {
    let urlToClipBoard = `${Env.WEB_APP_URL}/PerspectiveContribution?contributionId=${contributionId}`
    Toast.show('URL copied!', { position: Toast.positions.TOP })
    await Clipboard.setStringAsync(urlToClipBoard)
  }

  useWechatShareInit(() => {
    if (!contribution) return null
    return {
      title: contribution.contributionType,
      description: `${contribution.input} and ${contribution.bumpCount} bump(s)`,
    }
  }, [contribution])

  if (!contribution) {
    return null
  }

  return (
    <ScreenWrapper withScrollView={false} style={[globalStyles.pageContainer]}>
      <View style={{ marginHorizontal: theme.spacing(5) }}>
        <ContributionCard
          showStatus={true}
          contribution={contribution}
          children={
            <IconButton
              mode="contained-tonal"
              size={20}
              icon="content-copy"
              onPress={() => saveContributionUrl()}
            />
          }
        />
      </View>
      <MButton
        targetType={MelddTaskTargetType.ARTICLE_TASK}
        targetId={contribution.articleId}
        preview={''}
      />
    </ScreenWrapper>
  )
}
export default PerspectiveContributionScreen

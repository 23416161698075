import { getCountry } from 'react-native-localize'
import { Platform } from 'react-native'
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js'
import i18n from '../i18n'
import * as Sentry from '@sentry/react-native'
export class LocalizationUtils {
  public static get() {
    return new LocalizationUtils()
  }
  public async countryCode(): Promise<string> {
    const countryCode =
      (await Platform.OS) === 'web'
        ? this.getCountryCodeByIP()
        : this.getCountryCodeByPhoneSettings()
    const defaultCode = i18n.language === 'en' ? '+1' : '+86'
    return countryCode || defaultCode
  }

  private getCountryCodeByPhoneSettings() {
    const country = getCountry()
    const code = getCountryCallingCode(country as CountryCode) satisfies string
    return Promise.resolve(`+${code}`)
  }

  private async getCountryCodeByIP() {
    /**
     * Free provider that returns the country code from the IP
     */
    const CODE_PROVIDER = 'https://ipapi.co/json/'
    try {
      const result = await fetch(CODE_PROVIDER)
      const data = (await result.json()) as { country_calling_code: string }
      return data.country_calling_code
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
    }

    return ''
  }
}

import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { Control, FieldErrors, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Button, TextInput } from 'react-native-paper'
import { ControlledTextInput } from '../../../components/form/ControlledTextInput'
import { HTMLErrorBox } from '../../../components/HTMLErrorBox'
import { ContributionType } from '../../../generated/graphql'
import { useTheme } from '../../../hooks/use-theme'
import { Contribution } from '../types'
import { useValidationMessage } from './ValidationMessage'

// New component outside the main function
const ContributionInputs = ({
  context,
  contribution,
  control,
  disabled,
  errors,
}: {
  context: string
  contribution: Contribution
  control: Control<FormShape>
  disabled?: boolean
  errors: FieldErrors<FormShape>
}) => {
  const { t } = useTranslation('contributions')
  const { intendedType } = contribution
  const input = useWatch({
    control,
    name: 'input',
  })

  if (intendedType === ContributionType.Edit) {
    const htmlValidationError = useValidationMessage(
      input,
      contribution,
      context
    )
    return (
      <>
        {control._getDirty('input') && (
          <HTMLErrorBox
            title={t('html-validation-error')}
            html={htmlValidationError}
          />
        )}
        <ControlledTextInput
          control={control}
          name={'input'}
          disabled={disabled}
          placeholder={t('Type your text')}
          rules={{
            validate: (value) => value !== contribution.originalText,
          }}
          label={t('Proposed Text')}
          error={!!errors.input}
          mode="outlined"
          autoCapitalize="none"
          helperText={errors.input?.message || t('Type your text')}
          multiline={true}
        />
        <ControlledTextInput
          control={control}
          name={'motivation'}
          disabled={disabled}
          placeholder={t('Type your justification')}
          label={t('Justification')}
          mode="outlined"
          autoCapitalize="none"
          helperText={t('Type your justification')}
          multiline={true}
        />
      </>
    )
  }

  if (intendedType === ContributionType.Challenge) {
    return (
      <>
        <ControlledTextInput
          control={control}
          name={'input'}
          disabled={disabled}
          placeholder={t('Type your challenge')}
          rules={{
            required: t('requiredChallenge'),
            validate: (value) => !!value.trim() || t('requiredChallenge'),
          }}
          label={t('Challenge')}
          error={!!errors.input}
          mode="outlined"
          autoCapitalize="none"
          helperText={errors.input?.message || t('Type your challenge')}
          multiline={true}
        />
        <ControlledTextInput
          control={control}
          name={'motivation'}
          disabled={disabled}
          placeholder={t('Provide a reference')}
          rules={{
            required: t('requiredReference'),
            validate: (value) => !!value.trim() || t('requiredReference'),
          }}
          label={t('Reference')}
          mode="outlined"
          error={!!errors.motivation}
          autoCapitalize="none"
          helperText={errors.motivation?.message || t('Provide a reference')}
          multiline={true}
        />
      </>
    )
  }

  if (intendedType === ContributionType.Inquiry) {
    return (
      <>
        {/* This is a hack to make the text not scroll too high on Android */}
        <TextInput
          style={{
            position: 'absolute',
            zIndex: -1,
            height: 1,
            width: 1,
            top: 20,
            left: 20,
          }}
          disabled={true}
        />
        <ControlledTextInput
          control={control}
          name={'input'}
          disabled={disabled}
          placeholder={t('Type your inquiry')}
          rules={{
            required: t('requiredInquiry'),
            validate: (value) => !!value.trim() || t('requiredInquiry'),
          }}
          label={t('Inquiry')}
          error={!!errors.input}
          mode="outlined"
          autoCapitalize="none"
          helperText={errors.input?.message || t('Type your inquiry')}
        />
      </>
    )
  }

  return null
}

interface ContributionInputProps {
  contribution: Contribution
  inputHandler: (input: string, motivation: string) => void | Promise<void>
  context: string
  disabled?: boolean
}

interface FormShape {
  input: string
  motivation: string
}

export function ContributionInput(props: ContributionInputProps) {
  const { contribution, disabled, context } = props
  const theme = useTheme()
  const { t } = useTranslation('contributions')

  const { control, watch, formState } = useForm<FormShape>({
    defaultValues: {
      input:
        contribution.intendedType === ContributionType.Edit
          ? contribution.originalText
          : ' ',
      motivation: ' ',
    },
    mode: 'onTouched',
  })
  const { errors, isValid } = formState
  const [input, motivation] = watch(['input', 'motivation'])
  const [submitDisabled, setSubmitDisabled] = React.useState(false)
  const updateSaveHandler = React.useCallback(
    async (input: string, motivation: string) => {
      setSubmitDisabled(true)
      await props.inputHandler(input, motivation)
      setSubmitDisabled(false)
    },
    [setSubmitDisabled, props.inputHandler]
  )

  useEffect(() => {
    setSubmitDisabled(!isValid)
  }, [isValid])

  const bkgColor = useMemo(() => {
    switch (props.contribution.intendedType) {
      case ContributionType.Edit:
        return theme.colors.editBkg
      case ContributionType.Challenge:
        return theme.colors.challengeBkg
      case ContributionType.Inquiry:
        return theme.colors.inquiryBkg
    }
  }, [theme, contribution.intendedType])

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: bkgColor,
          paddingVertical: theme.spacing(5),
          marginTop: -theme.spacing(5),
          paddingHorizontal: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(5),
          borderTopRightRadius: theme.spacing(5),
        },
        submitButton: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
      }),
    [theme, bkgColor]
  )
  return (
    <View style={styles.container}>
      <ContributionInputs
        control={control}
        errors={errors}
        context={context}
        contribution={contribution}
        disabled={disabled}
      />
      <View style={styles.submitButton}>
        <Button
          disabled={submitDisabled}
          mode={'contained'}
          onPress={() => updateSaveHandler(input, motivation)}
        >
          {t('Submit')}
        </Button>
      </View>
    </View>
  )
}

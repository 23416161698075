import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { TextInput as TextInputNative, View } from 'react-native'
import 'react-native-get-random-values'
import { Button, Divider, Icon, IconButton, Text } from 'react-native-paper'

import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../auth/auth-context'
import { Copyright } from '../../components/Copyright'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { AppNavigatorParams } from '../../navigation/types'
import GoogleLogin from './GoogleLogin'
import WechatLogin from './WechatLogin'
import { extractReturnPath, goAfterLogin } from './utils'
import { Row } from '../../components/containers/Row'
import OTPLogin, { FormStatus } from './OtpLogin'
import { MelddTextLogo, SmartPhone } from '../../utils/meldd-icons'
import colors from '../../constants/colors'
import { RegisterForm } from '../../components/Register/RegisterForm'

const MobileLoginOrRegistration = ({
  googleTokenId,
  wechatCode,
  returnPath,
  setFormStatus,
  formStatus,
}: {
  googleTokenId: string
  wechatCode: string
  returnPath: string
  setFormStatus: (formStatus: FormStatus) => void
  formStatus: FormStatus
}) => {
  const [showForm, setShowForm] = useState('login')
  const styles = useStyle()
  const { t } = useTranslation('login_register_reset')

  if (showForm === 'login') {
    return (
      <>
        <OTPLogin
          onFormStatus={setFormStatus}
          googleTokenId={googleTokenId}
          wechatCode={wechatCode}
          returnPath={returnPath}
        />
        <Row justifyContent="center" alignItems="center">
          <Divider style={styles.divider} />
        </Row>

        <Row justifyContent="center" alignItems="center" wrap={false}>
          <Button
            style={{ flex: 1 }}
            mode={'outlined'}
            onPress={() => setShowForm('register')}
            disabled={formStatus.isSubmitting}
          >
            {t('Sign Up')}
          </Button>
        </Row>
      </>
    )
  }

  if (showForm === 'register') {
    return (
      <>
        <RegisterForm
          initialCountryCode={formStatus.mobileCountryCode}
          initialMobileNumber={formStatus.mobileNumber}
          returnPath={returnPath}
        />
        <Row justifyContent="center" alignItems="center">
          <Divider style={styles.divider} />
        </Row>
        <Row justifyContent="center" alignItems="center" wrap={false}>
          <Button
            style={{ flex: 1 }}
            mode={'outlined'}
            onPress={() => setShowForm('login')}
            disabled={formStatus.isSubmitting}
          >
            {t('Sign In')}
          </Button>
        </Row>
      </>
    )
  }
}
export function LoginMobileNumberScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'LoginMobileNumber'>) {
  const [googleTokenId, setGoogleTokenId] = useState('')
  const [wechatCode, setWechatCode] = useState('')
  const { isAnonymous } = useContext(AuthContext)
  const [formStatus, setFormStatus] = useState<FormStatus>({
    mobileNumber: '',
    mobileCountryCode: '',
    isSubmitting: false,
  })
  const isFocused = useIsFocused()
  const otpRef = useRef<TextInputNative>(null)
  const { t } = useTranslation('login_register_reset')
  const [displayForm, setDisplayForm] = useState('')
  const styles = useStyle()
  const returnPath = extractReturnPath(route)

  useEffect(() => {
    if (!isAnonymous) {
      goAfterLogin({ navigation, returnPath })
      return
    }
  }, [isAnonymous, returnPath])

  useEffect(() => {
    if (isFocused && otpRef.current) {
      otpRef.current.focus()
    }
  }, [isFocused, otpRef])

  return (
    <ScreenWrapper>
      <View style={styles.mainContainer}>
        {/*
        Possibility to show a left pannel on login screen once the design is decided
        */}
        {false && (
          <View style={styles.leftPanel}>
            <View>
              <MelddTextLogo />
            </View>
          </View>
        )}
        <View style={styles.rightPanel}>
          <View></View>
          <View>
            <Row justifyContent="center" alignItems="center" wrap={false}>
              <Text variant="titleLarge">{t('login-single-click')}</Text>
            </Row>
            <View style={{ marginTop: 20 }} />
            <Row justifyContent="center" alignItems="center" wrap={false}>
              <GoogleLogin
                disabled={formStatus.isSubmitting}
                onMergeAccount={setGoogleTokenId}
              />
              <WechatLogin
                disabled={formStatus.isSubmitting}
                redirectPath={`LoginMobileNumber?returnPath=${returnPath}`}
                onAccountExists={() => navigation.navigate('ListNodes')}
                onMergeAccount={setWechatCode}
              />
              <IconButton
                mode="outlined"
                style={{
                  backgroundColor:
                    displayForm === 'otp' ? colors.grey50 : undefined,
                }}
                icon={SmartPhone}
                disabled={formStatus.isSubmitting}
                onPress={() =>
                  setDisplayForm(displayForm === 'otp' ? '' : 'otp')
                }
              />
            </Row>
            {displayForm === 'otp' ? (
              <View style={styles.otpContainer}>
                <Row justifyContent="center" alignItems="center">
                  <Divider style={styles.divider} />
                </Row>
                <MobileLoginOrRegistration
                  formStatus={formStatus}
                  setFormStatus={setFormStatus}
                  googleTokenId={googleTokenId}
                  wechatCode={wechatCode}
                  returnPath={returnPath}
                />
              </View>
            ) : null}
          </View>
          <View style={styles.copyright}>
            <Copyright />
          </View>
        </View>
      </View>
    </ScreenWrapper>
  )
}

const useStyle = createStyle(({ theme, dimensions, isMobile }) => ({
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  leftPanel: {
    flex: 1,
    backgroundColor: theme.colors.surface,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    display: isMobile ? 'none' : 'flex',
  },
  rightPanel: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: dimensions.height - 60,
    padding: 20,
  },
  logo: {
    width: '80%',
    height: 100,
    marginBottom: 20,
  },
  divider: {
    marginVertical: 25,
    marginHorizontal: 20,
    width: '50%',
  },
  otpContainer: {
    paddingHorizontal: isMobile ? 10 : '20%',
  },
  copyright: {
    height: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
}))

import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Button, Card, Chip, Text } from 'react-native-paper'
import { useAuthContext } from '../../../auth/auth-context'
import {
  CreateTaskModal,
  CreateTaskModalProps,
} from '../../../components/m-button/CreateTaskModal'
import {
  ContributionStatus,
  ContributionType,
  useUpdateContributionStatusMutation,
} from '../../../generated/graphql'
import { useTheme } from '../../../hooks/use-theme'
import { AppNavigation } from '../../../navigation/types'
import { UserLink } from '../../ClusterCards/components/ClickableClusterMember'
import { TaskPendingIcon } from '../../tasks/components/task-pending-icon'
import { MelddTaskTargetType } from '../../tasks/task.types'
import { Contribution } from '../types'
import { BumpButtons } from './BumpButtons'
import { ContributionDecisionBox } from './ContributionDecisionBox'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import Toast from 'react-native-root-toast'
import { MessageType } from '../../../utils/message-type'
import * as Sentry from '@sentry/react-native'
import Row from '../../../components/containers/Row'
interface ContributionCardProps {
  contribution: Contribution
  showStatus?: boolean
  children?: React.ReactNode
}

const CONTRIBUTION_TYPE_LABELS = {
  [ContributionType.Challenge]: 'Challenge',
  [ContributionType.Edit]: 'Edit',
  [ContributionType.Inquiry]: 'Inquiry',
  [ContributionType.Reserved]: 'Reserved',
} as const

const STATUS_LABELS = {
  [ContributionStatus.Accepted]: 'Accepted',
  [ContributionStatus.Cancelled]: 'Cancelled',
  [ContributionStatus.Censured]: 'Censured',
  [ContributionStatus.Pending]: 'Pending',
  [ContributionStatus.Rejected]: 'Rejected',
} as const

const ContributionInformation = ({
  contribution,
}: {
  contribution: Contribution
}) => {
  const { t } = useTranslation('contributions')
  const styles = useStyles(contribution)()

  return (
    <>
      {contribution.contributionType === ContributionType.Edit && (
        <>
          <View style={styles.header}>
            <Text variant={'titleMedium'} numberOfLines={1}>
              {t('Proposed text')}:
            </Text>
          </View>
          <View style={styles.header}>
            <Text style={styles.contributionText}>{contribution.input}</Text>
          </View>
          <View style={styles.header}>
            <Text variant={'titleMedium'} numberOfLines={1}>
              {t('Justification')}:
            </Text>
          </View>
          <View style={styles.header}>
            <Text style={styles.contributionMetaText}>
              {contribution.motivation}
            </Text>
          </View>
        </>
      )}
      {contribution.contributionType === ContributionType.Inquiry && (
        <>
          <View style={styles.header}>
            <Text variant={'titleMedium'} numberOfLines={1}>
              {t('Inquiry')}:
            </Text>
          </View>
          <View style={styles.header}>
            <Text style={styles.contributionMetaText}>
              {contribution.input}
            </Text>
          </View>
        </>
      )}
      {contribution.contributionType === ContributionType.Challenge && (
        <>
          <View style={styles.header}>
            <Text variant={'titleMedium'} numberOfLines={1}>
              {t('Challenge')}:
            </Text>
          </View>
          <View style={styles.header}>
            <Text style={styles.contributionMetaText}>
              {contribution.input}
            </Text>
          </View>
          <View style={styles.header}>
            <Text variant={'titleMedium'} numberOfLines={1}>
              {t('Reference')}:
            </Text>
          </View>
          <View style={styles.header}>
            <Text style={styles.contributionMetaText}>
              {contribution.motivation}
            </Text>
          </View>
        </>
      )}
    </>
  )
}

export const CancelContribution = ({
  contribution,
}: {
  contribution: Contribution
}) => {
  const { t } = useTranslation('contributions')
  const styles = useStyles(contribution)()
  const { userId } = useAuthContext()
  const theme = useTheme()
  const [doUpdateContributionStatus] = useUpdateContributionStatusMutation()
  const canCancel = useMemo(() => {
    return (
      contribution.status === ContributionStatus.Pending &&
      contribution.userId == userId
    )
  }, [contribution, userId])

  const cancelContribution = useCallback(async () => {
    const input = {
      contributionId: contribution.id,
      status: ContributionStatus.Cancelled,
    }

    try {
      await doUpdateContributionStatus({
        variables: { input },
        onQueryUpdated: (query) => {
          if (query.queryName == 'contributionsForMyArticles') {
            query.refetch()
          }
        },
      })
      return Toast.show(t('contribution-cancelled'), MessageType.info)
    } catch (e) {
      Toast.show((e as Error).message, MessageType.error)
      Sentry.captureException(e)
      console.error(e)
    }
  }, [doUpdateContributionStatus, contribution])

  if (!canCancel) return null

  return (
    <Button
      onPress={() => {
        cancelContribution()
      }}
      mode={'outlined'}
      style={styles.cancelButton}
      textColor={theme.colors.danger.main}
    >
      {t('Cancel')}
    </Button>
  )
}

export function ContributionCard(props: ContributionCardProps) {
  const { contribution, showStatus } = props

  const navigation = useNavigation<AppNavigation>()

  const theme = useTheme()
  const { t } = useTranslation('contributions')

  const createTaskModalRef = React.useRef<CreateTaskModalProps>(null)

  const styles = useStyles(contribution)()
  const ContributionTypeChip = useCallback(() => {
    return (
      <Chip
        mode={'flat'}
        style={styles.typeChip}
        compact
        textStyle={styles.typeChipLabel}
      >
        {t(CONTRIBUTION_TYPE_LABELS[contribution.contributionType])}
      </Chip>
    )
  }, [contribution.contributionType, styles.typeChip, styles.typeChipLabel, t])

  const StatusChip = useCallback(() => {
    return (
      <Chip
        mode={'flat'}
        style={styles.statusChip}
        compact
        textStyle={styles.statusChipLabel}
      >
        {t(STATUS_LABELS[contribution.status])}
      </Chip>
    )
  }, [contribution.status, styles.statusChip, styles.statusChipLabel, t])

  return (
    <Card style={[styles.container]} mode={'outlined'}>
      <CreateTaskModal ref={createTaskModalRef} />
      <Card.Content>
        <View style={styles.cardHeader}>
          <Pressable
            onPress={() =>
              navigation.navigate('Perspective', {
                articleId: contribution.article.id,
              })
            }
            style={{ flexShrink: 1 }}
          >
            <Text
              variant={'titleLarge'}
              numberOfLines={4}
              ellipsizeMode={'tail'}
              style={styles.title}
            >
              {contribution.article.title}
            </Text>
          </Pressable>
          {props.children ? props.children : null}
        </View>
        {showStatus && (
          <View style={[styles.header, { marginBottom: 20, flexWrap: 'wrap' }]}>
            <View style={[styles.header, { marginBottom: 0 }]}>
              <Text variant={'titleMedium'} numberOfLines={1}>
                {t('Status')}:
              </Text>
              <StatusChip />
            </View>
            {contribution.status === ContributionStatus.Accepted &&
              contribution.user && (
                <View style={[styles.header, { marginBottom: 0 }]}>
                  <Text variant={'titleMedium'} numberOfLines={1}>
                    {t('contributor')}:
                  </Text>
                  <UserLink user={contribution.user} />
                </View>
              )}
          </View>
        )}
        <View style={styles.header}>
          <Text variant={'titleMedium'} numberOfLines={1}>
            {t('Type of contribution')}:
          </Text>
          <ContributionTypeChip />
        </View>
        <View style={[styles.header, { marginTop: 10 }]}>
          <Text variant={'titleMedium'} numberOfLines={1}>
            {t('Original text')}:
          </Text>
        </View>
        <View style={styles.header}>
          <Text style={styles.contributionText}>
            {contribution.originalText}
          </Text>
        </View>
        <ContributionInformation contribution={contribution} />
        {contribution.article.isOwner && (
          <ContributionDecisionBox contribution={contribution} />
        )}
        {contribution.response && (
          <>
            <View style={[styles.header]}>
              <Text variant={'titleMedium'} numberOfLines={1}>
                {t('Response')}:
              </Text>
            </View>
            <View>
              <Text style={styles.contributionMetaText}>
                {contribution.response}
              </Text>
            </View>
          </>
        )}
        <Row justifyContent="space-between" alignItems="center" wrap>
          <Row alignItems="center">
            <CancelContribution contribution={contribution} />
          </Row>
          <Row alignItems="center">
            <View>
              <TaskPendingIcon
                targetId={contribution.id}
                targetType={MelddTaskTargetType.CONTRIBUTION_TASK}
              />
            </View>
            {contribution && (
              <BumpButtons
                contribution={contribution as unknown as Contribution}
              />
            )}
          </Row>
        </Row>
      </Card.Content>
    </Card>
  )
}

const useStyles = (contribution: Contribution) =>
  createStyle(({ theme }) => {
    const statusColor =
      theme.colors[
        contribution.status.toLowerCase() as
          | 'accepted'
          | 'rejected'
          | 'pending'
          | 'cancelled'
          | 'censured'
      ]
    const contributionColor =
      theme.colors[
        contribution.contributionType.toLowerCase() as
          | 'challenge'
          | 'edit'
          | 'inquiry'
          | 'reserved'
      ]
    const contributionBkgColor =
      theme.colors[
        (contribution.contributionType.toLowerCase() + '50') as
          | 'challenge50'
          | 'edit50'
          | 'inquiry50'
          | 'reserved50'
      ]
    return StyleSheet.create({
      container: {
        marginBottom: theme.spacing(4),
      },
      cardHeader: {
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(4),
      },
      header: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      title: {
        paddingTop: theme.spacing(4),
        flex: 1,
        textDecorationLine: 'underline',
      },
      typeChip: {
        borderRadius: 20,
        backgroundColor: contributionColor,
        padding: 0,
      },
      typeChipLabel: {
        color:
          contribution.contributionType === ContributionType.Edit
            ? 'black'
            : 'white',
        fontSize: 12,
        lineHeight: 15,
      },
      statusChip: {
        borderRadius: 20,
        backgroundColor: statusColor,
        padding: 0,
      },
      statusChipLabel: {
        color: 'white',
        fontSize: 12,
        lineHeight: 15,
      },
      contributionText: {
        padding: theme.spacing(1),
        backgroundColor: contributionBkgColor,
        marginBottom: theme.spacing(5),
      },
      contributionMetaText: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(5),
      },
      cancelButton: {
        borderColor: theme.colors.danger.main,
        flex: 1,
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    })
  })

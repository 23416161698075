import { DimensionValue, View, ViewStyle } from 'react-native'
import { useTheme } from '../../hooks/use-theme'

interface ColumnProps {
  children: React.ReactNode
  justifyContent?: ViewStyle['justifyContent']
  alignItems?: ViewStyle['alignItems']
  gap?: number
  columnGap?: number
  style?: ViewStyle
  flex?: number
  width?: DimensionValue
}

export const Column: React.FC<ColumnProps> = ({
  children,
  justifyContent = 'flex-start',
  alignItems,
  width = '100%',
  columnGap = 0,
  gap = 0,
  flex,
  style,
}) => {
  const theme = useTheme()
  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'column',
          width,
          justifyContent,
          alignItems,
          gap: theme.spacing(gap || columnGap),
          columnGap: theme.spacing(columnGap),
          flex,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

export default Column

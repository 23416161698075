import * as WebBrowser from 'expo-web-browser'
// import * as Google from 'expo-google-sign-in';
import * as Google from 'expo-auth-session/providers/google'
import { ActivityIndicator, IconButton, useTheme } from 'react-native-paper'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGoogleCheckMutation,
  useGoogleLoginMutation,
  useLinkWithGoogleMutation,
} from '../../generated/graphql'
import Toast from 'react-native-root-toast'
import { MessageType } from '../../utils/message-type'
import { AuthContext } from '../../auth/auth-context'
import { Platform } from 'react-native'
import { GooglePNG } from '../../utils/meldd-icons'
import { Image } from 'react-native'
import { RegisterSSOModal, RegisterSSOModalRef } from './register-sso.modal'
import { useRef } from 'react'
import { Env } from '../../env'
import * as Sentry from '@sentry/react-native'

/**
 * This components have 3 flows.
 * 1 - Login (existing / new account -> is all handled inside, changing the context we log in
 * 2 - Login (merge account) -> uses onMergeAccount to send the parent to the token
 * 3 - Link (Already logged) -> uses onSuccess to know the link succeed
 */
export interface GoogleLoginProps {
  disabled?: boolean
  onMergeAccount?: (tokenId: string) => void
  customButton?: (props: {
    disabled?: boolean
    onPress: () => void
  }) => JSX.Element
  onSuccess?: () => void
}

Platform.OS === 'web' && WebBrowser.maybeCompleteAuthSession()

type GoogleResponse = {
  type: string
  error?: { message?: string }
  params?: { id_token: string }
}

const config = {
  androidClientId: Env.GOOGLE_LOGIN.android || '',
  iosClientId: Env.GOOGLE_LOGIN.ios || '',
  webClientId: Env.GOOGLE_LOGIN.web || '',
  // Not sure is needed or making any difference
  // useProxy: true,
  // redirectUri: AuthSession.makeRedirectUri({ native: 'com.meldd.app.dev:/oauthredirect' }),
}

function GoogleLogin({
  disabled,
  onMergeAccount,
  customButton,
  onSuccess,
}: GoogleLoginProps) {
  const { t } = useTranslation('login_register_reset')
  const { userId } = useContext(AuthContext)

  const { setCurrentUser } = useContext(AuthContext)
  const [tokenId, setTokenId] = useState('')
  const [doLogin, { loading }] = useGoogleLoginMutation()
  const [doCheckAccount, { loading: checkLoading }] = useGoogleCheckMutation()
  const [doLinkGoogle] = useLinkWithGoogleMutation()

  const modalRef = useRef<RegisterSSOModalRef>(null)

  const theme = useTheme()

  // If the ENV for the OS is not set, this will crash and stuck in the splash screen
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest(config)

  useEffect(() => {
    getTokenId(response as unknown as GoogleResponse)
  }, [response])

  //log the userInfo to see user details

  const handleLinkWithGoogle = async (tokenId: string) => {
    try {
      await doLinkGoogle({
        variables: { input: { token: tokenId, os: Platform.OS } },
      })
      Toast.show(t('Success'))
      onSuccess?.()
      return true
    } catch (e) {
      Toast.show((e as Error).message, MessageType.error)
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

  const getTokenId = (response: GoogleResponse) => {
    if (!response) {
      return
    }
    if (response?.type === 'error') {
      return Toast.show(
        response.error?.message || 'Unknown Error with Google Login',
        MessageType.error
      )
    }
    // Ignore other status as dismissed or locked or cancel
    if (response.type !== 'success') {
      return
    }
    const token_id = response.params?.id_token || ''
    // This shall never happen ...
    if (!token_id) {
      return Toast.show(
        'Error: Google login succeed but not information was provide',
        MessageType.error
      )
    }
    setTokenId(token_id)
    // If is logged, he is trying to sync, otherwise he is trying to login
    userId ? googleLogin(token_id) : checkAccount(token_id)
  }

  const checkAccount = async (tokenId: string) => {
    try {
      const result = await doCheckAccount({
        variables: { input: { token: tokenId, os: Platform.OS } },
      })
      // If exists, we login

      // New account, we check link or create account
      /**
       * Uncommenting this we allow the user to chose to create a new account
       * with google or link google to his old account.
       * But for simplicity for now we always create a new account (user can delete it and link in the profile)
       */
      // if (!result.data!.googleCheck.accountExists) {
      //   modalRef.current?.open()
      //   return
      // }
      googleLogin(tokenId)
    } catch (error: unknown) {
      if ((error as Error).message.includes('false')) {
        Toast.show(t('Error, our server coudn`t verify your google identity'))
        return
      }
      Toast.show((error as Error).message.toString(), MessageType.error)
      console.error(error)
      Sentry.captureException(error)
    }
  }
  const googleLogin = async (tokenId: string) => {
    try {
      const result = await doLogin({
        variables: { input: { token: tokenId, os: Platform.OS } },
      })
      setCurrentUser(result.data!.googleLogin)
      Toast.show(t('Login successful'), MessageType.info)
      onSuccess?.()
    } catch (error: unknown) {
      if ((error as Error).message.includes('false')) {
        Toast.show(t('Error, our server couldn`t verify your google identity'))
        return
      }
      Toast.show((error as Error).message.toString(), MessageType.error)
      console.error(error)
      Sentry.captureException(error)
    }
  }

  const mergeAccount = async () => {
    onMergeAccount?.(tokenId)
    modalRef.current?.close()
  }

  //add it to a useEffect with response as a dependency

  const showLoading = loading || checkLoading
  return (
    <>
      {customButton ? (
        customButton({ disabled, onPress: () => promptAsync() })
      ) : (
        <IconButton
          mode="outlined"
          icon={() =>
            showLoading ? (
              <ActivityIndicator size="small" color={theme.colors.primary} />
            ) : (
              <Image source={GooglePNG} style={[{ width: 16, height: 16 }]} />
            )
          }
          disabled={disabled}
          onPress={() => promptAsync()}
        />
      )}
      <RegisterSSOModal
        ref={modalRef}
        ssoName={t('Google')}
        onNewAccount={() => googleLogin(tokenId)}
        mergeAccount={() => mergeAccount()}
      />
    </>
  )
}

function GoogleWrapper(props: GoogleLoginProps) {
  if (Platform.OS === 'android' && !config.androidClientId) {
    return null
  }
  if (Platform.OS === 'ios' && !config.iosClientId) {
    return null
  }
  if (Platform.OS === 'web' && !config.webClientId) {
    return null
  }

  if (Platform.OS === 'web' && Env.IS_CN) {
    return null
  }

  return <GoogleLogin {...props} />
}
export default GoogleWrapper
